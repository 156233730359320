<template>
    <div>
        <v-dialog v-model="dialog_addUnit" persistent width="300">
          <v-card>
            <v-card-title class="white-back pl-4 pr-4 pt-3 pb-3">Conversion Rate</v-card-title>
            <v-divider></v-divider>
            
            <v-card-text  dialog-card-content class="mb-4 mt-4 pa-4">
                <v-layout wrap ma-0>
                    <v-form data-vv-scope="subvariant" full-width>
                        <!-- <v-flex xs12 sm12 md12 form-item @click="openDialogUnits('ref_dialogAddUnit_primary')">
                           <v-text-field  
                                   readonly 
                                   v-model="$parent.Form.primaryUnitName" 
                                   label="Primary Unit" 
                                   placeholder=" " 
                                   append-icon="arrow_drop_down"
                                   ref="ref_dialogAddUnit_primary"  
                                    @input="fn_getUnitPacks"
                                   required>
                               </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md12 form-item @click="openDialogUnits('ref_dialogAddUnit_secondary')">
                           <v-text-field  
                                   readonly 
                                   v-model="$parent.Form.secondaryUnitName" 
                                   label="Secondary Unit" 
                                   placeholder=" " 
                                   append-icon="arrow_drop_down"
                                   ref="ref_dialogAddUnit_secondary" 
                                   :onUpdate="updateSecondaryUnit" 
                                clearable
                                @input="fn_checkClearSecondary"
                                   required>
                               </v-text-field>
                        </v-flex> -->

                        <!-- <v-flex xs12 sm12 md12 right-align><v-btn color="primary" flat small capitalize>Remove Secondary Unit</v-btn></v-flex> -->
                        

                        <!-- <v-flex xs12 sm12 md12 form-item v-if="!fixedCQty">
                           <v-text-field 
                                    
                                   v-model.number="$parent.Form.variant.packQuantity" 
                                   type="number" 
                                   label="Pack QTY" 
                                   placeholder=" " 
                                   v-validate="'required|numeric|min_value:1'" 
                                   :error-messages="errors.collect('subvariant.packQty')"  
                                   data-vv-name="packQty" 
                                   data-vv-as="pack qty" 
                                   required>
                               </v-text-field>
                        </v-flex> -->
                    </v-form>
                  
                </v-layout>

                <v-layout row wrap>
                    <template v-if="$parent.Form.selectedSecondaryUnit">
                            <!-- <v-flex xs12 sm12 md12 pa-3>Conversion Rate</v-flex> -->

                            <v-flex xs5 sm5 md5 pl-3>
                                <v-text-field
                                    :label="$parent.Form.selectedUnit.itemName"
                                    placeholder=" "
                                    v-model="$parent.Form.primaryUnitPackQuantity"
                                    type="number"
                                    required
                                ></v-text-field>
                            </v-flex>

                            <v-flex xs2 sm2 md2 center-align style="display: flex; align-items: center;justify-content: center;">=</v-flex>

                            <v-flex xs5 sm5 md5 pr-3>
                                <v-text-field
                                    :label="$parent.Form.selectedSecondaryUnit.itemName"
                                    placeholder=" "
                                    v-model="$parent.Form.secondaryUnitPackQuantity"
                                    type="number"
                                    required
                                ></v-text-field>
                            </v-flex>
                        </template>
                </v-layout>

                <v-list nopadding>
                    <template v-for="(item, index) in unitPacks">
                        <v-list-item class="nopadding" :Key="index" @click="fn_selectUnitPack(item)">
                            <v-list-item-action>
                                <v-icon :color="item.selected ? 'primary' : ''">{{ item.selected ? 'radio_button_checked' : 'radio_button_unchecked' }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{item.primaryPackQuantity}} {{ item.primaryUnitName }} &nbsp;=&nbsp; {{item.secondaryUnitPackQuantity}} {{item.secondaryUnitName}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>

            </v-card-text>
            <v-divider></v-divider>
            <!-- <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click.native="$parent.fn_closeDialog">close</v-btn>
              <v-btn color="blue darken-1" flat @click.native="fn_saveVariant">ok</v-btn>
            </v-card-actions> -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click.native="closeDialogWeb">cancel</v-btn>
              <v-btn color="blue darken-1" text @click.native="fn_saveUnit">ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default{
    // props:['openDialogUnits', 'updateSubUnit', 'checkSubVariantValidations', 'fixedCQty'],
    props:['openDialogUnits', 'updatePrimaryUnit', 'updateSecondaryUnit', 'checkSubVariantValidations', 'fixedCQty'],
    data(){	
        return{
            dialog_addUnit: false,
            unitPacks:null,
            primaryPackQty:null,
            secondaryPackQty:null
        }
    },
    methods:{
        openDialogWeb: function () {	
            this.dialog_addUnit = true;
            this.fn_getUnitPacks();
        },
        closeDialogWeb: function () {
            this.dialog_addUnit = false;

            if(!this.$parent.Form.secondaryUnitPackQuantity){
                this.$parent.Form.selectedSecondaryUnit = null;
            }
        },

        fn_checkClearSecondary: function(val){
            if(!val){
                this.$parent.Form.secondUnitId = null;
                this.$parent.Form.secondUnitName = null;
                this.$parent.Form.secondaryUnitId = null;
                this.$parent.Form.secondaryUnitName = null;
                this.$parent.Form.packQuantity = null;
                this.$parent.Form.primaryUnitPackQuantity = null;
                this.$parent.Form.secondaryUnitPackQuantity = null;
                this.unitPacks = null;
            }
            else{
                this.fn_getUnitPacks();
            }
        },
        // fn_saveVariant: function () {
        // 	var self = this;
        //     this.$validator.validateAll('subvariant').then((result) => {
        //         if (result) {
        //            this.checkSubVariantValidations(this.$parent.Form.variant, false)
        //         }
        //     });
        // }
        fn_saveUnit: function () {
            // this.$parent.Form.unitId = this.$parent.Form.primaryUnitId;
            // this.$parent.Form.unitName = this.$parent.Form.primaryUnitName;

            if(this.$parent.Form.selectedSecondaryUnit && this.$parent.Form.selectedSecondaryUnit.itemId){
                this.$parent.Form.secondUnitId = this.$parent.Form.secondaryUnitId;	  
                this.$parent.Form.secondUnitName = this.$parent.Form.secondaryUnitName;
                !this.$parent.Form.primaryUnitPackQuantity ? this.$parent.Form.primaryUnitPackQuantity = 1 : '';
            }

            this.closeDialogWeb();
            // this.$validator.validateAll('subvariant').then((result) => {
            //     if (result) {
            //         this.$parent.Form.unitId = this.$parent.Form.primaryUnitId;
            //         this.$parent.Form.unitName = this.$parent.Form.primaryUnitName;

            //         if(this.$parent.Form.secondaryUnitId){
            //             this.$parent.Form.secondUnitId = this.$parent.Form.secondaryUnitId;	  
            //             this.$parent.Form.secondUnitName = this.$parent.Form.secondaryUnitName;
            //             !this.$parent.Form.primaryUnitPackQuantity ? this.$parent.Form.primaryUnitPackQuantity = 1 : '';
            //         }

            //         this.closeDialogWeb();
            //     }
            // });
        },

        fn_getUnitPacks: function(){
            if(this.$parent.Form.selectedSecondaryUnit){
                let self = this;
                let filterObject = {};
                filterObject.primaryUnitId = this.$parent.Form.selectedUnit.itemId;
                filterObject.secondaryUnitId = this.$parent.Form.selectedSecondaryUnit.itemId;
                this.getFilterData('GetUnitQuantities', null, filterObject, null,
                    {
                        onSuccess: function(response){
                        console.log(response);
                        self.unitPacks = JSON.parse(response);
                        if(self.unitPacks && self.unitPacks.length){
                            for(let i=0; i<self.unitPacks.length; i++){
                                self.unitPacks[i].selected = false;

                                if(self.unitPacks[i].primaryPackQuantity == self.$parent.Form.primaryUnitPackQuantity && self.unitPacks[i].secondaryUnitPackQuantity == self.$parent.Form.secondaryUnitPackQuantity){
									self.unitPacks[i].selected = true;
								}
                            }
                        }
                    },
                        onError: function(error){
                        console.log(error);
                    }
                });	
            }
        },

        fn_selectUnitPack: function(item){
            for(let i=0; i<this.unitPacks.length; i++){
                this.unitPacks[i].selected = false;
            }
            item.selected = true;

            this.$parent.Form.primaryUnitPackQuantity = item.primaryPackQuantity;
            this.$parent.Form.secondaryUnitPackQuantity = item.secondaryUnitPackQuantity;
        }
        
    }
}
</script>