<template>
    <div>

        <v-toolbar :height="56" flat extended>
            <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

            <v-toolbar-title>Parties</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn>

            <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

            <v-btn icon @click="fn_openFilterNav">
                <v-icon>filter_list</v-icon>
                <span style="font-size: 48px;position: absolute;bottom: -10px;right: 6px;color: #4b77be;" v-if="selectedFilterItemValue != null || selectedSortValue">&bull;</span>
            </v-btn>

            <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
        </v-toolbar>

        <div class="data-container grey-back hasExtention">

            <!-- <div full-width class="search-filter two-btn">
                <v-text-field elevation-10  flat solo clearable placeholder="Search..." v-model="searchValue" @input="fn_searchItem" class="search-box" hide-details :class="{'bottom-shadow':scrollPositionTop!=0}"></v-text-field>

                <div v-ripple class="filter-btn" @click="add">
                    <v-icon>add</v-icon>
                </div>

                <div v-ripple class="filter-btn" @click="fn_openDialogFilterItems">
                    <v-icon>filter_list</v-icon>
                    <span style="font-size: 48px;position: absolute;bottom: -6px;right: 6px;color: #4b77be;" v-if="selectedFilterItemValue != null || selectedSortValue">&bull;</span>
                </div>

            </div> -->

            <!-- <div class="filter-display" style="clear:both;height:58px;" v-if="listItemObject.totalCount"> -->
            <div class="filter-display grey-back">
                <v-layout ma-0 row wrap>
                    <v-flex xs6 sm6 md6>
                        <div class="display-block white-back">

                            <span font11 greencolor>To Receive</span><br>
                            <span font16 class="filter-display-value">&#8377; {{$root.numberToLocale(roundUpto2Decimals(listItemObject.totalReceivables))}}</span>
                            <v-icon class="font18 greencolor circle_arrow_icon ml-1">arrow_downward</v-icon>
                            
                        </div>
                    </v-flex>

                    <v-flex xs6 sm6 md6>
                        <div class="display-block white-back">

                            <span font11 redcolor>To Pay</span><br>
                            <span font16 class="filter-display-value">&#8377; {{$root.numberToLocale(roundUpto2Decimals(listItemObject.totalPayables))}}</span>
                            <v-icon class="font18 redcolor circle_arrow_icon ml-1">arrow_upward</v-icon>
                            
                        </div>
                    </v-flex>

                </v-layout>
            </div>

            <!-- <div style="clear:both" class="padding-all-16 light-opacity-color center-align" v-if="!listItemObject.totalCount">There are no dues!</div> -->

            <div>

                <v-list grey-back nopadding v-if="listItemObject.totalCount">
                    <div white-back v-for="(item, index) in listItemObject.items" :key="index" :class="{'mt-2':index!=0}" :id="'item_'+item.partyId">

                        <v-layout ma-0 row wrap pt-2 pb-2 pr-3 pl-3 v-ripple @click="fn_itemClick(item.partyId, null, index)">
                            <v-flex font16 xs12 sm12 md12>{{item.partyName}}</v-flex>
                            <v-flex xs4 sm4 md4 pt-2 light-opacity-color>
                                {{item.groupTypeId == 101 ? 'Customer' : item.groupTypeId == 102 ? 'Supplier' : ''}}
                            </v-flex>
                            <v-flex xs8 sm8 md8 right-align pt-2 font16>
                                <div>
                                    <v-icon font20 class="greencolor circle_arrow_icon" v-if="item.balance > 0">arrow_downward</v-icon>
                                    <v-icon font20 class="redcolor circle_arrow_icon" v-if="item.balance < 0">arrow_upward</v-icon>
                                    &#8377; {{$root.numberToLocale(roundUpto2Decimals(item.amount))}}
                                </div>
                                <div class="font12">
                                    <span class="greencolor" v-if="item.balance > 0">To Recieve</span>
                                    <span class="redcolor" v-if="item.balance < 0">To Pay</span>
                                </div>
                            </v-flex>
                        </v-layout>

                        <v-divider></v-divider>
                        <div right-align style="padding:2px 6px">
                            <!-- <v-btn disabled nomargin icon text @click="fn_paymentReminder(item)" v-if="item.balance>0">
                                <v-icon font20>notifications_none</v-icon>
                            </v-btn> -->
                            <v-btn nomargin icon text @click="fn_itemClick(item.partyId, 1, index)">
                                <v-icon>picture_as_pdf</v-icon>
                            </v-btn>
                        </div>
                    </div>

                </v-list>

                <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
                  <v-col cols="6">
                    <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
                  </v-col>
                </v-row>

                <div center-align class="pa-6" v-if="loadTimeOut">
                    <p font12 light-opacity-color>Unable to load the items!</p>
                    <v-btn capitalize text color="primary" @click="fn_searchItem">
                        <v-icon left>refresh</v-icon>
                        Retry
                    </v-btn>
                </div>

                <!-- <transition name="fade" mode="out-in">
                    <v-card noshadow class="create-placeholder-card" v-if="showPlaceHolder && !listItemObject.totalCount">
                        <v-card-text @click="add">

                            <div center-align>
                                <div v-ripple class="placeholder-icon">
                                    <i aria-hidden="true" class="v-icon material-icons add-icon">add</i>
                                    <v-icon style="font-size:42px">person_outline</v-icon>
                                </div><br>

                                <p class="title light-opacity-color">Create New Party</p>
                            </div>
                        </v-card-text>
                    </v-card>
                </transition> -->
            </div>
            

            <!-- <div padding-all-16 center-align>
                <v-progress-circular indeterminate color="primary" v-if="loadMore"></v-progress-circular>
            </div> -->

        </div>  

        <!-- <dialog-filter-items ref="ref_dialogFilterItems" type="Parties" :filterData="filterData" :setFilter="fn_setFilter" :selectedFilterItemValue="selectedFilterItemValue" :selectedSortValue="selectedSortValue"></dialog-filter-items> -->

        <!-- <dialog-transaction-options ref="ref_dialogTransactionOptions" :groupTypeId="selectedItemObj.groupTypeId" :accountId="selectedItemObj.partyId" :accountName="selectedItemObj.partyName" v-if="selectedItemObj"></dialog-transaction-options> -->



        <!-- <canvas id="canvas-due-tab" width="400" height="200" class="payment-reminder-canvas" style="max-width:100%"></canvas> -->

        <v-navigation-drawer v-model="filter_nav" absolute right temporary>
          <filter-items ref="ref_filterItems" type="Ledgers" :filterData="filterData" :setFilter="fn_setFilter" :selectedFilterItemValue="selectedFilterItemValue" :selectedSortValue="selectedSortValue" :closeFilterNav="fn_closeFilterNav"></filter-items>
        </v-navigation-drawer>

    </div>
</template>

<script>
// var tagId = null;
// const menuItem = require('../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../config.js').configs.bottomSheetMenu;
// const transactionTypes = require('../../../config.js').configs.transactionTypes;

// import dialogFilterItems from '../dialogs/dialog_filter';

import filterItems from './dialogs/filter_items';

// import dialogPermission from '../../dialogs/dialog_permission';
// import dialogTransactionOptions from '../../dialogs/dialog_transaction_options'

// import InfiniteLoading from 'vue-infinite-loading';

    export default{
        // props:['add'],
        components: {
            // InfiniteLoading,
            'filter-items': filterItems,
            // 'dialog-transaction-options': dialogTransactionOptions,
        },
        data(){
            return{
                listItemObject:{"items":[], "totalCount":0, "totalReceivables":0, "totalPayables":0},
                searchValue:'',
                infiniteId: +new Date(),
                startIndex:0,
                scrollPositionTop:0,
                selectedItemId:null,
                groupTypeId:null,

                filter_nav:false,
                filterData:{
                    filterItems:[
                        {"filterItemId":1, "name":"All", "value":null},
                        {"filterItemId":2, "name":"To Receive", "value":1},
                        {"filterItemId":3, "name":"To Pay", "value":0}
                    ],
                    sortItems:[
                        {"sortId":1, "name":"Party Name", "value":0},
                        {"sortId":2, "name":"Amount - High to Low", "value":1},
                        {"sortId":3, "name":"Amount - Low to High", "value":2}
                    ],
                },
                
                selectedFilterItemValue:null,
                selectedSortValue:0,
                reminderAmount:null,
                selectedItemObj:null,
                showPlaceHolder:false,
                // loading:false,
                loadMore:false,
                loadTimeOut:false
            }
        },
        methods:{

            fn_openFilterNav: function(){
                this.filter_nav = true;
                this.$refs['ref_filterItems'].fn_setCurrentFilterValues();
            },

            fn_new: function(){
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/ledger/items/new', query:{tagId:this.guidWeb()}});
            },

            fn_closeFilterNav: function(){
                this.filter_nav = false;
            },

            onScroll: function(event){
                var element = event.target;
                if (element.scrollHeight - element.scrollTop - 150 <= element.clientHeight)
                {
                    console.log('scrolled');
                    if(this.listItemObject.items.length < this.listItemObject.totalCount){
                        this.fn_loadItems();
                    }
                }
            },

            fn_setFilter: function(filterItemId, sortId){
                this.selectedFilterItemValue = filterItemId;
                this.selectedSortValue = sortId;
                let obj = {};
                obj.filterItemId = filterItemId;
                obj.sortId = sortId;
                localStorage.setItem('activity_party_filter', JSON.stringify(obj));
                // this.fn_closeDialog();

                this.fn_closeFilterNav();
                this.fn_searchItem();
            },

            // fn_openDialogFilterItems: function(){
            //     this.$refs['ref_dialogFilterItems'].openDialogWeb();
            // },

            // fn_openDialogTransactionOptions: function(item){
            //  this.selectedItemObj = item;
            //  this.$refs['ref_dialogTransactionOptions'].openDialogWeb();
            // },

            // fn_closeDialog: function(){
            //     this.$refs['ref_dialogFilterItems'].dialog_filter_items = false;
            // },

            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  if(menuAction=='edit'){
            //      this.$router.push('/transactions/sales/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+this.selectedItemId+'/edit');
            //  }
            //  else if(menuAction=='copy'){
            //      this.$router.push({path:"/transactions/sales/"+this.toolbarTitle.toLowerCase().replace(/ /g,'')+"/items/new", query:{tagId:this.guidWeb(), itemId:this.selectedItemId, copy:true}});
            //  }
            // },

            fn_itemClick: function (itemId, shareStatement, index) {
                console.log(itemId, index);
                // alert("Under Development");
                let obj = {};
                obj.itemId = null;
                obj.lastScrollPos = window.pageYOffset;
                obj.itemIndex = index + 1;
                localStorage.setItem("ledgers_items_state",JSON.stringify(obj));

                if(shareStatement){
                    // this.$router.push({path:"/masters/accounts/ledgers/items/"+itemId+"/statement"});
                    this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/ledger/items/'+itemId+'/statement', query:{type:1}});
                }
                else{
                    // this.$router.push('/masters/accounts/ledgers/items/'+itemId);
                    this.$router.push('/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/ledger/items/'+itemId);
                }
            },

            // fn_getBottomMenu: function (elementId, itemId) {
            //  itemId ? this.selectedItemId = itemId : '';

            //  let bottomMenu;

            //  if(elementId == bottomSheetMenu.toolBar.Id){
            //      bottomMenu = {"BottomMenu": [menuItem.help]};
            //  }
            //  else if(elementId == bottomSheetMenu.masterListItems.Id){
            //      bottomMenu = {"BottomMenu": [menuItem.edit]};
            //  }
                
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // fn_filterByGroupType: function(groupTypeId){
            //  if(this.groupTypeId != groupTypeId){
            //      this.groupTypeId = groupTypeId;
            //      this.fn_searchItem();
            //  }
            // },

            // infiniteHandler($state) {
         //     let filterObject = {};
            //  filterObject.limit = 10;
            //  filterObject.startIndex = this.startIndex;
            //  filterObject.search = this.searchValue.toLowerCase();
            //  filterObject.groupTypeId = this.selectedFilterItemId;
            //  alert('test');


            //  let self = this;
            //  this.getItems("DuePayments", filterObject, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //              let responseJson = JSON.parse(response);
            //              self.listItemObject.totalCount = responseJson.totalCount;
            //              self.listItemObject.totalReceivables = responseJson.totalReceivables ? responseJson.totalReceivables : 0;
            //              self.listItemObject.totalPayables = responseJson.totalPayables ? responseJson.totalPayables : 0;

            //              for(let i in responseJson.items){
            //                  self.listItemObject.items.push(responseJson.items[i]);  
            //              }
            //              self.startIndex = self.listItemObject.items.length;

            //              if(self.listItemObject.items.length < self.listItemObject.totalCount){
            //                  alert('loaded');
            //                  $state.loaded();
            //              }
            //              else{
            //                  alert('complete');
            //                  $state.complete();
            //              }
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
         //    },

            // fn_searchItem: function () {
            //  alert('search');
            //  this.startIndex = 0;
            //  this.infiniteId += 1;
            //  this.listItemObject.items = [];
            // }

            fn_loadItems: function() {
                this.loadMore = true;
                let filterObject = {};
                filterObject.limit = 50;
                filterObject.startIndex = this.startIndex;
                filterObject.search = this.searchValue ? this.searchValue.toLowerCase() : '';
                // filterObject.groupTypeId = this.selectedFilterItemId;

                if(localStorage.getItem('activity_party_filter')){
                    let data = JSON.parse(localStorage.getItem('activity_party_filter'));
                    this.selectedFilterItemValue = data.filterItemId;
                    this.selectedSortValue = data.sortId;
                }
                
                filterObject.balanceType = this.selectedFilterItemValue;
                filterObject.sortByAmount = this.selectedSortValue;


                let pageState = null;
                if(localStorage.getItem("ledgers_items_state")){
                    pageState = JSON.parse(localStorage.getItem("ledgers_items_state"));
                    filterObject.limit = 50 - (pageState.itemIndex % 50) + pageState.itemIndex;
                }

                this.fn_loadTimeOut();

                let self = this;
                this.getItems("DuePayments", filterObject, 
                    {
                        onSuccess: function (response) {
                            self.loadTimeOut = false;
                            console.log(response);
                            let responseJson = JSON.parse(response);
                            self.listItemObject.totalCount = responseJson.totalCount;
                            self.listItemObject.totalReceivables = responseJson.totalReceivables ? responseJson.totalReceivables : 0;
                            self.listItemObject.totalPayables = responseJson.totalPayables ? responseJson.totalPayables : 0;

                            // self.listItemObject.items = responseJson.items;
                            // self.listItemObject.items = [];
                            let itemFound = false;

                            for(let i in responseJson.items){
                                if(pageState && pageState.itemId){
                                    if(responseJson.items[i].partyId == pageState.itemId){
                                        itemFound = true;
                                        console.log(itemFound);
                                    }
                                }
                                
                                let itemIndex = self.listItemObject.items.findIndex(item => item.partyId==responseJson.items[i].partyId);

                                if(itemIndex==-1){
                                    self.listItemObject.items.push(responseJson.items[i]);  
                                }
                            }

                            self.startIndex = self.listItemObject.items.length;
                            self.loadMore = false;

                            setTimeout(function(){
                                self.showPlaceHolder = true;
                            },300);
                        },
                        onError: function (error) {
                            console.log(error);
                            self.loadMore = false;
                            self.loadTimeOut = false;
                        }
                    }
                )
            },

            fn_searchItem: function () {
                // this.showPlaceHolder = false;
                this.startIndex = 0;
                this.listItemObject.items = [];
                this.fn_loadItems();
            },

            fn_debounceSearch: function(){
                 if (this.timeoutid != null){
                    clearTimeout(this.timeoutid);
                 }

                this.timeoutid = setTimeout(() => { 
                    this.fn_searchItem();
                },300);
            },

            fn_loadTimeOut: function(){
                setTimeout(() => {
                    if(this.loadMore){
                        this.loadTimeOut = true;
                        this.loadMore = false;
                    }
                    else{
                        this.loadTimeOut = false;
                    }
                }, 1 * 10 * 1000);
            }
        },
        mounted: function(){
            this.fn_loadItems();

            this.$eventHub.$on('UpdateLedgerList', dataObj => {
                console.log(dataObj);
                this.fn_searchItem();
            });

            // let eventFired = false;

            // this.$eventHub.$once('party', () => {
            //     !eventFired ? this.fn_new() : '';
            //     eventFired = true;
            //     setTimeout(() => {
            //         eventFired = false;
            //     }, 2000);
            // });

            // if(this.$route.query.type == 'party'){
            //     this.fn_new();
            // }
        },
    }
</script>