<template>
    <div>
        <v-dialog ref="dialog_datepicker" no-click-animation v-model="datepicker" :return-value.sync="selectedDate" persistent lazy full-width width="290px" transition="slide-y-transition">
            <!-- <v-date-picker v-model="selectedDate" :min="minimumDate" :max="maximumDate" scrollable> -->
            <v-date-picker v-model="selectedDate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$parent.fn_closeDialog">Cancel</v-btn>
                <v-btn text color="primary" @click="fn_saveDate(selectedDate)">OK</v-btn>
            </v-date-picker>
          </v-dialog>
    </div>
</template>

<script>
export default{
    props:['selected', 'minDate', 'maxDate', 'updateDate'],
    data(){
        return{
            datepicker: false,
            selectedDate:new Date(),
            minimumDate:this.$root.minDateSelection,
            maximumDate:this.$root.maxDateSelection
        }
    },
    methods:{
        openDialogWeb: function () {
            // let dateObj = new Date(this.selected);
            let dateObj = null;
            // doing this hack as in some old devices (tablet which harish have) "selected" props not working
            if(this.selected){
                dateObj = new Date(this.selected);
            }
            else{
                dateObj = new Date(this.$parent.selectedDate);
            }
              this.selectedDate = dateObj.getFullYear()+'-'+("0" + (dateObj.getMonth()+1)).slice(-2)+'-'+("0" + dateObj.getDate()).slice(-2);
              this.minimumDate = this.minDate ? this.minDate : this.$root.minDateSelection;
            this.maximumDate = this.maxDate ? this.maxDate : this.$root.maxDateSelection;
            this.datepicker = true;

            if(this.$parent.dateType && this.$parent.dateType.indexOf("dynamicFieldDate") != -1){
                this.minimumDate = null;
                this.maximumDate = null;
            }
        },
        closeDialogWeb: function () {
            this.datepicker = false;
        },
        fn_saveDate: function (value) {
            let dateObj = null;
            let currentDate = this.$moment(new Date()).format('YYYY-MM-DD');
            if(currentDate == value){
                let currentTime = new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds();
                console.log(value + ":" + currentTime);
                dateObj = new Date(value + ":" + currentTime);
            }
            else{
                dateObj = new Date(value);
            }
            // let dateObj = new Date(value);
            this.$refs.dialog_datepicker.save(value);
            this.updateDate(dateObj);
        }
    }
}
</script>