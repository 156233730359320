<template>
    <div grey-back full-height class="display_wrapper">
        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <dialog-form ref="ref_dialogForm" :closeDialog="fn_closeDialogForm"></dialog-form>

        <dialog-select-batch ref="ref_dialogSelectBatch" :appendBatches="fn_appendBatches" :pid="productData.productId" :productData="productData" :openDatePicker="fn_openDatePicker" :formType="type=='minus' ? 'outward' : 'inward'" :selectedUnit="Form.selectedUnit" v-if="productData"></dialog-select-batch>
            <dialog-datepicker-batch ref="ref_dialogDatePickerBatch" :selected="selectedDate" :updateDate="fn_updateBatchDate"></dialog-datepicker-batch>
    </div>
</template>

<script>
const transactionData = require('../../datamodel').data.transactionData;
import dialogDatePicker from '../../../dialogs/dialog_datepicker'
import dialogForm from './dialogs/dialog_form'
import {transactionMixin} from '../../transaction_mixin'

import dialogSelectBatch from '../dialogs/dialog_select_batch'
import dialogDatepickerBatch from '../../../dialogs/dialog_datepicker_batch'

    export default{
        props:['settings'],
        mixins: [transactionMixin],
        data(){
            return{

                Form:{
                    "transactionData":JSON.parse(JSON.stringify(transactionData)),
                    "rate":null,
                    "qty":null,
                    "selectedUnit":null,
                    "batches":[],
                    "productBatches":[],
                    "selectedBatchType":null,
                },
                productData:null,
                summaryArray:null,
                loading:true,
                tagId:null,
                draftStorageId:null,
                dialogStorageId:null,
                backButtonPress: false,
                // unwatch:false,
                disabledBtn:false,
                type:"plus",
                hasOnlySeries:true,
				hasSeries:false,
				selectedDate:null,
				dateType:null,
            }
        },
        components:{
            'dialog-datepicker':dialogDatePicker,
            'dialog-form': dialogForm,
            'dialog-select-batch':dialogSelectBatch,
            'dialog-datepicker-batch':dialogDatepickerBatch
        },
        methods:{

            fn_openDatePicker: function (type, date) {
                this.dateType = type;
                this.selectedDate = date ? date : this.$root.fn_getLocalDateFormat();
                this.$refs['ref_dialogDatePickerBatch'].openDialogWeb();
            },

            fn_updateBatchDate: function (value) {
                this.$refs['ref_dialogSelectBatch'].fn_updateDate(value, this.dateType);
                this.fn_closeDialog();
            },

            fn_closeDialogForm: function(){
                this.$refs['ref_dialogForm'].closeDialogWeb();
                setTimeout(()=>{
                    this.fn_goBack();
                })
            },

            // fn_openDatePicker: function (type, date) {
            //     let self = this;
            //     this.selectedDate = date;
            //     setTimeout(function () {
            //       self.$refs['ref_dialogDatePicker'].openDialogWeb();
            //     })
            // },

            fn_updateDate: function (value) {
                this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(value);
                this.$refs['ref_dialogDatePicker'].closeDialogWeb();
            },

            fn_getSummary: function (itemId) {
                var self = this;
                this.getData('ProductSummary', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.summaryArray = JSON.parse(response);
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_getProductData: function (itemId, isEdit, tProducts) {
                var self = this;
                this.getData('Product', itemId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.productData = JSON.parse(response);
                            if(!isEdit){
                                self.rate = self.productData.purchaseRate;
                            }

                            setTimeout(()=>{
                                if(self.rate){
                                    document.getElementById('field_rate').focus();      
                                }
                                else{
                                    document.getElementById('field_quantity').focus();
                                }
                            },50);
                            
                            self.fn_getSummary(itemId);

                            self.fn_getBatches(itemId);

                            if(self.productData.productVariants[0].secondaryUnitId){
                                if(isEdit){
                                    if(tProducts && tProducts.length && tProducts[0].variants.length){
                                        self.Form.selectedUnit = {};
                                        self.Form.selectedUnit.itemId = tProducts[0].variants[0].unitId;
                                        self.Form.selectedUnit.itemName = tProducts[0].variants[0].unitName;	
                                    }
                                }
                                else{
                                    self.Form.selectedUnit = {};
                                    self.Form.selectedUnit.itemId = self.productData.productVariants[0].unitId;
                                    self.Form.selectedUnit.itemName = self.productData.productVariants[0].unitName;
                                }
                                
                            }
                        }, 
                        onError: function(error){
                          console.log(error);
                        }
                    }
                );
            },

            fn_getBatches: function(productId, addedBatches){
                let self = this;
                this.getData('GetProductBatchByProductId', productId, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.$set(self.Form, 'batches', JSON.parse(response));

                            if(addedBatches && addedBatches.length){
                                for(let i=0; i<addedBatches.length; i++){
                                    let bIndex = self.Form.batches.findIndex(bItem=>{return bItem.batchId == addedBatches[i].batchId});
                                    if(bIndex == -1){
                                        self.Form.batches.push(addedBatches[i]);
                                    }
                                }
                            }
                            // if(self.$parent.Form.productData.batches && self.$parent.Form.productData.batches.length){
                            // 	self.$parent.fn_openDialogWeb('web', 'Batches', 'ref_dialogSelectBatch');
                            // }
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },
            fn_openDialogSelectBatch: function(){
                if(this.Form.productBatches && this.Form.productBatches.length){
                    for(let i=0; i<this.Form.batches.length; i++){
                        this.Form.batches[i].productQty = null;
                        this.Form.batches[i].selected = false;
                        for(let j=0; j<this.Form.productBatches.length; j++){
                            if(this.Form.productBatches[j].batchId == this.Form.batches[i].batchId){
                                if(this.Form.productBatches[j].productQty){
                                    this.Form.batches[i].selected = true;
                                    this.Form.batches[i].productQty = this.Form.productBatches[j].productQty;
                                }
                            }
                        }
                    }
                }
                this.$refs['ref_dialogSelectBatch'].openDialogWeb();
            },

            fn_getData: function (type, itemId) {
                this.Form.isNew = false;
                let filterObject = {};
                filterObject.type = this.settings.transactionTypeId;

                let self = this;
                this.getFilterData(type, itemId, filterObject, null,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            let data = JSON.parse(response);
                            self.fn_bindingData(data);
                        }, 
                        onError: function(error){
                            console.log(error);
                            self.$eventHub.$emit('snackbarAlert', {msg:"Something went wrong. Please try again"});
                            self.fn_goBack();
                        }
                    }
                );
            },

            fn_bindingData: function (data) {
                // this.fn_getTransactionTypeData(data.transactionTypeFormId, null, false);
                this.Form.transactionData.additionalData = data.additionalData;
                this.Form.transactionData.amount = data.amount;
                this.Form.transactionData.businessId = data.businessId;
                this.Form.transactionData.createdBy = data.createdBy;
                this.Form.transactionData.createdDate = data.createdDate;
                this.Form.transactionData.currencyId = data.currencyId;
                this.Form.transactionData.dueDate = data.dueDate;
                this.Form.transactionData.dueType = data.dueType;
                this.Form.transactionData.modifiedBy = data.modifiedBy;
                this.Form.transactionData.modifiedDate = data.modifiedDate;
                this.Form.transactionData.notes = data.notes;
                this.Form.transactionData.partyId = data.partyId;
                this.Form.transactionData.paymentMode = data.paymentMode;
                this.Form.transactionData.prefix = data.prefix;
                this.Form.transactionData.refDate = data.refDate;
                this.Form.transactionData.refNumber = data.refNumber;
                this.Form.transactionData.roundedAmount = data.roundedAmount;
                this.Form.transactionData.salesmanId = data.salesmanId;
                this.Form.transactionData.status = data.status;
                this.Form.transactionData.suffix = data.suffix;
                this.Form.transactionData.transactionDate = data.transactionDate;
                this.Form.transactionData.transactionId = data.transactionId;
                this.Form.transactionData.transactionListId = data.transactionListId;
                this.Form.transactionData.transactionNumber = data.transactionNumber;
                this.Form.transactionData.mergeTransactionNumber = data.mergeTransactionNumber;
                // this.Form.transactionProductsTemp = JSON.parse(JSON.stringify(data.transactionProducts));
                let products = JSON.parse(JSON.stringify(data.transactionProducts));
                this.Form.rate = products[0].variants[0].rate;
                this.Form.qty = products[0].variants[0].qty;
                this.type = products[0].variants[0].transactionProductMode ? 'plus' : 'minus';

                this.Form.selectedBatchType = products[0].selectedBatchType;

                if(products[0].transactionProductBatches && products[0].transactionProductBatches.length){
                    this.Form.productBatches = [];
                    for(let i=0; i<products[0].transactionProductBatches.length; i++){
                        products[0].transactionProductBatches[i].productQty = products[0].transactionProductBatches[i].qty;
                        this.Form.productBatches.push(JSON.parse(JSON.stringify(products[0].transactionProductBatches[i])))
                    }
                }

                this.fn_getProductData(data.productId, true, products);
            },

            fn_checkValidations: function(){
                this.disabledBtn = true;
                this.fn_post();
                // this.showLoading('Please Wait...');
                // this.$validator.validateAll().then((result) => {
             //        if (result) {
             //         this.fn_post();
             //     }
             //     else{
             //         this.disabledBtn = false;
             //         this.hideLoading();
             //     }
             //    });
            },

            fn_saveLocal: function(dataObj){
                var self = this;
                this.postData('TransactionProducts', dataObj, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            // self.hideLoading();
                            setTimeout(()=>{
                                self.$eventHub.$emit('UpdateItemList', {}); 
                            },100);

                            self.fn_goBack();
                        },
                        onError: function(error){
                            console.log(error);
                            self.saveBtnKey++;
                            // self.hideLoading();
                            let response = JSON.parse(error);
                            if(response && response.negativeProducts && response.negativeProducts.length){
                                self.fn_shortListInvalidItems(response.negativeProducts);
                            }
                        }
                    }
                );
            },

            fn_post: function(){

                //only in case of new transaction
                if(!this.Form.transactionData.transactionId){
                    this.Form.transactionData.transactionId = this.guid();
                    // this.Form.transactionData.createdDate = new Date();
                    this.Form.transactionData.createdDate = this.$root.fn_getLocalDateFormat();
                    this.Form.transactionData.transactionListId = this.settings.transactionTypeId;
                }

                // this.Form.transactionData.modifiedDate = new Date();
                this.Form.transactionData.modifiedDate = this.$root.fn_getLocalDateFormat();

                this.Form.transactionData.amount = this.totalAmount;
                this.Form.transactionData.roundedAmount = this.totalAmount
                this.Form.transactionData.status = 1;
                this.Form.transactionData.transactionTypeFormId = this.Form.transactionTypeFormId;
                this.Form.transactionData.refDate = null;

                // this.Form.transactionProducts = [];
                let transactionProducts = [];

                let tpObj = {};

                tpObj.transactionProductId = this.guid();
                tpObj.transactionId = this.Form.transactionData.transactionId;
                tpObj.productId = this.productData.productId;
                tpObj.productVariantId = this.productData.productVariantId;
                tpObj.groupId = this.guid();
                tpObj.mrp = null;
                tpObj.rate = this.Form.rate ? this.Form.rate : 0;
                tpObj.additionalData = null;
                // tpObj.unitId = this.productData.productVariants[0].unitId;
                tpObj.unitId = this.Form.selectedUnit ? this.Form.selectedUnit.itemId : this.productData.productVariants[0].unitId;
                tpObj.primaryUnitId = this.productData.productVariants[0].variantBaseUnitId;
                tpObj.qty = this.Form.qty;
                tpObj.packQuantity = this.productData.productVariants[0].packQuantity ? this.productData.productVariants[0].packQuantity : 1;
                tpObj.amount = this.totalAmount;
                tpObj.taxableValue = this.totalAmount;
                tpObj.selectedBatchType = this.Form.selectedBatchType;
                if(this.type == 'minus'){
                    tpObj.transactionProductMode = 0;
                    tpObj.actualQty = -this.Form.qty;
                }
                else{
                    tpObj.transactionProductMode = 1;
                    tpObj.actualQty = this.Form.qty;
                }

                if(this.Form.selectedUnit && this.Form.selectedUnit.itemId && this.Form.selectedUnit.itemId != tpObj.primaryUnitId){
                    tpObj.actualQty = this.roundUpto5Decimals((tpObj.actualQty * tpObj.packQuantity) / this.productData.productVariants[0].secondaryUnitPackQuantity);
                }

                transactionProducts.push(tpObj);

                let postObj = {};

                postObj = this.Form.transactionData;
                postObj.transactionProducts = transactionProducts;

                postObj.masterProductBatches = [];
                postObj.transactionProductBatches = [];

                postObj.fiscalYear = JSON.stringify(this.fn_getFiscalYear(this.Form.transactionData.transactionDate));
                postObj.sortDate = this.$moment(this.Form.transactionData.transactionDate).format('YYYY-MM-DD');
                
                if(this.Form.selectedBatchType == 2){
                    this.Form.productBatches = this.Form.productBatches.filter(batch=>{
                        return batch.field1 || batch.field2 || batch.field3
                    })
                }
                else{
                    this.Form.productBatches = this.Form.productBatches.filter(batch=>{
                        return !batch.field1 && !batch.field2 && !batch.field3
                    })
                }

                if(this.Form.productBatches && this.Form.productBatches.length){
                    //adding batches to transaction
                    for(let i=0; i<this.Form.productBatches.length; i++){
                        let obj = {};
                        obj.productId = this.productData.productId;
                        obj.transactionProductBatchId = this.guid();
                        obj.batchId = this.Form.productBatches[i].batchId;
                        obj.transactionProductId = tpObj.transactionProductId;
                        obj.qty = this.Form.productBatches[i].productQty;
                        obj.orderNumber = i + 1;
                        obj.transactionId = this.Form.transactionData.transactionId;
                        obj.transactionListId = this.Form.transactionData.transactionListId;
                        if(this.type == 'minus'){
                            obj.actualQty = -this.Form.productBatches[i].productQty;
                        }
                        else{
                            obj.actualQty = this.Form.productBatches[i].productQty;
                        }

                        if(this.Form.selectedUnit && this.Form.selectedUnit.itemId && this.Form.selectedUnit.itemId != tpObj.primaryUnitId){
                            obj.actualQty = this.roundUpto5Decimals((obj.actualQty * tpObj.packQuantity) / this.productData.productVariants[0].secondaryUnitPackQuantity);
                        }
                        
                        postObj.transactionProductBatches.push(obj);

                        if(this.Form.productBatches[i].isNew){
                            delete this.Form.productBatches[i].isNew;
                            this.Form.productBatches[i].productId = this.productData.productId;
                            this.Form.productBatches[i].orderNumber = this.Form.batches.length + (i + 1);
                            postObj.masterProductBatches.push(this.Form.productBatches[i]);
                        }
                    }
                }

                console.log(this.Form);
                this.fn_saveLocal(postObj);
            },

            fn_appendBatches: function(batches){
                if(batches.length){
                    this.$set(this.Form, 'productBatches', JSON.parse(JSON.stringify(batches)));
                    // this.Form.productData.items[0].batches = JSON.parse(JSON.stringify(batches));
                    let totalQty = 0;
                    for(let i=0; i<this.Form.productBatches.length; i++){
                        totalQty += Number(this.Form.productBatches[i].productQty);
                    }
                    this.Form.qty = Number(totalQty);

                    if(this.productData && this.Form.selectedBatchType == 2){
						this.Form.selectedUnit = {"itemId":this.productData.productVariants[0].unitId, "itemName":this.productData.productVariants[0].unitName};
					}
                }
                this.$refs['ref_dialogSelectBatch'].closeDialogWeb();
            },

            fn_removeBatch: function(batchIndex){
                this.Form.productBatches.splice(batchIndex, 1);
                let totalQty = 0;
                for(let i=0; i<this.Form.productBatches.length; i++){
                    totalQty = totalQty + Number(this.Form.productBatches[i].productQty);
                }
                this.Form.qty = totalQty ? totalQty : null;
            },

            fn_showHideBatches: function(field){
                if(this.Form.selectedBatchType == 2 && !field.batchNumber && !field.batchMRP && !field.batchSize && !field.mfgDate && !field.expDate && !field.field4){
                    return true;
                }
                else if(this.Form.selectedBatchType == 1 && !field.field1 && !field.field2 && !field.field3){
                    return true;
                }
                else{
                    return false;
                }
            },

            fn_changeBatchType: function(val){
              if(val==2 && this.Form.productBatches && this.Form.productBatches.length){
                if(this.Form.selectedUnit){
                  this.Form.selectedUnit = {"itemId":this.productData.productVariants[0].unitId, "itemName":this.productData.productVariants[0].unitName}
                }
              }
            },

            fn_goBack: function(){
                this.backButtonPress = true;
                this.$router.go(-1);
            }
        },
        mounted: function(){

            // !this.$root.CurrentBusiness.DataDrive ? this.fn_checkTransactionCount() : '';

            if(new Date().getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(this.$root.CurrentBusiness.OpeningDate);
            }
            else{
                this.Form.transactionData.transactionDate = this.$root.fn_getLocalDateFormat(); 
            }

            this.type = this.$route.query.type;
            this.fn_getProductData(this.$route.query.itemid);

            if(this.$root.TransactionSettings.batchFields && this.$root.TransactionSettings.batchFields.length){
                for(let i=0; i<this.$root.TransactionSettings.batchFields.length; i++){
                    let field = this.$root.TransactionSettings.batchFields[i]
                    if(field.id == 'field1' || field.id == 'field2' || field.id == 'field3'){
                        this.hasSeries = true;
                    }

                    if(field.id == 'batchNumber' || field.id == 'batchMRP' || field.id == 'purchaseRate' || field.id == 'batchSize' || field.id == 'mfgDate' || field.id == 'expDate' || field.id == 'field4'){
                        this.hasOnlySeries = false;
                    }
                }
            }

            if(!this.Form.selectedBatchType){
                this.Form.selectedBatchType = !this.hasOnlySeries ? 1 : 2;
            }

            this.$refs['ref_dialogForm'].openDialogWeb();

        },
        beforeRouteEnter (to, from, next) {
            next(vm => {

                let itemId = null;

                //edit from display view 
                if((from.params && from.params.tagId) && (to.query && !to.query.tagId)){//from display view
                    itemId = from.params.tagId;
                    vm.fn_getData('TransactionProducts', itemId);
                }

            })
        },
        computed:{
            totalAmount: function(){
                let rate = this.Form.rate ? this.Form.rate : 0;
                return this.roundUpto2Decimals(rate * this.Form.qty);
            }
        }
    }

</script>