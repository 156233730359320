<template>
    <div grey-back>
        <v-dialog v-model="dialog_select_batch" no-click-animation persistent scrollable content-class="half-screen-dialog" lazy transition="dialog-bottom-transition" :max-width="$root.fn_getActionPaneWidth()">

            <v-card>
                <v-toolbar flat>
                    <v-btn icon @click="closeDialogWeb"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title class="two-line-title">{{ $parent.Form.selectedBatchType == 2 ? 'Add Series' : 'Add Batches'}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-if="!newBatch" placeholder="Search" class="mr-8" v-model="searchValue" :hide-details="true" style="max-width: 200px;"></v-text-field>
                    <v-btn color="primary" v-if="!newBatch" outlined small @click="newBatch=true">Add New</v-btn>
                    <!-- <v-menu offset-y v-if="!newBatch">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ml-4" v-bind="attrs" v-on="on" icon><v-icon>more_vert</v-icon></v-btn>
                        </template>
                        
                        <v-list dense nopadding>
                            <v-list-item>
                                <v-list-item-action style="min-width: 36px;">
                                    <v-checkbox v-model="includeOutOfStock" @change="fn_changeOutOfStockFilter"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Show Out of Stock</v-list-item-title>    
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu> -->
                    <!-- <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="fn_newBatch">New Batch</v-btn> -->
                </v-toolbar>

                <v-divider></v-divider>

                  <v-card-text full-height nopadding>

                    <div class="batch-form pt-4" v-if="newBatch">
                        <v-form full-width>
                                    <ValidationObserver ref="observer">
                                        <!-- <ValidationProvider name="Party" rules="required" v-slot="{ errors }"> -->
                                            <v-layout ma-0 wrap pt-2 white-background>
                                                <template v-for="(field, index) in batchFields">
                                                    <v-flex :class="[batchFields.length == 1 ? 'xs12 sm12 md12' : 'xs4 sm4 md4']" form-item :key="index" v-if="field.id != 'opening' && fn_fieldVisibilty(field)">
                                                        <div v-if="field.type == 'string'">
                                                            <ValidationProvider :name="field.title" rules="required" v-slot="{ errors }">
                                                                <v-text-field  
                                                                        :label="field.title" 
                                                                        v-model="field.value"
                                                                        placeholder=" " 
                                                                        required
                                                                        :error-messages="errors">
                                                                </v-text-field>
                                                            </ValidationProvider>
                                                        </div>

                                                        <div v-else-if="field.type == 'date'" @click="openDatePicker(field.id, field.value)">
                                                            <ValidationProvider :name="field.title" rules="required" v-slot="{ errors }">
                                                                <v-text-field  
                                                                        readonly
                                                                        :value="field.value | moment('DD-MMM-YYYY')"
                                                                        :label="field.title" 
                                                                        placeholder=" " 
                                                                        :error-messages="errors"
                                                                        required
                                                                        append-icon="arrow_drop_down"
                                                                        >
                                                                </v-text-field>
                                                            </ValidationProvider>
                                                        </div>

                                                        <div v-else>
                                                            <ValidationProvider :name="field.title" rules="required" v-slot="{ errors }">
                                                                <v-text-field  
                                                                        v-model.number="field.value"
                                                                        type="number"
                                                                        :label="field.title" 
                                                                        placeholder=" " 
                                                                        :error-messages="errors"
                                                                        required>
                                                                </v-text-field>
                                                            </ValidationProvider>
                                                        </div>
                                                    </v-flex>
                                                </template>

                                                <v-flex xs4 sm4 md4 form-item v-if="$parent.Form.selectedBatchType == 1">
                                                    <v-text-field  
                                                        v-model.number="pqty"
                                                        type="number"
                                                        :label="openingForm ? 'Opening' : 'Quantity'" 
                                                        placeholder=" ">
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>
                                        <!-- </ValidationProvider> -->
                                    </ValidationObserver>

                                    <v-layout ma-0 wrap pt-2 white-background>
                                        <v-flex xs12 sm12 md12 right-align form-item>
                                            <v-btn color="primary" text width="120" @click="newBatch=false">cancel</v-btn>
                                            <v-btn color="primary" width="120" @click="fn_addBatch">Add</v-btn>
                                        </v-flex>
                                    </v-layout>
                        </v-form>

                    </div>

                    <!-- <div class="added-batches mt-1" v-if="fn_areFieldsAvailable()"> -->
                    <div v-if="!newBatch">
                        <!-- <v-subheader class="pr-0" white-background>
                            <div style="word-break: normal;">{{ $parent.Form.selectedBatchType == 2 ? 'Select Series' : 'Select Batches'}}</div> 
                            <v-spacer></v-spacer>
                            <v-text-field placeholder="Search" v-model="searchValue" style="max-width: 200px;"></v-text-field>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon><v-icon>more_vert</v-icon></v-btn>
                                </template>
                                
                                <v-list dense nopadding>
                                    <v-list-item>
                                        <v-list-item-action style="min-width: 36px;">
                                            <v-checkbox v-model="includeOutOfStock" @change="fn_changeOutOfStockFilter"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>Show Out of Stock</v-list-item-title>    
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-subheader>
                        <v-divider></v-divider> -->
                        <v-form data-vv-scope="fields" full-width class="hasFooter">
                            <template v-for="(batch, index) in filteredBatches()">
                                <div class="pa-4 white-background" :class="{'batch_selected': batch.productQty}" :key="index" full-width margin-bottom-ten>
                                <!-- <div class="pt-3 pl-3 pr-3 white-background pb-1 mb-2" v-for="(batch, index) in filteredBatches()" :key="index" full-width margin-bottom-ten> -->
                                    <!-- <v-layout row wrap>
                                        <v-flex xs2 sm2 md2 v-if="openingForm && batch.isNew">
                                            <v-btn class="pa-0 ma-0" icon small @click="fn_removeBatch(batch.batchId)"><v-icon>close</v-icon></v-btn>
                                        </v-flex>

                                        <v-flex xs2 sm2 md2 v-if="!openingForm">
                                            <v-checkbox hide-details class="ma-0" v-model="batch.selected" @change="fn_autoFillQty(batch)"></v-checkbox>
                                        </v-flex>
                                    </v-layout> -->
                                    <div style="float: left;" :style="{'marginRight':openingForm && batch.isNew ? '12px' : '8px'}">
                                        <v-btn v-if="openingForm && batch.isNew" class="pa-0 ma-0" icon small @click="fn_removeBatch(batch.batchId)"><v-icon>close</v-icon></v-btn>
                                        <v-checkbox v-if="!openingForm" hide-details class="ma-0" v-model="batch.selected" @change="fn_autoFillQty(batch)"></v-checkbox>
                                    </div>
                                    <v-layout ma-0 class="batch_layout" row wrap>
                                        <!-- <v-flex xs2 sm2 md2 v-if="openingForm && batch.isNew">
                                            <v-btn class="pa-0 ma-0" icon small @click="fn_removeBatch(batch.batchId)"><v-icon>close</v-icon></v-btn>
                                        </v-flex>

                                        <v-flex xs2 sm2 md2 v-if="!openingForm">
                                            <v-checkbox hide-details class="ma-0" v-model="batch.selected" @change="fn_autoFillQty(batch)"></v-checkbox>
                                        </v-flex> -->
                                        <v-flex xs8 sm8 md8>
                                            <v-layout ma-0 row wrap>
                                                <template v-for="(field, fieldIndex) in $root.TransactionSettings.batchFields">
                                                    <v-flex xs4 sm4 md4 mb-2 :key="fieldIndex" v-if="field.id != 'opening' && fn_fieldVisibilty(field) && batch[field.id]">
                                                        <div light-opacity-color v-if="$root.TransactionSettings.batchFields.length > 1">{{ field.title }}</div>
                                                        <div v-if="field.type == 'date'">{{ batch[field.id] | moment('DD-MMM-YYYY') }}</div>
                                                        <div v-else>{{ batch[field.id] }}</div>
                                                    </v-flex>
                                                </template>
                                            </v-layout>
                                        </v-flex>

                                        <v-flex xs4 sm4 md4>
                                             <ValidationObserver ref="observer2">
                                                <v-layout ma-0 row wrap :style="{'paddingLeft': !openingForm || batch.isNew ? '40px' : '0px'}" v-if="$parent.Form.selectedBatchType == 1">
                                                        
                                                            <template v-if="openingForm">
                                                                    <v-flex xs12 sm12 md12>
                                                                        <ValidationProvider name="opening" rules="min_value:0" v-slot="{ errors }">
                                                                            <v-text-field
                                                                                label="Opening"
                                                                                v-model.number="batch.opening"
                                                                                @input="fn_inputOpening(batch)"
                                                                                type="number"
                                                                                placeholder=" "
                                                                                :error-messages="errors"
                                                                                :suffix="fn_getOpeningFormUnit()"
                                                                            ></v-text-field>
                                                                        </ValidationProvider>
                                                                    </v-flex>
                                                            </template>
                                                            
                                                            <!-- :disabled="!batch.selected" -->
                                                            <template v-else>
                                                                    <v-flex xs12 sm12 md12 v-if="productData && productData.productVariants && productData.productVariants.length">
                                                                        <ValidationProvider name="quantity" rules="min_value:0" v-slot="{ errors }">
                                                                            <v-text-field
                                                                                v-model.number="batch.productQty"
                                                                                @input="fn_calculateTotalQty(batch)"
                                                                                outlined
                                                                                dense
                                                                                type="number"
                                                                                placeholder="Quantity"
                                                                                :error-messages="errors"
                                                                                :hide-details="!errors || !errors.length"
                                                                                :suffix="productData.productVariants[0].selectedUnit ? productData.productVariants[0].selectedUnit.itemName : selectedUnit ? selectedUnit.itemName : productData.productVariants[0].unitName"
                                                                            ></v-text-field>
                                                                            <div right-align :class="{'error_background redcolor':batch.currentStock<0}">Current Stock: {{ batch.currentStock ? roundUpto2Decimals(batch.currentStock) : 0 }} {{ productData.productVariants[0].primaryUnitName }}</div>
                                                                        </ValidationProvider>
                                                                    </v-flex>

                                                            </template>

                                                    
                                                    <!-- <v-flex xs12 sm12 md12 redcolor pb-2 pt-2 v-if="batch.expDate && fn_checkDate(batch)">
                                                        <v-icon class="mr-1 font16 redcolor">info_outline</v-icon> Expired {{ fn_getDate(batch) }}
                                                    </v-flex> -->

                                                </v-layout>

                                                <v-layout row wrap ma-0>
                                                    <v-flex xs12 sm12 md12 right-align redcolor pb-2 pt-2 v-if="batch.expDate && fn_checkDate(batch)">
                                                        <v-icon class="mr-1 font16 redcolor">info_outline</v-icon> Expired {{ fn_getDate(batch) }}
                                                    </v-flex>
                                                </v-layout>
                                                </ValidationObserver>
                                        </v-flex>
                                        
                                    </v-layout>
                                    <!-- <v-layout row wrap>
                                        <v-flex xs4 sm4 md4 mb-3>
                                            <div light-opacity-color>Batch No.</div>
                                            <div>{{ batch.batchNumber }}</div>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 mb-3>
                                            <div light-opacity-color>Size</div>
                                            <div>{{ batch.batchSize }}</div>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 mb-3>
                                            <div light-opacity-color>MRP</div>
                                            <div>{{ batch.batchMRP }}</div>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 mb-3>
                                            <div light-opacity-color>Mfg. Date</div>
                                            <div>{{ batch.mfgDate | moment('DD-MMM-YYYY') }}</div>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 mb-3>
                                            <div light-opacity-color>Exp. Date</div>
                                            <div>{{ batch.expDate | moment('DD-MMM-YYYY') }}</div>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 mb-3>
                                            <div light-opacity-color>Opening</div>
                                            <div>{{ batch.opening ? batch.opening : 0 }}</div>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 mb-3>
                                            <div light-opacity-color>Purchase Rate</div>
                                            <div>{{ batch.purchaseRate }}</div>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 mb-3>
                                            <div light-opacity-color>Serial Number</div>
                                            <div>{{ batch.field1 }}</div>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 mb-3>
                                            <div light-opacity-color>IMEI Number</div>
                                            <div>{{ batch.field2 }}</div>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 mb-3>
                                            <div light-opacity-color>Part Number</div>
                                            <div>{{ batch.field3 }}</div>
                                        </v-flex>
                                        <v-flex xs4 sm4 md4 mb-3>
                                            <div light-opacity-color>Model Number</div>
                                            <div>{{ batch.field4 }}</div>
                                        </v-flex>
                                    </v-layout> -->

                                    <!-- <ValidationObserver ref="observer2">
                                    <v-layout ma-0 row wrap :style="{'paddingLeft': !openingForm || batch.isNew ? '40px' : '0px'}" v-if="$parent.Form.selectedBatchType == 1">
                                        
                                                <template v-if="openingForm">
                                                        <v-flex xs12 sm12 md12>
                                                            <ValidationProvider name="opening" rules="min_value:0" v-slot="{ errors }">
                                                                <v-text-field
                                                                    label="Opening"
                                                                    v-model.number="batch.opening"
                                                                    @input="fn_inputOpening(batch)"
                                                                    type="number"
                                                                    placeholder=" "
                                                                    :error-messages="errors"
                                                                ></v-text-field>
                                                            </ValidationProvider>
                                                        </v-flex>
                                                </template>
                                                
                                                <template v-else>
                                                        <v-flex xs6 sm6 md6>
                                                            <ValidationProvider name="quantity" rules="min_value:0" v-slot="{ errors }">
                                                                <v-text-field
                                                                    :disabled="!batch.selected"
                                                                    v-model.number="batch.productQty"
                                                                    @input="fn_calculateTotalQty"
                                                                    outlined
                                                                    dense
                                                                    type="number"
                                                                    placeholder="Quantity"
                                                                    :error-messages="errors"
                                                                    :hide-details="!errors || !errors.length"
                                                                    :suffix="productData.productVariants[0].selectedUnit ? productData.productVariants[0].selectedUnit.itemName : selectedUnit ? selectedUnit.itemName : productData.productVariants[0].unitName"
                                                                ></v-text-field>
                                                                <div right-align :class="{'error_background redcolor':batch.currentStock<0}">Current Stock: {{ batch.currentStock ? batch.currentStock : 0 }}</div>
                                                            </ValidationProvider>
                                                        </v-flex>

                                                </template>

                                        
                                        <v-flex xs12 sm12 md12 redcolor pb-2 pt-2 v-if="batch.expDate && fn_checkDate(batch)">
                                            <v-icon class="mr-1 font16 redcolor">info_outline</v-icon> Expired {{ fn_getDate(batch) }}
                                        </v-flex>

                                    </v-layout>
                                    </ValidationObserver> -->
                                </div>
                            </template>
                        

                        </v-form>
                    </div>
                      
                  </v-card-text>

                <!-- <v-card-actions class="pa-0" v-if="fn_areFieldsAvailable()"> -->
                <v-card-actions class="pa-0" v-if="!newBatch">
                    <v-layout ma-0 row wrap>
                        <!-- <v-flex xs12 sm12 md12> -->
                            <!-- <div class="pa-2">Total Qty: {{totalQty}}</div> -->
                            <!-- <div class="pa-2">Product Qty/Batch Qty: {{productData.productVariants[0].qty ? productData.productVariants[0].qty : 0}}/{{ totalQty }}</div> -->
                        <!-- </v-flex> -->
                         <v-flex xs6 sm6 md6 pl-3 pt-1 pb-1>
                            Total Qty:
                         </v-flex>
                         <v-flex xs6 sm6 md6 right-align pr-3 pt-1 pb-1>
                            {{totalQty}}
                            <template v-if="openingForm">
                                {{ fn_getOpeningFormUnit() }}
                            </template> 
                            <temnplate v-else>
                                <span v-if="productData && productData.productVariants && productData.productVariants.length">{{productData.productVariants[0].selectedUnit ? productData.productVariants[0].selectedUnit.itemName : selectedUnit ? selectedUnit.itemName : productData.productVariants[0].unitName}}</span>
                            </temnplate>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-btn block text style="height: 48px;" color="primary" @click="closeDialogWeb">Cancel</v-btn>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <v-btn block style="height: 48px;" color="primary" @click="fn_checkBatchCount">Done</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-actions>
            </v-card>	
          </v-dialog>
    </div>
</template>	 	

<script>

import { required } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('required', {
  ...required,
  message: 'This field can not be empty',
})

export default{
    props:['appendBatches', 'pid', 'openDatePicker', 'openingForm', 'productData', 'formType', 'selectedUnit', 'selectedUnitName'],
    data(){	
        return{
            dialog_select_batch: false,
            batchFields:[],
            dataObj:{
                batchId:null,
                productId:null,
                batchNumber:null,
                batchMRP:null,
                mfgDate:null,
                expDate:null,
                batchSize:null,
                opening:null,
                purchaseRate:null,
                field1:null, //(serialNumber)
                field2:null, //(imeiNumber)
                field3:null, //(partNumber)
                field4:null, //(modelNumber)
                orderNumber:null,
                additionalData:null
            },
            mfgDate:null,
            expDate:null,
            includeOutOfStock:false,
            totalQty:0,
            pqty:null,
            searchValue:'',
            newBatch:false
            // hasOnlySeries:true,
            // hasSeries:false
            // productBatches:null
        }
    },
    components:{
        ValidationProvider,
        ValidationObserver,
    },
    methods:{
        openDialogWeb: function () {	
            // this.fn_productBatches();
            // setTimeout(() => {
            //     if(!this.$parent.Form.batches || !this.$parent.Form.batches.length){
            //         this.fn_productBatches();
            //     }
            // }, 1000);
            this.dialog_select_batch = true;
            this.newBatch = false;
            if(!this.batchFields || !this.batchFields.length){
                this.batchFields = [];
                if(this.$root.TransactionSettings.batchFields && this.$root.TransactionSettings.batchFields.length){
                    for(let i=0; i<this.$root.TransactionSettings.batchFields.length; i++){
                        if(this.$root.TransactionSettings.batchFields[i].status){
                            let obj = JSON.parse(JSON.stringify(this.$root.TransactionSettings.batchFields[i]));
                            obj.value = null;
                            this.batchFields.push(obj);
                        }
                    }
                }
            }

            if(localStorage.getItem('select_batch_filter')){
                let filterVal = localStorage.getItem('select_batch_filter');
                if(this.formType != 'outward' || filterVal == 1){
                    this.includeOutOfStock = true;
                }
                else{
                    this.includeOutOfStock = false;    
                }
                // this.includeOutOfStock = filterVal == 1 || this.formType == 'outward' ? true : false;
            }

            this.fn_calculateTotalQty();

            // this.$nextTick(() => {
            //     let element = document.getElementsByClassName("batch_dialog_container")[0];
            //     element.addEventListener('scroll', this.fn_onScroll); 
            // })
            

            // setTimeout(() => {
            //     if(localStorage.getItem('batch_data')){
            //         let newBatches = JSON.parse(localStorage.getItem('batch_data'));
            //         !this.$parent.Form.batches || !this.$parent.Form.batches.length ? this.$parent.Form.batches = [] : '';
            //         for(let j=0; j<newBatches.length; j++){
            //             if(newBatches[j].isNew){
            //                 newBatches[j].productQty = null;
            //                 this.$parent.Form.batches.push(newBatches[j]);
            //             }
            //             else{
            //                 let itemIndex = this.$parent.Form.batches.findIndex(item=>{return item.batchId == newBatches[j].batchId});
            //                 if(itemIndex != -1){
            //                     this.$parent.Form.batches[itemIndex] = newBatches[j];
            //                 }
            //             }
            //         }
            //         localStorage.removeItem('batch_data');
            //     }
            // }, 1000);
        },
        closeDialogWeb: function () {
            this.dialog_select_batch = false;
            this.batchFields = [];
            this.fn_emptyObject();
        },
        fn_emptyObject: function(){
            this.dataObj = {
                batchId:null,
                productId:null,
                batchNumber:null,
                batchMRP:null,
                mfgDate:null,
                expDate:null,
                opening:null,
                purchaseRate:null,
                field1:null, //(serialNumber)
                field2:null, //(imeiNumber)
                field3:null, //(partNumber)
                field4:null, //(modelNumber)
                orderNumber:null,
                additionalData:null
            }
            this.mfgDate = null;
            this.expDate = null;
            this.pqty = null;
        },

        // fn_onScroll: function(){
        //     console.log('scrolled');
        // },
        // fn_productBatches: function(){
        //     if(this.$parent.Form.batches && this.$parent.Form.batches.length){
        //         for(let i=0; i<this.$parent.Form.batches.length; i++){
        //             if(!this.$parent.Form.batches[i].productQty){
        //                 this.$parent.Form.batches[i].productQty = null;
        //             }
        //         }
        //     }
        // },
        // fn_newBatch: function(){
        //     this.$parent.backButtonPress = false;
        //     if(this.pid){
        //         this.$router.push({path:"/product/batches", query:{pid:this.pid, dialog:1, new:1}});	
        //     }
        //     else{
        //         this.$router.push({path:"/product/batches", dialog:1});
        //     }
        // },
        fn_addBatch: function(){

            // this.$validator.validateAll().then((result) => {
            this.$refs.observer.validate().then((result) => {
                if (result) {
                    for(let i=0; i<this.batchFields.length; i++){
                        if(this.mfgDate && this.batchFields[i].id == 'mfgDate'){
                            this.batchFields[i].value = this.mfgDate;
                        }
                        if(this.expDate && this.batchFields[i].id == 'expDate'){
                            this.batchFields[i].value = this.expDate;
                        }
                        this.dataObj[this.batchFields[i].id] = this.batchFields[i].value;
                        this.batchFields[i].value = null;
                    }

                    // let items = this.this.$parent.Form.batches;

                    let existingBatch = [];
                    for(let i=0; i<this.$parent.Form.batches.length; i++){
                        let field = this.$parent.Form.batches[i];
                        if(this.$parent.Form.selectedBatchType == 1){
                            if(field.batchNumber == this.dataObj.batchNumber &&
                            field.batchMRP == this.dataObj.batchMRP &&
                            field.batchSize == this.dataObj.batchSize &&
                            this.$moment(new Date(field.mfgDate)).startOf('day').toString() == this.$moment(new Date(this.dataObj.mfgDate)).startOf('day').toString() &&
                            this.$moment(new Date(field.expDate)).startOf('day').toString() == this.$moment(new Date(this.dataObj.expDate)).startOf('day').toString() &&
                            // new Date(field.mfgDate).toString() == new Date(this.dataObj.mfgDate).toString() &&
                            // new Date(field.expDate).toString() == new Date(this.dataObj.expDate).toString() && 
                            field.field4 == this.dataObj.field4 &&
                            field.purchaseRate == this.dataObj.purchaseRate){
                                existingBatch.push(this.$parent.Form.batches[i]);
                            }
                        }
                        else{
                            if(field.field1 == this.dataObj.field1 &&
                            field.field2 == this.dataObj.field2 &&
                            field.field3 == this.dataObj.field3){
                                existingBatch.push(this.$parent.Form.batches[i]);
                            }
                        }
                    }
                    
                    // let existingBatch = this.$parent.Form.batches.filter(field=>{
                    //     if(this.$parent.Form.selectedBatchType == 1){
                    //         return field.batchNumber == this.dataObj.batchNumber &&
                    //         field.batchMRP == this.dataObj.batchMRP &&
                    //         field.batchSize == this.dataObj.batchSize &&
                    //         field.mfgDate == this.dataObj.mfgDate &&
                    //         field.expDate == this.dataObj.expDate && 
                    //         field.field4 == this.dataObj.field4 &&
                    //         field.purchaseRate == this.dataObj.purchaseRate
                    //     }
                    //     else{
                    //         return field.field1 == this.dataObj.field1 &&
                    //         field.field2 == this.dataObj.field2 &&
                    //         field.field3 == this.dataObj.field3
                    //     }
                    // })

                    let existingBatchIndex = null;
                    if(existingBatch && existingBatch.length){
                        existingBatchIndex = this.$parent.Form.batches.findIndex(item=>{return item.batchId == existingBatch[0].batchId});
                    }

                    if(existingBatchIndex != null && existingBatchIndex != -1){
                        this.$parent.Form.batches[existingBatchIndex].selected = true;
                        if(this.openingForm){
                            this.$parent.Form.batches[existingBatchIndex].opening = this.$parent.Form.selectedBatchType == 2 ?  1 : this.pqty;
                        }
                        else{
                            this.$parent.Form.batches[existingBatchIndex].productQty = this.$parent.Form.selectedBatchType == 2 ?  1 : this.pqty;
                        }
                    }
                    else{
                        if(!this.dataObj.batchId){
                            this.dataObj.batchId = this.guid();
                            this.dataObj.orderNumber = this.$parent.Form.batches.length + 1;
                            this.dataObj.isNew = true;
                            this.dataObj.selected = true;
                            // this.$parent.Form.selectedBatchType == 2 ? this.dataObj.productQty = 1 : '';
                            if(this.openingForm){
                                this.dataObj.opening = this.$parent.Form.selectedBatchType == 2 ?  1 : this.pqty;
                            }
                            else{
                                this.dataObj.productQty = this.$parent.Form.selectedBatchType == 2 ?  1 : this.pqty;
                            }
                            this.$parent.Form.batches.unshift(JSON.parse(JSON.stringify(this.dataObj)));
                        }
                    }
                    

                    this.fn_autoFillQty(this.dataObj);

                    this.fn_emptyObject();
                    this.newBatch = false;
                }
            })

            
            // !this.$parent.Form.batches || !this.$parent.Form.batches.length ? this.$parent.Form.batches = [] : '';
            // for(let j=0; j<newBatches.length; j++){
            //     if(newBatches[j].isNew){
            //         newBatches[j].productQty = null;
            //         this.$parent.Form.batches.push(newBatches[j]);
            //     }
            //     else{
            //         let itemIndex = this.$parent.Form.batches.findIndex(item=>{return item.batchId == newBatches[j].batchId});
            //         if(itemIndex != -1){
            //             this.$parent.Form.batches[itemIndex] = newBatches[j];
            //         }
            //     }
            // }
        },

        fn_checkBatchCount: function(){
            let hasBatch = false;
            if(!this.openingForm){
                if(this.$parent.Form.items && this.$parent.Form.items.length){
                    for(let i=0; i<this.$parent.Form.items.length; i++){
                        if(this.$parent.Form.items[i].batches && this.$parent.Form.items[i].batches.length){
                            hasBatch = true;
                            break;
                        }
                    }
                }
            }
            
            if(hasBatch){
                if(this.totalQty){
                    this.fn_appendBatches();
                }
                else{
                    this.$parent.fn_openDialogBatchConfirmation();
                }
            }
            else{
                this.fn_appendBatches();
            }
        },

        fn_removeSelectedItemBatches: function(){
            this.productData.batches = [];
            this.$parent.$refs['ref_dialogConfirmation'].closeDialogWeb();
            this.closeDialogWeb();
        },

        fn_appendBatches: function(){

            if(!this.filteredBatches() || !this.filteredBatches().length){
                this.$eventHub.$emit('snackbarAlert', {msg:"Add new batch"});
                return;
            }
            // this.$validator.validateAll('fields').then((result) => {
            if(this.$refs.observer2 && this.$refs.observer2.length){
                this.$refs.observer2[0].validate().then((result) => {
                    if (result) {
                        
                        let batches = null;
                        if(this.openingForm){
                            batches = this.$parent.Form.batches;
                        }
                        else{

                            if(this.totalQty <= 0){
                                this.$eventHub.$emit('snackbarAlert', {msg:"Quantity should be greater than 0"});
                                return;
                            }
                            
                            if(this.$parent.Form.selectedBatchType == 2){
                                batches = this.$parent.Form.batches.filter(item=>{return item.selected });
                            }
                            else{
                                batches = this.$parent.Form.batches.filter(item=>{return item.productQty && item.productQty > 0});
                            }
                        }
                        

                        // if(this.$parent.Form.selectedBatchType == 2){
                        //     batches = this.$parent.Form.batches.filter(item=>{return item.selected });
                        // }
                        // else{
                        //     batches = this.$parent.Form.batches.filter(item=>{return item.productQty && item.productQty > 0});
                        // }
                        if(batches && batches.length){
                            this.appendBatches(batches, this.productData);
                        }
                        else{
                            if(this.$parent.Form.batches && this.$parent.Form.batches.length){
                                // this.showToast(this.$parent.Form.selectedBatchType == 2 ? "Select an item" : "Select batch and add quantity");
                                this.$eventHub.$emit('snackbarAlert', {msg:this.$parent.Form.selectedBatchType == 2 ? 'Select an item' : 'Select batch and add quantity'});
                            }
                            else{
                                // this.showToast(this.$parent.Form.selectedBatchType == 2 ? "Add Series" : "Add Batch");
                                this.$eventHub.$emit('snackbarAlert', {msg:this.$parent.Form.selectedBatchType == 2 ? 'Add Series' : 'Add Batch'});
                            }
                        }
                    }
                })
            }
        },

        fn_fieldVisibilty: function(field){
            if(field.status){
                if(this.$parent.Form.selectedBatchType == 2){
                    if(field.id == 'field1' || field.id == 'field2' || field.id == 'field3'){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                else{
                    if(field.id == 'batchNumber' || field.id == 'batchMRP' || field.id == 'purchaseRate' || field.id == 'batchSize' || field.id == 'mfgDate' || field.id == 'expDate' || field.id == 'opening' || field.id == 'field4'){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            }
            else{
                return false
            }
        },

        filteredBatches: function(){
            let batches = [];
            if(this.$parent.Form.selectedBatchType == 2){
                batches = this.$parent.Form.batches.filter(field=>{
                    return !field.batchNumber && !field.batchMRP && !field.batchSize && !field.purchaseRate && !field.mfgDate && !field.expDate && !field.field4
                    // return !field.batchNumber && !field.batchMRP && !field.batchSize && !field.purchaseRate && !field.mfgDate && !field.expDate && !field.opening && !field.field4
                })
            }
            else{
                batches = this.$parent.Form.batches.filter(field=>{
                    return !field.field1 && !field.field2 && !field.field3
                })
            }
            
            let searchText = this.searchValue ? this.searchValue.toLowerCase() : '';

            if(searchText){
                return batches.filter(item => {
                    let batchNumber = item.batchNumber ? item.batchNumber.toLowerCase() : '';
                    let batchSize = item.batchSize ? item.batchSize.toLowerCase() : '';
                    let batchMRP = item.batchMRP ? item.batchMRP.toString() : '';
                    let purchaseRate = item.purchaseRate ? item.purchaseRate.toString(): '';
                    let field1 = item.field1 ? item.field1.toLowerCase() : '';
                    let field2 = item.field2 ? item.field2.toLowerCase() : '';
                    let field3 = item.field3 ? item.field3.toLowerCase() : '';
                    let field4 = item.field4 ? item.field4.toLowerCase() : '';
                    return batchNumber.match(searchText) || batchSize.match(searchText) || batchMRP.match(searchText) || purchaseRate.match(searchText) || field1.match(searchText) || field2.match(searchText) || field3.match(searchText) || field4.match(searchText);
                })
            }
            else{
                return batches;
            }
        },

        fn_updateDate: function(newValue, dateType){
            // this[dateType] = newValue;
            // let tempFields = [...this.batchFields];
            let fIndex = this.batchFields.findIndex(item=>{return item.id === dateType});
            this.batchFields[fIndex].value = newValue;
            // this.$forceUpdate();
            // this.batchFields[fIndex] = {...this.batchFields[fIndex], value:newValue};
            // this.$set(tempFields[fIndex], 'value', value);
            // this.batchFields = [...tempFields];
            // this.$forceUpdate();
            // this.batchFields = [...this.batchFields];
            // console.log(this.batchFields);
            // for(let i=0; i<this.batchFields.length; i++){
            //     if(this.batchFields[i].id === dateType){
            //         this.$set(this.batchFields[0], 'value', value);
            //         break;
            //     }
            // }
            // console.log(this.batchFields);
            // this.$parent.fn_closeDialog();
        },

        fn_removeBatch: function(batchId){
            let itemIndex = this.$parent.Form.batches.findIndex(item=>{return item.batchId === batchId});
            this.$parent.Form.batches.splice(itemIndex, 1);
            this.fn_calculateTotalQty();
        },

        fn_areFieldsAvailable: function(){
            if(this.$parent.Form.selectedBatchType == 1){
                let batches = this.batchFields.filter(field=>{
                    return field.id == 'batchNumber' || field.id == 'batchMRP' || field.id == 'purchaseRate' || field.id == 'batchSize' || field.id == 'mfgDate' || field.id == 'expDate' || field.id == 'field4'
                })
                return batches.length ? true : false;
            }
            else{
                let series = this.batchFields.filter(field=>{
                    return field.id == 'field1' || field.id == 'field2' || field.id == 'field3'
                })
                return series.length ? true : false;
            }
        },

        fn_changeOutOfStockFilter: function(val){
            if(this.formType == 'outward'){
                localStorage.setItem('select_batch_filter', val ? 1 : 0);
            }
        },

        fn_calculateTotalQty: function(batch){
            if(batch){
                batch.selected = batch.productQty ? true : false;
            }

            this.totalQty = 0;
            let items = this.filteredBatches();
            for(let i=0; i<items.length; i++){
                if(items[i].productQty || (this.openingForm && items[i].opening)){
                    let qty = this.openingForm && items[i].opening ? items[i].opening : items[i].productQty;
                    if(!this.openingForm && items[i].selected){
                        this.totalQty =  this.totalQty + Number(qty);
                    }
                    else{
                        this.totalQty =  this.totalQty + Number(qty);
                    }
                }
            }
        },

        fn_autoFillQty: function(batch){
            if(this.productData){
                if(this.$parent.Form.selectedBatchType == 2){
                    batch.productQty = batch.selected ? 1 : null;
                }
                else{
                    if(batch.selected){
                        if(this.productData && this.productData.productVariants.length && this.productData.productVariants[0].qty){
                            if(this.totalQty < this.productData.productVariants[0].qty && batch.currentStock > 0){
                                batch.productQty =  Number(this.productData.productVariants[0].qty) - this.totalQty;
                                if(batch.productQty && batch.currentStock < batch.productQty){
                                    batch.productQty = batch.currentStock;
                                }
                            }
                        }
                    }
                    else{
                        batch.productQty = null;
                    }
                }
            }
            else{
                if(this.$route.name == 'newStockAdjustment' || this.$route.name == 'stockAdjustmentEdit'){
                    if(batch.selected && this.$parent.Form.qty){
                        if(this.totalQty < this.$parent.Form.qty){
                            batch.productQty =  Number(this.$parent.Form.qty) - this.totalQty;
                        }
                    }
                }
            }
            this.fn_calculateTotalQty();
        },

        fn_inputOpening: function(batch){
            batch.selected = batch.opening ? true : false;
            this.fn_calculateTotalQty();
        },

        showHideOutofStock: function(batch){
            if(batch.isNew){
                return true;
            }
            else{
                if(!this.includeOutOfStock){
                    return batch.currentStock > 0 ? true : false;
                }
                else{
                    return true;
                }
            }
        },

        fn_checkDate: function(batch){
            let date = this.$moment(new Date()).startOf('day');
            let eDate = this.$moment(new Date(batch.expDate)).startOf('day');
            if(date > eDate){
                return true;
            }
            else{
                return false;
            }
        },

        fn_getDate: function(batch){
            let dt = this.$moment(batch.expDate).endOf('day');
            let value = this.$moment(dt).from(new Date());
            if(value.indexOf('hours ago') != -1){
                return 'a day ago';
            }
            else{
                return value;
            }
        },

        fn_getOpeningFormUnit: function(){
            if(this.selectedUnitName){
                return this.selectedUnitName;
            }
            else if(this.$parent.Form.selectedUnit){
                return this.$parent.Form.selectedUnit.itemName;
            }
            else{
                if(this.$parent.Form.selectedItemData && this.$parent.Form.selectedItemData.productVariants && this.$parent.Form.selectedItemData.productVariants.length){
                    this.$parent.Form.selectedItemData.productVariants[0].unitName
                }
            }
        }

        // getStockValue: function(batch){
        //     if(batch.isNew && batch.opening){
        //         return batch.opening;
        //     }
        //     else{
        //         return batch.currentStock;
        //     }
        // }

        // getDateValue: function(field){
        //     if(field.id == 'mfgDate' && this.mfgDate){
        //         return this.mfgDate;
        //     }
        //     else if(field.id == 'expDate' && this.expDate){
        //         return this.expDate;
        //     }
        // }
    }
}
</script>