<template>
    <div>
        <v-dialog v-model="dialog_stockadjustment_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>
                <v-toolbar :height="56" flat class="noprint">
                    <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                    <v-toolbar-title>{{$parent.type=='minus' ? 'Reduce Stock' : 'Add Stock'}}</v-toolbar-title>
                </v-toolbar>

                <v-divider class="noprint"></v-divider>

                <div class="data-container grey-back">

                  <ValidationObserver ref="observer">



                    <v-list class="pa-0">
                        <v-list-item style="height:36px">
                            <v-list-item-content>
                              <v-list-item-title>Date</v-list-item-title>
                            </v-list-item-content>

                            <span v-if="$parent.Form.transactionData.transactionDate">{{$parent.Form.transactionData.transactionDate | moment("DD-MMM-YYYY")}}</span> 

                            <v-list-item-action class="ml-0">
                                <v-btn icon @click="$parent.fn_openDatePicker('transactionDate', $parent.Form.transactionData.transactionDate)"><v-icon>date_range</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <template v-if="$parent.productData">
                      <v-layout ma-0 mt-2 row wrap white-back pt-4 pb-2>

                        <v-flex xs12 sm12 md12 form-item>

                          <v-text-field 
                            :disabled="true"
                            :hide-details="true"
                            :value="$parent.productData.name" 
                            label="Item Name"
                            placeholder=" ">
                          </v-text-field>

                          <div class="font12 right-align pt-2">
                            <template v-for="(item, index) in $parent.summaryArray">
                              <span :key="index" v-if="$parent.summaryArray.length">
                                Stock: <span :class="[item.qty<0 ? 'redcolor' : 'greencolor']">{{item.qty}} {{item.unitName}}</span>
                              </span>  
                            </template>
                          </div>
                         
                        </v-flex>

                        <v-flex xs6 sm6 md6 form-item>

                          <ValidationProvider v-slot="{ errors }" name="quantity" rules="required">

                            <v-text-field 
                            :disabled="$parent.Form.productBatches && $parent.Form.productBatches.length && $root.TransactionSettings.enableBatch ? true : false"
                              id="field_quantity"
                              :prefix="$parent.type == 'minus' ? '(-)' : '(+)'"
                              @input="$root.fn_boundDecimalLength($parent.Form, 'qty')"
                              v-model="$parent.Form.qty" 
                              label="Quantity"
                              type="number"
                              placeholder=" " 
                              :error-messages="errors"
                              required>
                            </v-text-field>

                          </ValidationProvider>
                                 
                        </v-flex>

                        <!-- <v-flex xs6 sm6 md6 pl-3>
                          <v-text-field
                            label="Unit"
                            :disabled="true"
                            :value="$parent.Form.selectedUnit ? $parent.Form.selectedUnit.itemName : $parent.productData.productVariants[0].unitName"
                          ></v-text-field>
                        </v-flex> -->

                        <template v-if="$parent.productData && $parent.productData.productVariants && $parent.productData.productVariants.length">
                          <v-flex xs6 sm6 md6 pr-3 pl-2 v-for="(item, vIndex) in $parent.productData.productVariants" :key="vIndex">
                            <v-text-field 
                              v-if="!item.secondaryUnitId"
                              :disabled="true"
                              :value="item.unitName"
                              label="Unit"
                              placeholder=" ">
                            </v-text-field>

                            <v-select
                              v-else
                              :items="[{itemId:item.unitId, itemName:item.unitName}, {itemId:item.secondaryUnitId, itemName:item.secondaryUnitName}]"
                              label="Unit"
                              :disabled="$parent.Form.selectedBatchType == 2 && $parent.Form.productBatches && $parent.Form.productBatches.length"
                              item-text="itemName"
                              item-value="itemId"
                              v-model="$parent.Form.selectedUnit"
                              :hide-details="true"
                              return-object
                              ref="ref_unitSelection"
                              @change="fn_unitChange($event, item)"
                            ></v-select>

                            <div class="pb-3 pt-1 right-align" v-if="item.secondaryUnitId">{{ item.packQuantity }} {{ item.unitName }} = {{ item.secondaryUnitPackQuantity }} {{ item.secondaryUnitName }}</div>
                          </v-flex>
                        </template>

                        <v-flex xs12 sm12 md12 form-item>

                          <ValidationProvider v-slot="{ errors }" name="rate" rules="min_value:0|decimal2">

                            <v-text-field 
                              id="field_rate"
                              :label="$parent.productData.productVariants[0].secondaryUnitId ? 'Rate (Per ' + $parent.Form.selectedUnit.itemName + ')' : 'Rate (Per ' + $parent.productData.productVariants[0].unitName + ')'"
                              type="number" 
                              placeholder=" "
                              v-model.number="$parent.Form.rate"
                              :error-messages="errors"
                              required>
                            </v-text-field>

                          </ValidationProvider>
                                
                          </v-flex>

                          <v-flex sm12 md12 xs12 pr-4>
                            <div class="font12 right-align">
                              Total Value: &#8377; {{$root.numberToLocale($parent.totalAmount)}}
                            </div>
                          </v-flex>

                              
                      </v-layout>

                      <div class="pt-2 grey-back full-width" v-if="$root.TransactionSettings.enableBatch">
					<template v-if="$parent.hasSeries && !$parent.hasOnlySeries">
						<v-radio-group class="pt-3 pl-3 pr-3 pb-1" white-background hide-details v-model="$parent.Form.selectedBatchType" row @change="$parent.fn_changeBatchType($event)">
							<v-radio label="Batch" :value="1"></v-radio>
							<v-radio label="Series" :value="2"></v-radio>
						</v-radio-group>
						<v-divider></v-divider>
					</template>

					<v-subheader class="white-background">
						<template v-if="$parent.Form.selectedBatchType == 2"><span v-for="(field, index) in $root.TransactionSettings.batchFields" :key="field.title"> <span v-if="field.id == 'field1' || field.id == 'field2' || field.id == 'field3'">{{ field.title }}<span v-if="$root.TransactionSettings.batchFields.length - 1 != index">/</span></span></span></template>
						<template v-else>Batches</template>
						<v-spacer></v-spacer> 
						<v-btn small text outlined capitalize color="primary" :ripple="false" @click="$parent.fn_openDialogSelectBatch">
							<v-icon class="mr-1" font16 color="primary">add</v-icon> Add / Select
						</v-btn>
					</v-subheader>

					<!-- <v-subheader class="pr-0 white-background">Batches <v-spacer></v-spacer> 
						<v-btn color="primary"  capitalize flat class="mr-0" @click="fn_openDialogSelectBatch">Select Batch <v-icon color="primary" class="ml-1">keyboard_arrow_down</v-icon></v-btn> 
					</v-subheader> -->
          <template v-for="(batch, batchIndex) in $parent.Form.productBatches">
            <v-card flat v-if="$parent.fn_showHideBatches(batch)" :key="batchIndex">
              <v-divider></v-divider>
              <v-card-text class="pb-2">
                <div style="float: left;margin-right: 12px;">
                  <v-btn small nomargin icon flat @click="$parent.fn_removeBatch(batchIndex)"><v-icon>close</v-icon></v-btn>
                </div>
                <v-layout class="batch_layout" ma-0 row wrap>
                  <!-- <v-flex xs2 sm2 md2 mb-2>
                    <v-btn small nomargin icon flat @click="fn_removeBatch(batchIndex)"><v-icon>close</v-icon></v-btn>
                  </v-flex> -->
                  <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchNumber">
                    <div light-opacity-color>Batch No.</div>
                    <div>{{ batch.batchNumber }}</div>
                  </v-flex>
                  <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchSize">
                    <div light-opacity-color>Size</div>
                    <div>{{ batch.batchSize }}</div>
                  </v-flex>
                  <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchMRP">
                    <div light-opacity-color>MRP</div>
                    <div>{{ batch.batchMRP }}</div>
                  </v-flex>
                  <v-flex xs3 sm3 md3 mb-2 v-if="batch.mfgDate">
                    <div light-opacity-color>Mfg. Date</div>
                    <div v-if="batch.mfgDate">{{ batch.mfgDate | moment('DD-MMM-YYYY') }}</div>
                  </v-flex>
                  <v-flex xs3 sm3 md3 mb-2 v-if="batch.expDate">
                    <div light-opacity-color>Exp. Date</div>
                    <div v-if="batch.expDate">{{ batch.expDate | moment('DD-MMM-YYYY') }}</div>
                  </v-flex>
                  <v-flex xs3 sm3 md3 mb-2 v-if="batch.purchaseRate">
                    <div light-opacity-color>Purchase Rate</div>
                    <div>{{ batch.purchaseRate }}</div>
                  </v-flex>
                  <v-flex xs3 sm3 md3 mb-2 v-if="batch.field1">
                    <div light-opacity-color>Serial Number</div>
                    <div>{{ batch.field1 }}</div>
                  </v-flex>
                  <v-flex xs3 sm3 md3 mb-2 v-if="batch.field2">
                    <div light-opacity-color>IMEI Number</div>
                    <div>{{ batch.field2 }}</div>
                  </v-flex>
                  <v-flex xs3 sm3 md3 mb-2 v-if="batch.field3">
                    <div light-opacity-color>Part Number</div>
                    <div>{{ batch.field3 }}</div>
                  </v-flex>
                  <v-flex xs3 sm3 md3 mb-2 v-if="batch.field4">
                    <div light-opacity-color>Model Number</div>
                    <div>{{ batch.field4 }}</div>
                  </v-flex>
                </v-layout>

                <v-layout row wrap ma-0 style="padding-left: 40px;" v-if="$parent.Form.selectedBatchType == 1">
                  <!-- <v-flex xs4 sm4 md4 pr-2 :class="{'error_background':batch.currentStock<0}">
                    <v-text-field
                      readonly
                      :error="batch.currentStock<0"
                      label="Stock"
                      :value="batch.currentStock ? batch.currentStock : 0"
                      placeholder=" "
                    ></v-text-field>
                  </v-flex> -->

                  <v-flex xs6 sm6 md6>
                    <v-text-field
                      label="Quantity"
                      v-model.number="batch.productQty"
                      @input="$parent.fn_changeBatchQty"
                      type="number"
                      placeholder=" "
                      :hide-details="true"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 sm6 md6 pl-3>
                    <v-text-field
                      label="Unit"
                      :disabled="true"
                      :value="$parent.Form.selectedUnit ? $parent.Form.selectedUnit.itemName : $parent.productData.productVariants[0].unitName"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12 pt-1 pb-1 pr-2 right-align :class="{'error_background redcolor':batch.currentStock<0}">
                    Current Stock: {{ batch.currentStock ? batch.currentStock : 0 }} {{ $parent.productData.productVariants[0].unitName }}
                  </v-flex>

                  <v-flex xs12 sm12 md12 redcolor pb-2 v-if="batch.expDate && $parent.fn_checkDate(batch)">
                    <v-icon class="mr-1 font16 redcolor">info_outline</v-icon> Expired {{ fn_getDate(batch) }}
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </template>
				</div>

                      <v-layout row wrap white-back ma-0 mt-2>

                          <v-flex sm12 md12 xs12 pa-4>
                            <ValidationProvider v-slot="{ errors }" name="Notes" rules="max:255">
                              <v-textarea
                                rows="2"
                                label="Notes" 
                                auto-grow
                                :counter="255"
                                v-model="$parent.Form.transactionData.notes"
                                :error-messages="errors">
                              </v-textarea>
                            </ValidationProvider>
                          </v-flex>
                      </v-layout>
                    </template>

                    

                  </ValidationObserver>

                  
                    
                </div>

                <!-- <div white-background full-width nopadding style="position:fixed;bottom:0;" v-if="$parent.productData">
                  <v-layout row wrap>

                    <template v-if="$parent.Form.productBatches && $parent.Form.productBatches.length">
                      <v-flex xs6 sm6 md6 pl-3 pt-2 pb-1 left-align>Total Qty:</v-flex>
                          <v-flex xs6 sm6 md6 pr-3 pt-2 pb-1 right-align>{{$parent.Form.qty}} {{ $parent.Form.selectedUnit ? $parent.Form.selectedUnit.itemName : productData.productVariants[0].unitName }}</v-flex>
                    </template>
                    

                    <v-flex xs6 sm6 md6 pl-3 pt-2 pb-2 left-align>Total Value</v-flex>
                        <v-flex xs6 sm6 md6 pr-3 pt-2 pb-2 right-align>&#8377; {{$root.numberToLocale(totalAmount)}}</v-flex>

                    <v-flex xs12 sm12 md12 height48>
                      <v-btn color="primary" full-height nomargin block no-round-borders noshadow :disabled="disabledBtn" @click="fn_checkValidations">Save</v-btn>
                    </v-flex>
                  </v-layout>
                </div> -->

                <v-card-actions white-back>
                    <v-layout row wrap ma-0>

                        <v-flex xs12 sm12 md12 height48>
                            <v-btn no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                        </v-flex>

                    </v-layout>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
import { required, max, min_value, numeric } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

// setInteractionMode('eager')

extend('required', {
  ...required,
  // message: '{_field_} can not be empty',
  message: 'This field can not be empty',
})

extend('max', max)
extend('min_value', min_value)
extend('numeric', numeric)

extend('decimal2', value => {

  let decimalCount = 0;
  if(Math.floor(value) != value){
     decimalCount = value.toString().split(".")[1].length || 0;
  }

  if(decimalCount <= 2){
    return true;
  }

  return 'This field must be numeric and may contain 2 decimal points.';

});

    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_stockadjustment_form: false,
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_stockadjustment_form = true;
            },
            closeDialogWeb: function () {
                this.dialog_stockadjustment_form = false;
            },
            fn_validateForm: function(){
              this.$refs.observer.validate().then((result) => {
                if(result) {
                  this.$parent.fn_checkValidations();
                }
                else{
                  this.$parent.saveBtnKey++;
                  this.fn_scrollToElement('error--text');
                }
              });
            },
            fn_unitChange: function(value, item){
              let currentItemId = this.$refs.ref_unitSelection[0]._data.selectedItems[0].itemId;
              if(currentItemId != value.itemId){
                if(value.itemId == item.secondaryUnitId){
                  this.$parent.Form.rate = (this.$parent.Form.rate * item.packQuantity) / item.secondaryUnitPackQuantity;
                }
                else{
                  this.$parent.Form.rate = (this.$parent.Form.rate * item.secondaryUnitPackQuantity) / item.packQuantity;
                  // product.rate = this.roundUpto2Decimals(product.rate * item.secondaryUnitPackQuantity);
                }
                // this.tempRate = this.Form.rate;
                this.$parent.Form.rate = this.roundUpto2Decimals(this.$parent.Form.rate); 
              }
            },
        }
    }
</script>