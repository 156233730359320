<template>
    <div class="bill_template template_1 full-width" :class="{'noborder':!data.templateSettings.tableRowBorder, 'preview':preview, 'print-settings':isEditable, 'gridall':getGridClass==1, 'font_small':invoiceFieldsObj.fontSize.value=='small', 'font_large':invoiceFieldsObj.fontSize.value=='large',  'font_xl':invoiceFieldsObj.fontSize.value=='xl', 'font_xxl':invoiceFieldsObj.fontSize.value=='xxl'}" v-if="dataLoaded">

        <!-- <div class="cancel_div" style="position: absolute;z-index: 0;bottom:50%;left: 15%;right: 10%;display: block;font-size: 100px !important;transform: rotate(-45deg);-webkit-transform: rotate(-45deg);color: rgba(0,0,0,0.1);">Cancelled</div> -->
        <!-- <div class="cancel_div" style="display: table-header-group;position: absolute;z-index: 0;top: 100px;;font-size:48px!important;color: rgba(255,0,0,0.2);transform: rotate(-45deg);">Cancelled</div> -->
        <!-- <div id="thingy">Cancelled Text</div> -->
        <table class="invoice_table invoice_table_top" id="body_template_1" full-width>
            <tbody>

                <template>
                    <tr>
                        <td :colspan="headers.length" left-align :style="{'background':invoiceColor}" class="noborder">
                            <div full-height class="title invoice_title pt-1 pb-1 center-align font-heavy font20">
                                <template v-if="$root.CurrentBusiness.GSTRegistrationStatus == 1 && data.transactionDetails.transactionListId == 10003">
                                    {{summary.totalTax && !hasNilRatedItems ? data.transactionDetails.invoiceTitle : summary.totalTax && hasNilRatedItems ? 'Invoice-cum-Bill of Supply' : 'Bill Of Supply'}}
                                </template>
                                <template v-else>
                                    {{data.transactionDetails.invoiceTitle}}
                                </template>
                            </div>
                        </td>
                    </tr>

                    <tr right-align v-if="!data.transactionDetails.status">
                        <td class="noborder redcolor" :colspan="headers.length">Cancelled!</td>
                    </tr>

                    <tr center-align v-if="subtitle.data && subtitle.data.content">
                        <td class="noborder pl-2 pr-2" :colspan="headers.length">
                            <div v-html="subtitle.data.content" v-ripple :class="{'editable':isEditable}" @click="fn_editPrintField(subtitle.fieldId)"></div>
                        </td>
                    </tr>

                    <tr>
                        <td class="noborder border-bottom business_row" :colspan="headers.length" :class="{'pt-5':isEditable}">

                            <div class="col50_2 no-right-padding no-left-padding" :class="{'hasLogo':!preview && data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && fn_showSection(invoiceFieldsObj.businessLogo.status)}">

                                <div class="business_logo" v-ripple v-if="data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && fn_showSection(invoiceFieldsObj.businessLogo.status)" :class="{'editable':isEditable, 'fade-opacity':!invoiceFieldsObj.businessLogo.status}" @click="fn_editLogo">
                                    <img :src="$root.CurrentBusiness.businessLogo"/>
                                </div>

                                <div class="business_logo" :class="{'editable':isEditable}" @click="fn_editLogo" white-back v-if="(!data.templateSettings.businessLogo || !$root.CurrentBusiness.businessLogo) && isEditable">    
                                    <div style="text-align: center;padding: 14px 6px;font-size: 10px;">+ Logo</div>
                                </div>

                                <!-- <div style="clear:both;width:48px;float:left" class="pt-0" v-if="isEditable && data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && logoField.fieldId">
                                    <v-switch class="hide-margins small-switch mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="logoField.showInPrintOut" @change="fn_toggleField($event, logoField.fieldId)"></v-switch>
                                </div> -->

                                <div class="business_details" :class="{'nopadding': (!data.templateSettings.businessLogo || !$root.CurrentBusiness.businessLogo || !invoiceFieldsObj.businessLogo.status) && !isEditable, 'pl-2':!preview && (data.templateSettings.businessLogo || $root.CurrentBusiness.businessLogo)}" v-if="header.data && header.data.content && fn_showSection(invoiceFieldsObj.header.status) || isEditable">
                                    <!-- <div v-html="header.data.content" v-ripple v-if="header.data && header.data.content" :class="{'editable':isEditable}" @click="fn_editBusiness"></div> -->
                                    <div v-html="header.data.content" v-ripple :class="{'editable':isEditable, 'fade-opacity':!invoiceFieldsObj.header.status, 'pa-2':header.data && !header.data.content}" @click="fn_editPrintField(header.fieldId)"></div>
                                    <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Business Details</div>
                                </div>

                            </div>

                            <div class="col50_2 no-right-padding no-left-padding header-right-section" right-align style="white-space:nowrap">
                                <!-- <div style="padding: 1px 2px;border:1px solid;margin-bottom:2px" :style="{'borderColor':invoiceColor}" v-if="data.transactionDetails.transactionListId == 10003 && fn_showInvoiceForText()">
                                    {{fn_showInvoiceForText()}}
                                </div> -->
                                <div style="margin-bottom:2px" v-if="data.transactionDetails.transactionListId == 10003 && fn_showInvoiceForText()">
                                    {{fn_showInvoiceForText()}}
                                </div>
                                <div class="break_word">{{data.transactionDetails.transactionListId == 10003 ? "Invoice" : fn_getTransactionTitle(data.transactionDetails.transactionListId)}} No: {{data.transactionDetails.transactionNumber}}</div>
                                <div>Date of {{data.transactionDetails.transactionListId == 10003 ? "Invoice" : fn_getTransactionTitle(data.transactionDetails.transactionListId)}}: {{data.transactionDetails.transactionDate | moment("DD-MMM-YYYY")}}</div>

                                <div v-if="data.transactionDetails.refNumber">Bill No: {{ data.transactionDetails.refNumber }}</div>
                                <div v-if="data.transactionDetails.refDate">Bill Date: {{ data.transactionDetails.refDate | moment("DD-MMM-YYYY") }}</div>

                                <div v-if="$root.CurrentBusiness.GSTRegistrationStatus == 1">Place of Supply: {{fn_getPlaceOfSupply()}}</div>
                                <div v-if="data.transactionDetails.paymentTerm && data.transactionDetails.paymentTerm != -1">Term of Payment: {{data.transactionDetails.paymentTerm}} Days</div>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td :colspan="headers.length" class="noborder" full-height nopadding>

                            <div full-height class="col33_3" :class="{'col50_2':!otherFields.length}">
                                
                                <template v-if="data.transactionDetails.partyDetails.partyName && fn_showSection(invoiceFieldsObj.billingLabel.status)">

                                    <span :class="{'fade-opacity':!invoiceFieldsObj.billingLabel.status}" font-heavy v-html="billingLabel.data.content" v-if="billingLabel.data && billingLabel.data.content"></span>

                                    <div v-ripple :class="{'editable':isEditable && data.transactionDetails.partyDetails.partyName, 'fade-opacity':!invoiceFieldsObj.billingLabel.status}" @click="fn_editParty">

                                        <div v-if="data.transactionDetails.partyDetails.partyName">
                                            <div>{{data.transactionDetails.partyDetails.partyName}}</div>
                                            <div v-if="billingAddress">{{billingAddress}}</div>
                                            <div v-if="partyPhoneBilling || data.transactionDetails.partyDetails.primaryMobileNumber">Ph: {{partyPhoneBilling ? partyPhoneBilling : data.transactionDetails.partyDetails.primaryMobileNumber}}</div>
                                            <div v-if="data.transactionDetails.partyDetails.email">{{'Email: ' + data.transactionDetails.partyDetails.email}}</div>
                                            <div v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.gstNumber">GSTIN: {{data.transactionDetails.partyDetails.gstNumber}}</div>
                                            <div v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.msmeUdyamNumber">MSME/Udyam No: {{data.transactionDetails.partyDetails.msmeUdyamNumber}}</div>
                                            <div v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.panNumber">PAN No: {{data.transactionDetails.partyDetails.panNumber}}</div>
                                        </div>

                                        <div class="editabled-field-label" v-if="isEditable && data.transactionDetails.partyDetails.partyName"><v-icon>edit</v-icon> Party Details</div>
                                        
                                    </div>

                                    <!-- <div v-if="isEditable">
                                        <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="billingLabel.showInPrintOut" @change="fn_toggleField($event, billingLabel.fieldId)"></v-switch>
                                    </div> -->

                                </template>

                                <div v-if="data.transactionDetails.transactionListId == 20005 && !data.transactionDetails.partyDetails.partyName">
                                    Supplier not applicable
                                </div>

                            </div>

                            <div full-height class="col33_3" :class="{'col50_2 noborder':!otherFields.length}">

                                <template v-if="data.transactionDetails.partyDetails.partyName && fn_showSection(invoiceFieldsObj.shippingLabel.status)">

                                    <span :class="{'fade-opacity':!invoiceFieldsObj.shippingLabel.status}" font-heavy v-html="shippingLabel.data.content" v-if="shippingLabel.data && shippingLabel.data.content"></span>

                                    <div v-ripple :class="{'editable':isEditable, 'fade-opacity':!invoiceFieldsObj.shippingLabel.status}" @click="fn_editParty('expense')">

                                        <div v-if="data.transactionDetails.partyDetails.partyName">
                                            <div>{{data.transactionDetails.partyDetails.partyName}}</div>
                                            <div v-if="shippingAddress">{{shippingAddress}}</div>
                                            <div v-if="partyPhoneShipping || data.transactionDetails.partyDetails.primaryMobileNumber">Ph: {{partyPhoneShipping ? partyPhoneShipping : data.transactionDetails.partyDetails.primaryMobileNumber}}</div>
                                            <div v-if="data.transactionDetails.partyDetails.email">{{'Email: ' + data.transactionDetails.partyDetails.email}}</div>
                                            <div v-if="data.transactionDetails.partyDetails && data.transactionDetails.partyDetails.panNumber">PAN No: {{data.transactionDetails.partyDetails.panNumber}}</div>
                                        </div>

                                        <div class="editabled-field-label" v-if="isEditable && data.transactionDetails.partyDetails.partyName"><v-icon>edit</v-icon> Party Details</div>
                                    </div>

                                    <!-- <div v-if="isEditable">
                                        <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17" nopadding v-model="shippingLabel.showInPrintOut" @change="fn_toggleField($event, shippingLabel.fieldId)"></v-switch>
                                    </div> -->
                                </template>

                            </div>


                            <div full-height class="col33_3" v-if="otherFields.length">
                                <template v-for="(field, fieldIndex) in otherFields">
                                    <div class="other-field-item" :key="fieldIndex" v-if="field.fieldValue">
                                        <!-- <template v-if="field.fieldValue"> -->
                                            <div v-if="field.fieldType == 'print' && field.additionalData.content" v-html="field.additionalData.content"></div>
                                            <div v-if="field.fieldType != 'print'">{{field.name}}{{field.fieldValue ? ':' : ''}}
                                                <span v-if="field.fieldType == 'dateTime'">{{field.fieldValue | moment("DD-MMM-YYYY")}}</span>
                                                <span v-else>{{field.fieldValue}}</span>
                                            </div>  
                                        <!-- </template> -->
                                        
                                    </div>
                                </template>
                            </div>

                        </td>
                    </tr>

                    <tr v-if="data.transactionDetails.expenseCategoryName">
                        <td class="noborder pl-2 pr-2" :colspan="headers.length">
                            <div><strong>Expense For</strong>: {{data.transactionDetails.expenseCategoryName}}</div>
                        </td>
                    </tr>
                </template>

            </tbody>
        </table>

        <div style="display: flex;justify-content: right;" @click="fn_openToggleOptions(description, itemDescription, brand, category, company)" right-align v-if="isEditable && filteredItems && filteredItems.length"> <div style="background: #f0f0f0;width: fit-content;padding: 0px 6px;"><v-icon>edit</v-icon> Item Details</div>	</div>
        <table class="invoice_table invoice_table_mid" full-width>
            <thead class="items_header">
                <tr class="items_header noborder" :style="{'background':invoiceColor, 'color':invoiceColor=='#4b77be' ? '#ffffff' : '#000000'}">
                    <template v-for="(header, index) in headers">
                        <td v-if="showHideHeader(header.id)" class="noborder" :key="index" :class="{'right-align':  header.name!='#' && header.name!='Description' && header.name!='HSN/SAC'}" :style="{'minWidth':header.name=='Description' ? '50%' : header.name=='Mfg Date' || header.name=='Exp Date' ? '80px' : 'auto'}">
                            <strong>{{header.name}}</strong>
                        </td>
                    </template>
                </tr>
            </thead>

            <tbody>

                <template v-if="filteredItems && filteredItems.length">
                    <template v-for="(item, itemIndex) in filteredItems" class="noborder">
                        <tr v-if="isRef" class="noborder border-bottom" :key="itemIndex"><td class="noborder" :colspan="headers.length"><strong>{{item.baseTransactionNumber}}</strong></td></tr>

                        <tr @click="fn_openToggleOptions(description, itemDescription, brand, category, company)" v-for="(row, index) in item.items" :key="item.baseTransactionNumber + index" class="noborder border-bottom items_row">
                            <td>{{index+1}}</td>
                            
                            <td style="width: 50%;">
                                {{row.itemName}}
                                <!-- <template v-if="row.itemDescription">
                                    <br>
                                    <span class="description_row"><em>Desc: {{ row.itemDescription }}</em></span>
                                </template>
                                <template v-if="row.description">
                                    <br>
                                    <span class="description_row"><em>Note: {{ row.description }}</em></span>
                                </template>							 -->
                                <template v-if="row.productCode && fn_showSection(invoiceFieldsObj.tableColProductCode.status)">
                                    <br>
                                    <span class="description_row" :class="{'fade-opacity':!invoiceFieldsObj.tableColProductCode.status}"><em>{{ row.productCode }}</em></span>
                                </template>
                                <template v-if="row.itemDescription && fn_showSection(invoiceFieldsObj.tableColItemDescription.status)">
                                    <br>
                                    <span class="description_row" :class="{'fade-opacity':!invoiceFieldsObj.tableColItemDescription.status}"><em>{{ row.itemDescription }}</em></span>
                                </template>
                                <template v-if="row.description && fn_showSection(invoiceFieldsObj.tableColItemNote.status)">
                                    <br>
                                    <span class="description_row" :class="{'fade-opacity':!invoiceFieldsObj.tableColItemNote.status}"><em>{{ row.description }}</em></span>
                                </template>
                                <template v-if="row.brand && fn_showSection(invoiceFieldsObj.tableColBrand.status)">
                                    <br>
                                    <span class="description_row" :class="{'fade-opacity':!invoiceFieldsObj.tableColBrand.status}"><em>{{ row.brand }}</em></span>
                                </template>
                                <template v-if="row.company && fn_showSection(invoiceFieldsObj.tableColCompany.status)">
                                    <!-- <br> -->
                                    <span class="description_row" :class="{'fade-opacity':!invoiceFieldsObj.tableColCompany.status}">/<em>{{ row.company }}</em></span>
                                </template>
                                <template v-if="row.category && fn_showSection(invoiceFieldsObj.tableColCategory.status)">
                                    <!-- <br> -->
                                    <span class="description_row" :class="{'fade-opacity':!invoiceFieldsObj.tableColCategory.status}">/<em>{{ row.category }}</em></span>
                                </template>

                                <br v-if="row.field1 || row.field2 || row.field3 || row.field4">
                                <template v-if="row.field1 && row.field1.length">
                                    <span>Serial Number: </span>
                                    <span class="description_row" v-for="(field, index) in row.field1" :key="index">{{ field }} <span v-if="row.field1.length - 1 != index">, </span></span>
                                    <br>
                                </template>

                                <template v-if="row.field2 && row.field2.length">
                                    <span>IMEI Number: </span>
                                    <span class="description_row" v-for="(field, index) in row.field2" :key="index">{{ field }} <span v-if="row.field2.length - 1 != index">, </span></span>
                                    <br>
                                </template>

                                <template v-if="row.field3 && row.field3.length">
                                    <span>Part Number: </span>
                                    <span class="description_row" v-for="(field, index) in row.field3" :key="index">{{ field }} <span v-if="row.field3.length - 1 != index">, </span></span>
                                    <br>
                                </template>

                                <template v-if="row.field4 && row.field4.length">
                                    <span>Model Number: </span>
                                    <span class="description_row" v-for="(field, index) in row.field4" :key="index">{{ field }} <span v-if="row.field4.length - 1 != index">, </span></span>
                                </template>
                            </td>

                            <td v-if="isHsn">{{row.hsn ? row.hsn : '-'}}</td>
                            <td right-align :class="{'fade-opacity':!invoiceFieldsObj.tableColQty.status}" v-if="fn_showSection(invoiceFieldsObj.tableColQty.status)">{{row.qty}}</td>

                            <td right-align v-if="row.batchNumber != undefined">{{row.batchNumber}}</td>
                            <td right-align v-if="row.batchSize != undefined">{{row.batchSize}}</td>
                            <td right-align v-if="row.mfgDate != undefined">{{row.mfgDate}}</td>
                            <td right-align v-if="row.expDate != undefined">{{row.expDate}}</td>
                            <td right-align v-if="row.purchaseRate != undefined">{{row.purchaseRate}}</td>

                            <td right-align :class="{'fade-opacity':!invoiceFieldsObj.tableColUnit.status}" v-if="fn_showSection(invoiceFieldsObj.tableColUnit.status)">{{row.unit}}</td>
                            <td right-align :class="{'fade-opacity':invoiceFieldsObj.tableColMrp && !invoiceFieldsObj.tableColMrp.status}" v-if="invoiceFieldsObj.tableColMrp && fn_showSection(invoiceFieldsObj.tableColMrp.status) && isMrp">{{row.mrp ? $root.fixed2Decimal(row.mrp) : ''}}</td>
                            <td right-align :class="{'fade-opacity':!invoiceFieldsObj.tableColRate.status}" v-if="fn_showSection(invoiceFieldsObj.tableColRate.status)">{{row.rate ? $root.fixed2Decimal(row.rate) : ''}}</td>

                            <template v-for="(header, headerIndex) in headers">
                                <td right-align :key="headerIndex" v-if="header.addOnsId">
                                    <template v-for="(addOn, addOnIndex) in row.addOns">
                                        <span v-if="header.addOnsId == addOn.addOnsId" :key="addOnIndex">
                                            {{addOn.value ? $root.fixed2Decimal(addOn.value) : ''}}
                                            <span v-if="addOn.percentage && addOn.value"><br>{{'@' + addOn.percentage + '%'}}</span>
                                        </span>
                                        <span v-else :key="addOnIndex">-</span>    
                                    </template>
                                </td>
                            </template>
                            
                            <!-- <td right-align v-if="summary.totalTax">{{row.taxableValue ? $root.fixed2Decimal(row.taxableValue) : ''}}</td> -->
                            <!-- <td right-align v-if="row.taxableValue && $root.CurrentBusiness.GSTRegistrationStatus == 1">{{row.taxableValue ? $root.fixed2Decimal(row.taxableValue) : ''}}</td> -->
                            <!-- <td right-align v-if="row.taxableValue && gstAddOnsTotal && gstAddOnsTotal.length">{{row.taxableValue ? $root.fixed2Decimal(row.taxableValue) : ''}}</td> -->
                            <td right-align v-if="hasTaxableColumn && gstAddOnsTotal && gstAddOnsTotal.length">{{row.taxableValue ? $root.fixed2Decimal(row.taxableValue) : ''}}</td>

                            <template v-for="(header, headerIndex) in headers">
                                <td right-align :key="headerIndex" v-if="header.gstAddOnsId">
                                    <template v-for="(addOn, addOnIndex) in row.gstAddOns">
                                        <span :key="addOnIndex" v-if="header.gstAddOnsId == addOn.addOnsId">
                                            {{addOn.value != null || addOn.value != undefined ? $root.fixed2Decimal(addOn.value) : ''}}
                                            <span><br>{{'@' + addOn.percentage + '%'}}</span>
                                        </span>    
                                    </template>
                                </td>    
                            </template>
                            
                            <td right-align>{{row.rate ? $root.fixed2Decimal(row.total) : 'Free'}}</td>
                        </tr>
                    </template> 
                </template>
                
                <template v-if="filteredItems && !filteredItems.length">
                    <tr class="noborder border-bottom items-row items_row">
                        <td>1</td>
                        <td style="width: 80%;"><span class="description_row">{{data.transactionDetails.description}}</span></td>
                        <td class="right-align">{{$root.numberToLocale(data.transactionDetails.roundedAmount)}}</td>
                    </tr>
                </template>
                    
                <tr class="items_footer noborder border-bottom" v-if="filteredItems && filteredItems.length" :style="{'background':invoiceColor}">
                    <td :colspan="isHsn ? 3 : 2"><strong>Total (&#8377;)</strong></td>

                    <td right-align :class="{'fade-opacity':!invoiceFieldsObj.tableColQty.status}" v-if="fn_showSection(invoiceFieldsObj.tableColQty.status)">
						<strong>{{qtyTotal ? qtyTotal : ''}}</strong>
					</td>

                    <!-- <td :colspan="headers.length - addOnsTotal.length - gstAddOnsTotal.length - (isMrp ? 5 : 6) - (isHsn ? 1 : 0)"></td> -->
                    <td :colspan="fn_footerColspanSize()"></td>

                    <template v-for="(header, headerIndex) in headers">
                        <td right-align :key="headerIndex" v-if="header.addOnsId">
                            <template v-for="(item, index) in addOnsTotal">
                                <span :key="index" v-if="header.addOnsId == item.addOnsId">
                                    <strong>{{$root.numberToLocale(item.value)}}</strong>
                                </span>    
                            </template>
                        </td>    
                    </template>
                    
                    <!-- <td right-align v-if="summary.totalTax"><strong>{{$root.numberToLocale(data.transactionDetails.taxableValue)}}</strong></td> -->
                    <td right-align v-if="data.transactionDetails.taxableValue && gstAddOnsTotal && gstAddOnsTotal.length"><strong>{{$root.numberToLocale(data.transactionDetails.taxableValue)}}</strong></td>
                    
                    <template v-for="(header, headerIndex) in headers">
                        <td right-align :key="headerIndex" v-if="header.gstAddOnsId">
                            <template v-for="(item, index) in gstAddOnsTotal">
                                <span :key="index" v-if="header.gstAddOnsId == item.gstAddOnsId">
                                    <strong>{{$root.numberToLocale(item.value)}}</strong>
                                </span>    
                            </template>
                        </td>    
                    </template>
                    

                    <td right-align><strong>{{$root.numberToLocale(netTotal)}}</strong></td>
                </tr>

                <!-- Footer in case of direct amount invoice (no items) -->
                <tr class="items_footer noborder border-bottom" v-else :style="{'background':invoiceColor}">
                    <td colspan="2"><strong>Total</strong></td>
                    <td class="right-align"><strong>{{$root.numberToLocale(data.transactionDetails.roundedAmount)}}</strong></td>
                </tr>

            </tbody>

        </table>

        <table class="invoice_table invoice_table_bottom bottom-table" full-width>
            <tbody>
                <tr class="border-bottom" :class="{'border-top':!filteredItems.length}" v-if="isEditable || invoiceFieldsObj.amountInWords.status || invoiceFieldsObj.taxAndTotal.status">
                    <td :colspan="headers.length" class="single-column-double-section noborder">
                        <div full-height class="left-column">
                            <div v-if="fn_showSection(invoiceFieldsObj.amountInWords.status)" :class="{'editable':isEditable, 'fade-opacity':!invoiceFieldsObj.amountInWords.status}">
                                <div><strong>Total amount (in words)</strong></div>
                                <div>{{convertNumberToWords(data.transactionDetails.roundedAmount)}} Only.</div>
                            </div>

                            <div v-if="data.transactionDetails.notes" class="pt-1 topborder">
                                <strong>Note</strong><br>
                                {{data.transactionDetails.notes}}
                            </div>

                        </div>

                        <div full-height class="right-column" right-align>
                            <div v-if="fn_showSection(invoiceFieldsObj.taxAndTotal.status)" :class="{'editable':isEditable, 'fade-opacity':!invoiceFieldsObj.taxAndTotal.status}">
                                <div class="table_div">
                                    <template v-if="showTaxLabels()">
                                        <div class="table_div_row">
                                            <div class="table_div_col">Taxable Amount (&#8377;):</div>
                                            <div class="table_div_col pr-1" right-align>
                                                <strong>{{$root.numberToLocale(data.transactionDetails.taxableValue)}}</strong>
                                            </div>
                                        </div>
                                        <div class="table_div_row">
                                            <div class="table_div_col">Total Tax {{data.transactionDetails.isRcmApplicable ? '(RCM)' : ''}} (&#8377;):</div>
                                            <div class="table_div_col pr-1" right-align>
                                                <strong>{{$root.numberToLocale(summary.totalTax)}}</strong>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="table_div_row" v-if="data.transactionDetails.transactionTds && data.transactionDetails.transactionTds.length">
                                        <div class="table_div_col">
                                            {{data.transactionDetails.transactionTds[0].tdsAccountName}} (&#8377;):
                                        </div>
                                        <div class="table_div_col pr-1" right-align>
                                            <strong>-{{$root.numberToLocale(data.transactionDetails.transactionTds[0].amount)}}</strong>
                                        </div>
                                    </div>

                                    <div class="table_div_row" v-for="(addOn, addOnIndex) in data.transactionDetails.transactionAddOns" :key="addOnIndex">
                                        <div class="table_div_col">{{addOn.name}} {{addOn.isGst == 1 && data.transactionDetails.isRcmApplicable ? '(RCM)' : ''}} (&#8377;):</div>
                                        <div class="table_div_col pr-1" right-align>
                                            {{addOn.nature == 1 ? '-' : ''}}<strong>{{$root.numberToLocale(addOn.value)}}</strong>
                                        </div>
                                    </div>
                                    <div class="table_div_row" v-if="roundOffAmount">
                                        <div class="table_div_col">Round Off (&#8377;):</div>
                                        <div class="table_div_col pr-1" right-align>
                                            <strong>{{$root.numberToLocale(roundOffAmount)}}</strong>
                                        </div>
                                    </div>
                                    <div class="table_div_row">
                                        <div class="table_div_col"><strong>Total Amount (&#8377;):</strong></div>
                                        <div class="table_div_col pr-1" right-align>
                                            <strong>{{$root.numberToLocale(data.transactionDetails.roundedAmount)}}</strong>
                                        </div>
                                    </div>

                                    <template v-if="data.transactionDetails.paymentAccounts && data.transactionDetails.paymentAccounts.length">

                                        <div class="table_div_row" v-for="(item, index) in data.transactionDetails.paymentAccounts" :key="index">
                                            <div class="table_div_col">Paid by {{item.accountLedgerName == 'Cash A/c' ? 'Cash' : item.accountLedgerName}} (&#8377;):</div>
                                            <div class="table_div_col pr-1" right-align>
                                                <strong>{{$root.numberToLocale(item.amount)}}</strong>
                                            </div>
                                        </div>

                                    </template>

                                    <template v-else>
                                        <div class="table_div_row" v-if="showBalanceLabel()">
                                            <div class="table_div_col">Received (&#8377;):</div>
                                            <div class="table_div_col pr-1" right-align>
                                                <strong>0</strong>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="table_div_row" v-if="showBalanceLabel() && fn_showSection(invoiceFieldsObj.tableColSummaryBalance.status)" :class="{'fade-opacity':!invoiceFieldsObj.tableColSummaryBalance.status}">
                                        <div class="table_div_col">Remaining Amount (&#8377;):</div>
                                        <div class="table_div_col pr-1" right-align>
                                            <strong>{{data.transactionDetails.onCredit ? $root.numberToLocale(data.transactionDetails.onCredit) : '0'}}</strong>
                                        </div>
                                    </div>

                                    <template v-if="$root.TransactionSettings.showPartyBalance && (data.transactionDetails.transactionListId == '10003' || data.transactionDetails.transactionListId == '10004' || data.transactionDetails.transactionListId == '20003' || data.transactionDetails.transactionListId == '20004' || data.transactionDetails.transactionListId == '20005')">
										<div class="table_div_row" v-if="data.transactionDetails.lastInvoice">
											<div class="table_div_col">Previous Balance (&#8377;):</div>
											<div class="table_div_col pr-1" right-align>
												{{$root.numberToLocale(fn_previousBalance())}}
											</div>
										</div>

										<div class="table_div_row">
											<div class="table_div_col">Current Balance (&#8377;):</div>
											<div class="table_div_col pr-1" right-align>
												<strong>{{$root.numberToLocale(data.transactionDetails.partyDetails.currentBalance)}}</strong>
											</div>
										</div>
									</template>

                                </div>
                            </div>
                        </div>

                    </td>
                </tr>

                <!-- <template v-if="hsnSummary.items.length && $root.TransactionSettings.enableHsnSummary"> -->
                <template v-if="hsnSummary.items.length && hasHsnSummary && fn_showSection(invoiceFieldsObj.hsnSummary.status)">
                    <tr :style="{'background':invoiceColor}" class="border-bottom noborder hsn-summary-headers" :class="{'fade-opacity':!invoiceFieldsObj.hsnSummary.status}">
                        <td font-medium center-align :colspan="headers.length - hsnSummary.headers.length -2">HSN/SAC</td>
                        <td font-medium right-align :colspan="index == 0 || index == hsnSummary.headers.length - 1 ? 2 : 1" class="hsn-dynamic-headers" v-for="(hsnHeader, index) in hsnSummary.headers" :key="hsnHeader.name">{{ hsnHeader.name }}</td>
                    </tr>

                    <tr class="border-bottom noborder" v-for="(summary, summaryIndex) in hsnSummary.items" :key="summaryIndex" :class="{'fade-opacity':!invoiceFieldsObj.hsnSummary.status}">
                        <td :colspan="headers.length - hsnSummary.headers.length - 2">{{summary.hsn}}</td>
                        <td right-align :colspan="2">{{$root.fixed2Decimal(summary.taxableValue)}}</td>
                        <td right-align v-for="(gst, gstIndex) in summary.gstAddOns" :key="gstIndex"><div>{{$root.fixed2Decimal(gst.value)}} <br><template v-if="gst.percentage !=undefined">{{ '@' + gst.percentage + '%' }}</template> <template v-else>@0%</template></div></td>
                        <td right-align :colspan="2">{{$root.fixed2Decimal(summary.totalTax)}}</td>
                    </tr>

                    <tr :style="{'background':invoiceColor}" class="border-bottom noborder" :class="{'fade-opacity':!invoiceFieldsObj.hsnSummary.status}">
                        <td right-align font-medium :colspan="headers.length - hsnSummary.headers.length -2">Total</td>
                        <td right-align font-medium :colspan="footerIndex == 0 || footerIndex == hsnSummary.footers.length - 1 ? 2 : 1" v-for="(footerValue, footerIndex) in hsnSummary.footers" :key="footerIndex">{{$root.numberToLocale(footerValue)}}</td>
                    </tr>

                    <!-- <tr v-if="isEditable">
                        <td style="border-color: #fff !important;" :colspan="headers.length">
                            <v-switch class="hide-margins small-switch float-right mr-1 mt-1" hide-details :height="17" nopadding v-model="invoiceFieldsObj.hsnSummary.status" @change="fn_copyFieldObj(invoiceFieldsObj.hsnSummary)"></v-switch>
                        </td>
                    </tr> -->

                </template>

                <tr v-if="hasFooterSection">
                    <td :colspan="headers.length" class="noborder" :class="{'pt-5':isEditable}">

                        <footer-component v-if="dataLoaded"></footer-component>

                        <!-- <div class="bottom-row-container">
                            <div full-height class="bottom-left-column">

                                <template v-if="fn_showSection(terms.showInPrintOut) && terms.fieldId">

                                    <div :class="{'terms-section':printNote.data && printNote.data.content && printNote.showInPrintOut && terms.data && terms.data.content}">

                                        <div v-ripple class="pt-1 pb-1" :class="{'fade-opacity':!terms.showInPrintOut, 'editable':isEditable}" @click="fn_editPrintField(terms.fieldId)">
                                            <div v-html="terms.data.content" v-if="terms.data && terms.data.content"></div>

                                            <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Terms</div>
                                        </div>  

                                        <div v-if="isEditable && terms.data && terms.data.content">
                                            <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="terms.showInPrintOut" @change="fn_toggleField($event, terms.fieldId)"></v-switch>
                                        </div>

                                    </div>

                                </template>

                                <template v-if="fn_showSection(printNote.showInPrintOut) && printNote.fieldId">

                                    <div class="printnote-section" :class="{'mt-12 mb-8':isEditable, 'borderwhite':(!$root.CurrentBusiness.UpiID || !upiDetailField.showInPrintOut) && (!bankDetails.data || !bankDetails.data.content || !bankDetails.showInPrintOut)}">
                                        <div v-ripple class="pt-1 pb-1" :class="{'fade-opacity':!printNote.showInPrintOut, 'editable':isEditable}" @click="fn_editPrintField(printNote.fieldId)">
                                            <div v-html="printNote.data.content" v-if="printNote.data && printNote.data.content"></div>

                                            <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Print Note</div>
                                        </div>  

                                        <div v-if="isEditable && printNote.data && printNote.data.content">
                                            <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="printNote.showInPrintOut" @change="fn_toggleField($event, printNote.fieldId)"></v-switch>
                                        </div>
                                    </div>

                                </template>

                                <template v-if="(showBankDetails() && $root.CurrentBusiness.UpiID && fn_showSection(upiDetailField.showInPrintOut)) || (showBankDetails() && isEditable)">
                                    
                                    <div class="upi-details-section" :class="{'mt-1':isEditable}">
                                        <div class="pb-1" :class="{'fade-opacity':!upiDetailField.showInPrintOut, 'editable':isEditable}" nopadding @click="fn_editUpiDetails">

                                            <template v-if="$root.CurrentBusiness.UpiID">
                                                <div class="qr-code-container">
                                                    <img :src="paymentQrCodeUrl"/>
                                                </div>
                                                
                                                <div class="qr-code-details">
                                                    <p>Scan and Pay using UPI</p>
                                                    <p>UPI ID: {{$root.CurrentBusiness.UpiID}}</p>
                                                    <p>UPI Name: {{$root.CurrentBusiness.UPIName}}</p>
                                                </div>
                                            </template>

                                            <template v-if="isEditable">
                                                <div class="editabled-field-label" v-if="$root.CurrentBusiness.UpiID"><v-icon>edit</v-icon> UPI Details</div>
                                                <div class="editabled-field-label" v-else><v-icon>add</v-icon> UPI Details</div>    
                                            </template>

                                        </div>

                                        <div class="left-align" v-if="isEditable && $root.CurrentBusiness.UpiID && upiDetailField.fieldId">
                                            <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="upiDetailField.showInPrintOut" @change="fn_toggleField($event, upiDetailField.fieldId)"></v-switch>
                                        </div>
                                    </div>

                                </template>

                                <template v-if="(showBankDetails() && bankDetails.data && bankDetails.data.content && fn_showSection(bankDetails.showInPrintOut)) || (showBankDetails() && isEditable)">

                                    <div class="bank-details-section" :class="{'mt-1':isEditable}">
                                        <div class="pb-1" v-ripple :class="{'fade-opacity':!bankDetails.showInPrintOut, 'editable':isEditable}" @click="fn_editPrintField(bankDetails.fieldId)">

                                            <template v-if="isEditable">
                                                <div v-if="bankDetails.data && bankDetails.data.content" class="editabled-field-label"><v-icon>edit</v-icon> Bank Details</div>
                                                <div v-else class="editabled-field-label"><v-icon>add</v-icon> Bank Details</div>   
                                            </template>
                                            
                                            
                                            <template v-if="bankDetails.data && bankDetails.data.content">
                                                <div v-html="bankDetails.data.content"></div>
                                            </template>

                                        </div>

                                        <div v-if="isEditable && bankDetails.data && bankDetails.data.content">
                                            <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="bankDetails.showInPrintOut" @change="fn_toggleField($event, bankDetails.fieldId)"></v-switch>
                                        </div>
                                    </div>
                                    

                                </template>
                                

                            </div>


                            <div nopadding class="bottom-right-column">

                                <template v-if="fn_showSection(signatory.showInPrintOut)">

                                    <div class="pt-1 pb-1" :class="{'fade-opacity':!signatory.showInPrintOut, 'editable':isEditable}" @click="fn_editPrintField(signatory.fieldId, 'signature')">

                                        <div right-align class="signatory" v-ripple v-html="signatory.data.content" v-if="signatory.data && signatory.data.content"></div>

                                        <div class="editabled-field-label" v-if="isEditable"><v-icon>add</v-icon> Signature</div>

                                    </div>

                                    <div class="pb-3" v-if="isEditable && signatory.data && signatory.data.content">
                                        <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="signatory.showInPrintOut" @change="fn_toggleField($event, signatory.fieldId)"></v-switch>
                                    </div>  

                                </template>

                                <template v-if="fn_showSection(brandLogo.showInPrintOut)">
                                    <div :class="{'row-app-logo':isEditable}">
                                        <div class="invoice-footer mt-4" :class="{'fade-opacity': !brandLogo.showInPrintOut}">
                                            <div class="branding grey-back" style="position:relative">
                                                <v-btn @click="fn_toggleBrandLogo(false, 1)" v-if="!isEditable" style="border-radius: 4px;height: 18px;padding: 0px 8px 0px 6px;background: #fff;position: absolute;top: -19px;right: 0px;border: 1px #ddd solid;min-width: auto !important;" capitalize class="ma-0 mt-1 remove-logo-btn"><v-icon style="font-size: 10px !important;" class="mr-1" text color="primary">close</v-icon> Remove</v-btn>
                                                <a href="https://bizopsapp.com/" target="_blank">
                                                    <div class="branding-box">
                                                        <div class="dark-opacity-color powered">Powered By</div>
                                                        <div class="title primary-color">
                                                            <img class="brand_logo" src="../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo">BizOps App
                                                        </div>
                                                    </div>
                                                </a>

                                            </div>
                                        </div>
                                        

                                        <div class="pb-2 pt-2" v-if="isEditable">
                                            <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17" v-model="brandLogo.showInPrintOut"  nopadding @change="fn_toggleBrandLogo"></v-switch>
                                        </div>
                                    </div>
                                </template>

                                
                            </div>
                        </div> -->


                    </td>
                </tr>

                <tr v-if="fn_showSection(invoiceFieldsObj.bottomNote.status)">
                    <td center-align class="noborder borderwhite" :colspan="headers.length">
                        <div class="pa-1" v-ripple style="height:auto;" :class="{'fade-opacity':!invoiceFieldsObj.bottomNote.status, 'editable mt-3':isEditable}">
                            <div v-html="invoiceFieldsObj.bottomNote.value" v-if="invoiceFieldsObj.bottomNote.value"></div>
                            <div v-else>This is a Computer Generated Invoice.</div>
                        </div>
                    </td>
                </tr>

            </tbody>

            <!-- <tfoot> -->
                <!-- <tr class="noborder" :class="{'row-app-logo':isEditable}" v-if="fn_showSection(brandLogo.showInPrintOut)">
                    <td right-align class="noborder" :colspan="headers.length">
                        <div class="invoice-footer" :class="{'fade-opacity': !brandLogo.showInPrintOut}">
                            <div class="branding grey-back" style="position: relative;">
                                <v-btn @click="fn_toggleBrandLogo(false, 1)" v-if="!isEditable" style="font-size: 8px !important;border-radius: 4px;height: 20px;padding: 0px 8px 0px 6px;background: #fff;position: absolute;top: -20px;right: 0px;border: 1px #ddd solid;min-width: auto !important;" color="primary" capitalize text class="ma-0 mt-1 remove-logo-btn"><v-icon style="font-size: 10px !important;" class="mr-1" color="primary">close</v-icon> Remove</v-btn>
                                <a href="https://bizopsapp.com/" target="_blank">
                                    <div class="branding-box">
                                        <div class="dark-opacity-color powered">Powered By</div>
                                        <div class="font16 title primary-color">
                                            <img class="brand_logo" src="../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo">BizOps App
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="pb-2 pt-2" v-if="isEditable">
                            <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17" v-model="brandLogo.showInPrintOut"  nopadding @change="fn_toggleBrandLogo"></v-switch>
                        </div>
                    </td>
                </tr> -->
            <!-- </tfoot> -->

        </table>

        <!-- <div>headers length {{ headers.length }}</div>
        <div>addons length {{ addOnsTotal.length }}</div>
        <div>gst addons length {{ gstAddOnsTotal.length }}</div>
        <div>{{ isHsn ? 1 : 0 }}</div>
        <div>Colspan {{ fn_footerColspanSize() }}</div> -->
    </div>
</template>

<script>
import {invoiceTemplateMixin} from '../invoice_template_mixin'
import footerComponent from '../components/invoice_footer_section'

    export default{
        mixins: [invoiceTemplateMixin],
        components:{
            'footer-component':footerComponent
        }
    }
</script>
<style>
    /* ---------------- Primary Header Css -----------------*/

    /*.template_1 .business_logo{
        display: inline-block;
        width: 72px;
        position: relative;
        height: 72px;
        float: left;
    }

    .template_1 .business_logo img{
        width: 100%;
    }*/

    .template_1 .business_logo{
        margin-right: 4px;
        float: left;    
    }

    /* .template_1.preview .business_details{
        padding-left: 56px;
        position:relative;
    } */

    .template_1.print-settings .business_details{
        /* margin-left: 56px; */
        padding:0 !important;
    }

    .template_1 .business_details{
        display: grid;
    }

    .template_1 .hasLogo .business_details{
        margin-left: 56px;
    }

    .template_1.noborder, .template_1.noborder table, .template_1.noborder table tr, .template_1.noborder tr td, .template_1.noborder table tr td div, .template_1.noborder div{
        border:0 !important;
    }
</style>