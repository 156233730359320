<template>
    <!-- <div class="print_template" :class="{data.templateSettings.theme, 'preview':preview}" full-width> -->
    <div class="bill_template template_101 print_template" :class="{'preview':preview, 'print-settings':isEditable}" full-width>

        <table full-width>
            <thead class="primary-header noborder">

                <tr center-align>
                    <td colspan="3"><div><u>{{data.invoiceTitle}}</u></div></td>
                </tr>

                <tr right-align v-if="!data.status">
                    <td colspan="3" redcolor>Cancelled!</td>
                </tr>

                <tr>
                    <td colspan="3" class="business_row">

                        <div class="col67" :class="{'hasLogo':!preview && data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && fn_showSection(logoField.showInPrintOut)}">

                            <div class="business_logo mr-2" v-ripple :class="{'editable':isEditable, 'fade-opacity':!logoField.showInPrintOut}" v-if="data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && fn_showSection(logoField.showInPrintOut)" @click="fn_editLogo">
                                <img :src="$root.CurrentBusiness.businessLogo"/>
                                <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Logo</div>
                            </div>

                            <div class="business_logo" :class="{'editable':isEditable}" @click="fn_editLogo" white-background v-if="(!data.templateSettings.businessLogo || !$root.CurrentBusiness.businessLogo) && isEditable">    
                                <div style="text-align: center;padding: 18px 0px;font-size: 10px;">Logo</div>
                                <div class="editabled-field-label" v-if="isEditable"><v-icon>add</v-icon> Logo</div>
                            </div>

                            <div class="business_details" style="display: grid;" :class="{'nopadding': (!data.templateSettings.businessLogo || !$root.CurrentBusiness.businessLogo || !logoField.showInPrintOut) && !isEditable, 'pl-2':!preview && (data.templateSettings.businessLogo || $root.CurrentBusiness.businessLogo)}">
                                <!-- <div v-html="header.data.content" v-if="header.data && header.data.content" v-ripple @click="fn_editBusiness" :class="{'editable':isEditable}"></div> -->
                                <div v-html="header.data.content" v-ripple v-if="header.data && header.data.content" :class="{'editable':isEditable}" @click="fn_editPrintField(header.fieldId)"></div>
                                <div class="editabled-field-label" v-if="isEditable"><v-icon>edit</v-icon> Business Details</div>
                            </div>

                            <div style="clear:both;width:48px;" class="pt-1" v-if="isEditable && data.templateSettings.businessLogo && $root.CurrentBusiness.businessLogo && logoField.fieldId">
                                <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="logoField.showInPrintOut" @change="fn_toggleField($event, logoField.fieldId)"></v-switch>
                            </div>
                        </div>

                        <div class="col33" right-align>
                            <template v-if="data.invoiceTitle!='Journal'">
                                <strong>{{ data.invoiceTitle }} No.</strong>: {{data.transactionNumber}}<br>
                                <strong>Date</strong>: {{data.transactionDate | moment("DD-MMM-YYYY")}}
                            </template>
                        </div>  
                    </td>
                </tr>
            </thead>
            
            <tbody class="noborder">

                <template v-if="data.invoiceTitle=='Journal'">
                    <tr>
                        <td style="width:50%"><strong>{{data.invoiceTitle}} No.</strong>: {{data.transactionNumber}}</td>
                        <td style="width:50%" right-align colspan="2"><strong>Date</strong>: {{data.transactionDate | moment("DD-MMM-YYYY")}}</td>
                    </tr>

                    <tr class="items_header border-bottom border-top">
                        <td style="width:50%" class="border-right"><strong>Particulars</strong></td>
                        <!-- <td style="width:33%" right-align><strong>Amount</strong></td> -->
                        <td style="width:25%" right-align><strong>To Receive</strong></td>
                        <td style="width:25%" right-align><strong>To Pay</strong></td>
                    </tr>

                    <tr v-for="(item, index) in data.transactionLedgers" :key="index">
                        <td class="border-right">{{item.name}}
                            <div v-if="item.references && item.references.length && (data.transactionListId == 30001 || data.transactionListId == 30002)">
                                <!-- ref.refNumber is using in case of bwb invoices -->
                                <template v-for="ref in item.references">
                                    Invoice No.: {{ref.transactionNumber ? ref.transactionNumber : ref.referenceNumber}} Dated: {{ref.transactionDate | moment("DD-MMM-YYYY")}} 
                                </template>
                            </div>
                            <div v-if="item.bankDetails">
                                <span v-if="item.bankDetails.bankName">Bank Name: {{item.bankDetails.bankName}}</span>&nbsp;
                                <span v-if="item.bankDetails.refNumber">Ref Number: {{item.bankDetails.refNumber}}</span>&nbsp;
                                <span v-if="item.bankDetails.refDate">Ref Date: {{item.bankDetails.refDate | moment("DD-MMM-YYYY")}}</span>&nbsp;
                                <span v-if="item.bankDetails.transType">Type: {{item.bankDetails.transType}}</span>
                            </div>
                        </td>
                        <td right-align><span v-if="item.amountType==1">&#8377;</span> {{item.amountType == 1 ? $root.numberToLocale(item.amount) : ''}}</td>
                        <td right-align><span v-if="item.amountType==0">&#8377;</span> {{item.amountType == 0 ? $root.numberToLocale(item.amount) : ''}}</td>
                    </tr>

                    <tr class="items_footer border-top border-bottom">
                        <td class="border-right"><strong>Total</strong></td>
                        <td right-align><strong>&#8377; {{$root.numberToLocale(data.roundedAmount)}}</strong></td>
                        <td right-align><strong>&#8377; {{$root.numberToLocale(data.roundedAmount)}}</strong></td>
                    </tr>

                    <tr>
                        <td colspan="3">
                            <strong>Amount (in words)</strong>: {{convertNumberToWords(data.roundedAmount)}} only.
                        </td>
                    </tr>
                </template>

                <template v-else>
                    <tr class="border-top">
                        <td colspan="2">
                            <div font-medium>{{data.invoiceTitle == 'Receipt' ? 'Received From' : 'Paid To'}}</div>
                            <div style="height: auto !important;"><span font-medium>Name</span>: {{data.partyDetails[0].partyName}}</div>
                            <div v-if="data.partyDetails[0].primaryMobileNumber"><span font-medium>Mobile</span>: {{data.partyDetails[0].primaryMobileNumber}}</div>
                            
                            <div v-for="(item, index) in data.transactionLedgers" :key="index">
								<div v-for="(ref, refIndex) in item.references" :key="refIndex">
									<v-layout row wrap style="margin:0; padding: 4px 0px 4px 16px;" pl-4 pt-1 pb-1 ma-0>
										<template v-if="ref.isAgainstRef">
											<v-flex xs2 sm2 md2>
												Ref: {{ ref.transactionNumber }}
											</v-flex>

											<v-flex xs4 sm4 md4>
                                                {{ fn_getPrefix(ref.transactionListId) == 'minus' ? '(-)' : '' }}
												&#8377; {{$root.numberToLocale(ref.amount)}}
											</v-flex>
										</template>

										<template v-if="ref.isOnAccount">
											<v-flex xs2 sm2 md2>
												On Account
											</v-flex>

											<v-flex xs4 sm4 md4>
												&#8377; {{$root.numberToLocale(ref.amount)}}
											</v-flex>
										</template>
									</v-layout>
								</div>
							</div>
                        
                        </td>

                        <td>
                            <div class="right-column nopadding right-align">
                                <div font-medium>Amount</div>
                                <div>(&#8377;) {{$root.numberToLocale(data.roundedAmount)}}</div>
                                <!-- <div font-medium>Amount (in words)</div>
                                <div>{{convertNumberToWords(data.roundedAmount)}} only.</div> -->
                            </div>
                        </td>
                    </tr>

                    <tr class="border-top">
                        <td colspan="3" class="single-column-double-section">
                            

                            <div class="left-column nopadding">
                                <div font-medium class="pb-1">{{data.invoiceTitle == 'Contra' ? 'Mode' : 'Payment Mode'}}</div>
                                <div v-for="(item, index) in data.transactionLedgers" :key="index">
                                    <div v-if="item.name != data.partyDetails[0].partyName"><span font-medium>{{item.name}}</span>: &#8377; {{$root.numberToLocale(item.amount)}}</div>
                                </div>
                                <!-- <div><strong>Payment Mode</strong></div>
                                <div v-for="(item, index) in data.transactionLedgers" :key="index" v-if="item.name != data.partyDetails[0].partyName">
                                    <strong>{{item.name}}</strong>: &#8377; {{$root.numberToLocale(item.amount)}}   
                                </div> -->
                            </div>

                            <!-- <div class="right-column nopadding right-align" full-height>
                                <div font-medium>Amount</div>
                                <div>(&#8377;) {{$root.numberToLocale(data.roundedAmount)}}</div><br>
                                <div font-medium>Amount (in words)</div>
                                <div>{{convertNumberToWords(data.roundedAmount)}} only.</div>
                            </div> -->
                            
                            <!-- <div class="right-column nopadding right-align">
                                <div font-medium class="pb-1">Amount (in words)</div>
                                <div>{{convertNumberToWords(data.roundedAmount)}} only.</div>
                            </div> -->
                        </td>
                    </tr>

                    <tr class="border-top">
						<td colspan="3" class="single-column-double-section">
							

							<!-- <div class="left-column nopadding">
								<div font-medium class="pb-1">{{data.invoiceTitle == 'Contra' ? 'Mode' : 'Payment Mode'}}</div>
								<div v-for="(item, index) in data.transactionLedgers" :key="index" v-if="item.name != data.partyDetails[0].partyName">
									<div><span font-medium>{{item.name}}</span>: &#8377; {{$root.numberToLocale(item.amount)}}</div>
								</div>
							</div> -->

							<!-- <div class="right-column nopadding right-align" full-height>
								<div font-medium>Amount</div>
								<div>(&#8377;) {{$root.numberToLocale(data.roundedAmount)}}</div><br>
								<div font-medium>Amount (in words)</div>
								<div>{{convertNumberToWords(data.roundedAmount)}} only.</div>
							</div> -->

							<div class="left-column nopadding">
								<div font-medium class="pb-1">Amount (in words)</div>
								<div>{{convertNumberToWords(data.roundedAmount)}} only.</div>
							</div>

							<!-- <div class="right-column nopadding right-align" style="padding-right: 4px !important">
								<div font-medium>&#8377; {{$root.numberToLocale(data.roundedAmount)}}</div>
							</div> -->
							
							
						</td>
					</tr>

                </template>

                

                <!-- <tr v-if="data.notes">
                    <td colspan="3">
                        <strong>Note</strong><br>
                        {{data.notes}}
                    </td>
                </tr> -->

                <tr :class="{'border-top':data.invoiceTitle!='Journal'}">

                    <td colspan="3" class="single-column-double-section" :class="{'pt-3':isEditable}">
                            

                        <div class="left-column nopadding">
                            <div v-if="data.notes">
                                <strong>Note</strong><br>
                                {{data.notes}}  
                            </div>
                        </div>

                        <div full-height nopadding class="right-column bottom-right-column" :class="{'editable':isEditable}">

                            <template v-if="fn_showSection(signatory.showInPrintOut)">

                                <div full-height :class="{'fade-opacity':!signatory.showInPrintOut, 'pt-2':!signatory.data}" @click="fn_editPrintField(signatory.fieldId, 'signature')">

                                    <div right-align class="signatory" v-ripple v-html="signatory.data.content" v-if="signatory.data && signatory.data.content"></div>

                                    <div class="editabled-field-label" v-if="isEditable"><v-icon>add</v-icon> Signature</div>

                                </div>

                                <div class="pt-2 pb-2" v-if="isEditable && signatory.data && signatory.data.content">
                                    <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="signatory.showInPrintOut" @change="fn_toggleField($event, signatory.fieldId)"></v-switch>
                                </div>  

                            </template>

                        </div>
                        
                    </td>

                    <!-- <td>
                        <div v-if="data.notes">
                            <strong>Note</strong><br>
                            {{data.notes}}  
                        </div>
                    </td>
                    <td></td>
                    <td class="pt-3">

                        <div full-height nopadding class="right-column bottom-right-column" :class="{'editable':isEditable}">

                            <div full-height :class="{'fade-opacity':!signatory.showInPrintOut, 'pt-2':!signatory.data}" @click="fn_editPrintField(signatory.fieldId, 'signature')">

                                <div right-align class="signatory" v-ripple v-html="signatory.data.content" v-if="signatory.data && signatory.data.content"></div>

                                <div class="editabled-field-label" v-if="isEditable"><v-icon>add</v-icon> Signature</div>

                            </div>

                            <div class="pt-2 pb-2" v-if="isEditable && signatory.data && signatory.data.content">
                                <v-switch class="hide-margins small-switch float-right mt-0 mb-0 ml-0" hide-details :height="17"  nopadding v-model="signatory.showInPrintOut" @change="fn_toggleField($event, signatory.fieldId)"></v-switch>
                            </div>  
                            
                        </div>

                    </td> -->
                </tr>

            </tbody>

            <!-- <tfoot v-if="!preview">
                <tr class="noborder">
                    <td right-align class="noborder" colspan="3">
                        <div class="invoice-footer">
                            <a href="https://bizopsapp.com/" target="_blank">
                                <div style="font-size:8px !important;line-height:10px">Powered By</div>
                                <div style="font-size:10px !important;line-height:10px" class="title primary-color">
                                    <img style="width:10px" src="../../../assets/Final_BizOpsLogo.svg" class="brand_logo logo_small" alt="bizops_logo">
                                    BizOps App
                                </div>
                            </a>
                        </div>
                    </td>
                </tr>
            </tfoot> -->

            <tr class="noborder" v-if="fn_showSection(brandLogo.showInPrintOut)">
                <td right-align class="noborder" colspan="3">
                    <div class="invoice-footer" :class="{'fade-opacity': !brandLogo.showInPrintOut}">
                        <div class="branding grey-back" style="position: relative;">
                            <v-btn @click="fn_toggleBrandLogo(false, 1)" v-if="!isEditable" style="font-size: 8px !important;border-radius: 4px;height: 20px;padding: 0px 8px 0px 6px;background: #fff;position: absolute;top: -20px;right: 0px;border: 1px #ddd solid;min-width: auto !important;" color="primary" capitalize text class="ma-0 mt-1 remove-logo-btn"><v-icon style="font-size: 10px !important;" class="mr-1" color="primary">close</v-icon> Remove</v-btn>
                            <a href="https://bizopsapp.com/" target="_blank">
                                <div class="branding-box">
                                    <div class="dark-opacity-color powered">Powered By</div>
                                    <div class="font16 title primary-color">
                                        <img class="brand_logo" src="../../../assets/BizOpsApp_Icon_24x24.png" alt="bizops_logo">BizOps App
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="pb-2 pt-2" v-if="isEditable">
                        <v-switch class="hide-margins small-switch float-right mr-2 mt-0 mb-0 ml-0" hide-details :height="17" v-model="brandLogo.showInPrintOut"  nopadding @change="fn_toggleBrandLogo"></v-switch>
                    </div>
                </td>
            </tr>

        </table>

        <!-- <div class="page-footer mt-3">
            <div class="branding watermark">
                <div style="font-size:8px !important">Powered By</div>
                <div style="font-size:10px !important" class="title primary-color">
                    <img src="../../../assets/Final_BizOpsLogo.svg" class="brand_logo logo_small" alt="bizops_logo">
                    BizOps App
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
const transactionTypes = require('../../../config.js').configs.transactionTypes;

    export default{
        props:['data', 'preview', 'isEditable', 'editBusiness', 'editLogo', 'editPrintField'],
        data(){
            return{
                headers:[{"name":"Particulars", "width":67}, {"name":"Amount", "width":33}],
                items:[],
                // header:null,
                // terms:null,
                // bankDetails:null,
                // signatory:null
                header:{data:null, fieldId:null, showInPrintOut:true},
                signatory:{data:null, fieldId:null, showInPrintOut:true},
                logoField:{data:null, fieldId:null, showInPrintOut:false},
                brandLogo:{showInPrintOut:true},
            }
        },
        methods:{
            fn_showSection: function(showInPrintOut){
                if(this.isEditable){
                    return true;
                }
                else{
                    if(showInPrintOut){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },

            fn_toggleField: function(val, fieldId){
                // var self = this;
                let dataObj = {};
                dataObj.status = val;
                dataObj.fieldId = fieldId;
                this.postData('TogglePrintFieldVisibility', dataObj, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                        },
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_toggleBrandLogo: function(val, fromPreview){
                let dataObj = {};
                dataObj.LocalKey = 'bizopslogo_visibility';
                dataObj.LocalValue = val;
                this.$root.fn_saveLocalData(dataObj);

                fromPreview ? this.brandLogo.showInPrintOut = val : '';
            },

            fn_getToken: function(val){
                if(this.$root.appVersion < 109){
					return;
				}
                // this.showLoading("Please wait...");
                this.$root.fn_getAuthToken(token=>{
                    this.fn_brandLogoToServer(val, token);
                });
            },

            fn_brandLogoToServer: function(val, token){
                if(!navigator.onLine){
                    // this.showToast('Internet not available');
                    return;
                }
                
                // let self = this;
                let dataObj = {};
                dataObj.businessId = this.$root.CurrentBusiness.BusinessID;
                dataObj.token = token;

                let fnName = val ? "EnableLogo" : "RemoveLogo";
                this.getServerData(fnName, dataObj, 
                    {
                    onSuccess: function(response){
                        console.log(response);
                        // self.hideLoading();
                    }, 
                    onError: function(error){
                        console.log(error);
                        // self.hideLoading();
                        // if(typeof JSON.parse(error) == 'object'){
                        //   let response = JSON.parse(error);
                        //   self.showToast(response.Message);
                        // }
                    }, 
                    }
                );
            },

            fn_editPrintField(fieldId, type){
                this.editPrintField ? this.editPrintField(fieldId, type) : '';
            },

            fn_editParty(){
                this.editParty ? this.editParty() : '';
            },

            fn_editBusiness(){
                this.editBusiness ? this.editBusiness() : '';
            },

            fn_editLogo(){
                this.editLogo ? this.editLogo() : '';
            },

            fn_init: function(){
                this.fn_sortItems();    
                this.fn_processSections();
                this.fn_processKeywords();

                // setTimeout(() => {
				// 	let signatureImageElement = document.querySelector(".bill_template .bottom-right-column .signatory img");
				// 	if(this.signatory.data && signatureImageElement){
				// 		let maxWidth = 120;
				// 		let maxHeight = 48;
				// 		let srcWidth = signatureImageElement.offsetWidth ? signatureImageElement.offsetWidth : signatureImageElement.width;
				// 		let srcHeight = signatureImageElement.offsetHeight ? signatureImageElement.offsetHeight : signatureImageElement.height;
				// 		let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

				// 		let imgWidth = srcWidth * ratio;
				// 		let imgHeight = srcHeight * ratio;

				// 		signatureImageElement.style.width = imgWidth + "px";
				// 		signatureImageElement.style.height = imgHeight + "px";
				// 	}
				// });
            },
            fn_sortItems: function(){
                this.data.transactionLedgers.sort(function(a, b){
                    return b.amountType - a.amountType;
                });
            },
            fn_processSections: function(){
                for(let i=0; i<this.data.templateSettings.printTemplateSections.length; i++){
                    let additionalData = this.data.templateSettings.printTemplateSections[i].addData;
                    if(this.data.templateSettings.printTemplateSections[i].sectionName == 'header'){
                        // this.header = JSON.parse(additionalData);
                        this.header.data = JSON.parse(additionalData);
                        this.header.fieldId = this.data.templateSettings.printTemplateSections[i].fieldId;
                    }
                    else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'signature'){
                        if(additionalData){
                            this.signatory.data = additionalData.indexOf("img") != -1 ?  JSON.parse(additionalData) : null; 
                        }
                        else{
                            this.signatory.data = null;
                        }
                        // this.signatory.data = additionalData.indexOf("img") != -1 ?  JSON.parse(additionalData) : null;
                        this.signatory.fieldId = this.data.templateSettings.printTemplateSections[i].fieldId;
                        this.signatory.showInPrintOut = this.data.templateSettings.printTemplateSections[i].status;
                    }
                }

                if(this.data.templateSettings.printFieldLogo){
                    this.logoField.fieldId = this.data.templateSettings.printFieldLogo.fieldId;
                    this.logoField.showInPrintOut = this.data.templateSettings.printFieldLogo.status;
                }

                this.$root.fn_getLocalData('bizopslogo_visibility', response=>{
					let val = JSON.parse(response);
                    this.brandLogo.showInPrintOut = val === null ? true : val;
                })
            },
            fn_processKeywords: function(){
                this.fn_replaceKeywords(this.header.data);
                this.fn_replaceKeywords(this.signatory.data);
            },
            fn_replaceKeywords: function(category){
                if(category && category.content){
                    category.content = category.content.replaceAll('{{BusinessName}}', this.$root.CurrentBusiness.BusinessName);
                    category.content = category.content.replaceAll('{{BusinessAddress}}', this.$root.CurrentBusiness.Address ? this.$root.CurrentBusiness.Address : '');
                    category.content = category.content.replaceAll('{{BusinessState}}', this.$root.CurrentBusiness.State);
                    category.content = category.content.replaceAll('{{GSTIN}}', this.$root.CurrentBusiness.GSTNumber ? this.$root.CurrentBusiness.GSTNumber : '');

                    if(this.$root.CurrentBusiness.GSTRegistrationStatus == 3){
						category.content = category.content.replaceAll(/GSTIN: /g, '');
					}
                }
            },
            convertNumberToWords: function(amount) {
              // custom addon for paise
                let paise = null;
                let stramount = amount.toString();
                if(stramount.indexOf('.')!=-1){
                    paise = stramount.substring(stramount.indexOf('.') + 1);  
                }
                  // end of custom addon

                var words = new Array();
                words[0] = '';
                words[1] = 'One';
                words[2] = 'Two';
                words[3] = 'Three';
                words[4] = 'Four';
                words[5] = 'Five';
                words[6] = 'Six';
                words[7] = 'Seven';
                words[8] = 'Eight';
                words[9] = 'Nine';
                words[10] = 'Ten';
                words[11] = 'Eleven';
                words[12] = 'Twelve';
                words[13] = 'Thirteen';
                words[14] = 'Fourteen';
                words[15] = 'Fifteen';
                words[16] = 'Sixteen';
                words[17] = 'Seventeen';
                words[18] = 'Eighteen';
                words[19] = 'Nineteen';
                words[20] = 'Twenty';
                words[30] = 'Thirty';
                words[40] = 'Forty';
                words[50] = 'Fifty';
                words[60] = 'Sixty';
                words[70] = 'Seventy';
                words[80] = 'Eighty';
                words[90] = 'Ninety';
                amount = amount.toString();
                var atemp = amount.split(".");
                var number = atemp[0].split(",").join("");
                var n_length = number.length;
                var words_string = "";
                if (n_length <= 9) {
                    var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
                    var received_n_array = new Array();
                    for (var i = 0; i < n_length; i++) {
                        received_n_array[i] = number.substr(i, 1);
                    }
                    for (var k = 9 - n_length, j = 0; k < 9; k++, j++) {
                        n_array[k] = received_n_array[j];
                    }
                    for (var l = 0, m = 1; l < 9; l++, m++) {
                        if (l == 0 || l == 2 || l == 4 || l == 7) {
                            if (n_array[l] == 1) {
                                n_array[m] = 10 + parseInt(n_array[m]);
                                n_array[l] = 0;
                            }
                        }
                    }
                    var value = "";
                    for (var n = 0; n < 9; n++) {
                        if (n == 0 || n == 2 || n == 4 || n == 7) {
                            value = n_array[n] * 10;
                        } else {
                            value = n_array[n];
                        }
                        if (value != 0) {
                            words_string += words[value] + " ";
                        }
                        if ((n == 1 && value != 0) || (n == 0 && value != 0 && n_array[n + 1] == 0)) {
                            words_string += "Crores ";
                        }
                        if ((n == 3 && value != 0) || (n == 2 && value != 0 && n_array[n + 1] == 0)) {
                            words_string += "Lakhs ";
                        }
                        if ((n == 5 && value != 0) || (n == 4 && value != 0 && n_array[n + 1] == 0)) {
                            words_string += "Thousand ";
                        }
                        if (n == 6 && value != 0 && (n_array[n + 1] != 0 && n_array[n + 2] != 0)) {
                            words_string += "Hundred and ";
                        } else if (n == 6 && value != 0) {
                            words_string += "Hundred ";
                        }
                    }
                    words_string = words_string.split("  ").join(" ");
                }

                if(!paise){
                      return words_string;  
                  }
                  else{
                      if(paise.length==1){
                        paise = paise + "0";
                      }
                      
                      if(words_string){
                        return words_string + 'Rupees and ' + this.convertNumberToWords(paise) + 'Paise';  
                      }
                      else{
                        return '';
                      }
                  }
                
            },

            fn_getPrefix: function(transactionListId){

                if(
                    (this.data.transactionListId == transactionTypes.receipt.id && 
                    (transactionListId == transactionTypes.salesInvoice.id || transactionListId == transactionTypes.purchaseReturn.id)) || 
                    (this.data.transactionListId == transactionTypes.payment.id && 
                    (transactionListId == transactionTypes.purchaseInvoice.id || transactionListId == transactionTypes.salesReturn.id))
                ){
                    return 'plus';
                }
                else if(
                    (this.data.transactionListId == transactionTypes.receipt.id && 
                    (transactionListId == transactionTypes.salesReturn.id || transactionListId == transactionTypes.purchaseInvoice.id)) || 
                    (this.data.transactionListId == transactionTypes.payment.id && 
                    (transactionListId == transactionTypes.salesInvoice.id || transactionListId == transactionTypes.purchaseReturn.id))
                ){
                    return 'minus';
                }

            },
        }
    }
</script>

<style>
    /* ---------------- Primary Header Css -----------------*/

    .template_101 .business_logo{
        float: left;    
    }
    /* .template_101.preview .business_details{
        padding-left: 56px;
    }
    .template_101.print-settings .business_details{
        margin-left: 56px;
        padding:0 !important;
    }
    .template_101 .hasLogo .business_details{
        margin-left: 56px;
    } */
</style>