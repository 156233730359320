import QRCode from 'qrcode'
const transactionForms = require('../../config.js').configs.transactionForms;

export const invoiceTemplateMixin =  {
//   props:['data', 'preview', 'isEditable', 'editParty', 'editBusiness', 'editLogo', 'editTransactionFields', 'editPrintField', 'editUpiDetails', 'themeColor', 'invoiceFor', 'headersLength', 'calculateZoomPercent', 'bizOpsLogo'],
props:['data', 'preview', 'isEditable', 'editParty', 'editBusiness', 'editLogo', 'editTransactionFields', 'editPrintField', 'themeColor', 'headersLength', 'calculateZoomPercent', 'bizOpsLogo', 'openToggleOptions'],
  data(){
    return{
      billingAddress:null,
      partyPhoneBilling:null,
      partyPhoneShipping:null,
      shippingAddress:null,
      otherFields:[],
      headers:[],
      items:[],
      summary:{"totalTax":0},
      addOnsTotal:[],
      gstAddOnsTotal:[],
      qtyTotal:null,
      netTotal:0,
      isHsn:false,
      isMrp:false,
      // header:null,
      // terms:null,
      // bankDetails:null,
      // signatory:null,
      // subtitle:null,
      // billingLabel:null,
      // shippingLabel:null,
      header:{data:null, fieldId:null, showInPrintOut:true},
      terms:{data:null, fieldId:null, showInPrintOut:true},
      printNote:{data:null, fieldId:null, showInPrintOut:true},
      bankDetails:{data:null, fieldId:null, showInPrintOut:true},
      signatory:{data:null, fieldId:null, showInPrintOut:true},
      subtitle:{data:null, fieldId:null, showInPrintOut:true},
      billingLabel:{data:null, fieldId:null, showInPrintOut:true},
      shippingLabel:{data:null, fieldId:null, showInPrintOut:true},
      logoField:{data:null, fieldId:null, showInPrintOut:true},
      upiDetailField:{data:null, fieldId:null, showInPrintOut:true},
      brandLogo:{showInPrintOut:true},

      description:{showInPrintOut:true},
      itemDescription:{showInPrintOut:true},
      brand:{showInPrintOut:false},
      company:{showInPrintOut:false},
      category:{showInPrintOut:false},
      // printDetails:null,
      isRef:false,
      paymentQrCodeUrl:null,
      dataLoaded:false,
      hasNilRatedItems:false,
      gridClassValue:0,
      hasHsnSummary:false,
      hsnSummary:{headers:[{id:"taxableValue", "name":"Taxable Value"}], items:[], footers:[]},
      footerSectionValue:1,
      hasBottomNote:false,
      bottomNoteValue:null,
      hasTaxableColumn:false,

      invoiceFieldsObj:{
        "upiDetails": {"fieldId":"upiDetails", "sectionName":"UPI Details", "status":true, "groupName":"businessFields", "defaultStatus":true},
        "businessLogo": {"fieldId":"businessLogo", "sectionName":"Business Logo",  "status":true, "groupName":"businessFields", "defaultStatus":true},

        "itemName": {"fieldId":"itemName", "sectionName":"Item Name",  "status":true, "groupName":"Item", "defaultStatus":true},
        "additionalItemName": {"fieldId":"additionalItemName", "sectionName":"Addtional Item Name",  "status":false, "groupName":"Item", "defaultStatus":false},
        "itemDescription": {"fieldId":"itemDescription", "sectionName":"Item Description",  "status":true, "groupName":"Item", "defaultStatus":true},
        "brand": {"fieldId":"brand", "sectionName":"Brand",  "status":false, "groupName":"Item", "defaultStatus":false},
        "company": {"fieldId":"company", "sectionName":"Company",  "status":false, "groupName":"Item", "defaultStatus":false},
        "category": {"fieldId":"category", "sectionName":"Category",  "status":false, "groupName":"Item", "defaultStatus":false},
        "itemNote": {"fieldId":"itemNote", "sectionName":"Item Note",  "status":true, "groupName":"Item", "defaultStatus":true},
        "productCode": {"fieldId":"productCode", "sectionName":"Item Code",  "status":false, "groupName":"Item", "defaultStatus":false},

        "amountInWords": {"fieldId":"amountInWords", "sectionName":"Amount in words",  "status":true, "groupName":"InvoiceSection", "defaultStatus":true},
        "taxAndTotal": {"fieldId":"taxAndTotal", "sectionName":"Tax & Total",  "status":true, "groupName":"InvoiceSection", "defaultStatus":true},

        "hsnSummary": {"fieldId":"hsnSummary", "sectionName":"HSN Summary",  "status":false, "groupName":"InvoiceSection", "defaultStatus":false},

        "tableColItemDescription": {"fieldId":"tableColItemDescription", "sectionName":"Item Description",  "status":true, "groupName":"InvoiceTable", "defaultStatus":true},
        "tableColItemNote": {"fieldId":"tableColItemNote", "sectionName":"Item Note",  "status":true, "groupName":"InvoiceTable", "defaultStatus":true},
        "tableColBrand": {"fieldId":"tableColBrand", "sectionName":"Brand",  "status":false, "groupName":"InvoiceTable", "defaultStatus":false},
        "tableColCompany": {"fieldId":"tableColCompany", "sectionName":"Company",  "status":false, "groupName":"InvoiceTable", "defaultStatus":false},
        "tableColCategory": {"fieldId":"tableColCategory", "sectionName":"Category",  "status":false, "groupName":"InvoiceTable", "defaultStatus":false},
        "tableColQty": {"fieldId":"tableColQty", "sectionName":"Qty",  "status":true, "groupName":"InvoiceTable", "defaultStatus":true},
        "tableColUnit": {"fieldId":"tableColUnit", "sectionName":"Unit",  "status":true, "groupName":"InvoiceTable", "defaultStatus":true},
        "tableColMrp": {"fieldId":"tableColMrp", "sectionName":"MRP",  "status":true, "groupName":"InvoiceTable", "defaultStatus":true},
        "tableColRate": {"fieldId":"tableColRate", "sectionName":"Rate",  "status":true, "groupName":"InvoiceTable", "defaultStatus":true},
        "tableColProductCode": {"fieldId":"tableColProductCode", "sectionName":"Item Code",  "status":false, "groupName":"InvoiceTable", "defaultStatus":false},
        "tableColSummaryBalance": {"fieldId":"tableColSummaryBalance", "sectionName":"Remaining Amount",  "status":true, "groupName":"InvoiceTable", "defaultStatus":true},
      
        "header": {"fieldId":"header", "sectionName":"header", "status":true, "groupName":"PrintField", "defaultStatus":true},
        "terms": {"fieldId":"terms", "sectionName":"terms",  "status":true, "groupName":"PrintField", "defaultStatus":true},
        "bankDetails": {"fieldId":"bankDetails", "sectionName":"bankDetails", "status":true, "groupName":"PrintField", "defaultStatus":true},
        "signatory": {"fieldId":"signatory", "sectionName":"signatory", "status":true, "groupName":"PrintField", "defaultStatus":true},
        "subtitle": {"fieldId":"subtitle", "sectionName":"subtitle", "status":true, "groupName":"PrintField", "defaultStatus":true},
        "billingLabel": {"fieldId":"billingLabel", "sectionName":"billingLabel", "status":true, "groupName":"PrintField", "defaultStatus":true},
        "shippingLabel": {"fieldId":"shippingLabel", "sectionName":"shippingLabel", "status":true, "groupName":"PrintField", "defaultStatus":true},
        "printNote": {"fieldId":"printNote", "sectionName":"printNote", "status":true, "groupName":"PrintField",  "defaultStatus":true, "value":null},
        "bottomNote": {"fieldId":"bottomNote", "sectionName":"bottomNote", "status":false, "groupName":"PrintField",  "defaultStatus":false, "value":null},
      
        "ebc863d6-6954-b70a-b7fa-7a22fb3168f1": {"fieldId":"ebc863d6-6954-b70a-b7fa-7a22fb3168f1", "sectionName":"PO No.", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "86723266-62de-4aac-9511-89743e6b4b7a": {"fieldId":"86723266-62de-4aac-9511-89743e6b4b7a", "sectionName":"PO Date", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "402b1cb0-b900-458d-bdf6-f0f252b08693": {"fieldId":"402b1cb0-b900-458d-bdf6-f0f252b08693", "sectionName":"PO Receipt Date", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "f35e1fe8-88b9-446d-a215-96cc52676298": {"fieldId":"f35e1fe8-88b9-446d-a215-96cc52676298", "sectionName":"Country of Supply", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "5e2bd992-9a8f-4ee0-989c-4ee9d1816f52": {"fieldId":"5e2bd992-9a8f-4ee0-989c-4ee9d1816f52", "sectionName":"Shipping Billing No.", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "8132d938-4d36-4571-a919-08e1caf6891a": {"fieldId":"8132d938-4d36-4571-a919-08e1caf6891a", "sectionName":"Shipping Billing Date", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "5419d2e4-c754-4710-9777-aad716c1b285": {"fieldId":"5419d2e4-c754-4710-9777-aad716c1b285", "sectionName":"Shipping Port Code", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "7f0aca99-cabd-4b72-b282-0788e489ec38": {"fieldId":"7f0aca99-cabd-4b72-b282-0788e489ec38", "sectionName":"Type of Import", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "1747e2de-dac2-4b89-939f-ae36c3bea9ba": {"fieldId":"1747e2de-dac2-4b89-939f-ae36c3bea9ba", "sectionName":"Bill of Entry No", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "03e70fca-bb56-4758-918a-eae7aafa57f8": {"fieldId":"03e70fca-bb56-4758-918a-eae7aafa57f8", "sectionName":"Bill of Entry Date", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "e0c98224-f5c1-431f-a7a4-b093e9b1194a": {"fieldId":"e0c98224-f5c1-431f-a7a4-b093e9b1194a", "sectionName":"Bill of Entry Port Code", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "7382995c-7c69-4efc-852c-c33bee50075b": {"fieldId":"7382995c-7c69-4efc-852c-c33bee50075b", "sectionName":"Bilty No.", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "7b0cb0f9-30ab-4996-8de7-49133880e61d": {"fieldId":"7b0cb0f9-30ab-4996-8de7-49133880e61d", "sectionName":"Bilty Date", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "452bc92a-fcdc-15ec-4cf7-a1ed93b2c4e2": {"fieldId":"452bc92a-fcdc-15ec-4cf7-a1ed93b2c4e2", "sectionName":"Mode", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "2fd5218f-3060-60ea-177c-d5f36d6f2233": {"fieldId":"2fd5218f-3060-60ea-177c-d5f36d6f2233", "sectionName":"Transporter", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "b549972a-6a89-cf47-c33d-570b20422b87": {"fieldId":"b549972a-6a89-cf47-c33d-570b20422b87", "sectionName":"LR/CR/RR No.", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "f64f89cd-4fa4-c201-f305-f0b367630a0f": {"fieldId":"f64f89cd-4fa4-c201-f305-f0b367630a0f", "sectionName":"Vehicle No.", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "931b5e6a-f65f-7da7-739b-97da6f2ae5ce": {"fieldId":"931b5e6a-f65f-7da7-739b-97da6f2ae5ce", "sectionName":"E-Way Bill No", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "7398d416-398a-8ba8-09c5-e80d682f0f86": {"fieldId":"7398d416-398a-8ba8-09c5-e80d682f0f86", "sectionName":"E-Way Bill Date", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "ea0e195d-d26d-ed9a-917a-c3875b0ffc18": {"fieldId":"ea0e195d-d26d-ed9a-917a-c3875b0ffc18", "sectionName":"Distance (In KM)", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "733d2d3d-0e28-0c43-64c3-b3c6a183b24f": {"fieldId":"733d2d3d-0e28-0c43-64c3-b3c6a183b24f", "sectionName":"Sub Type", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "4d48c812-c0a9-4067-1d5f-5623034329d1": {"fieldId":"4d48c812-c0a9-4067-1d5f-5623034329d1", "sectionName":"Document Type", "status":true, "groupName":"OtherField", "defaultStatus":true},
        "48ca6b36-1c71-42b5-8450-acb83bcb82dd": {"fieldId":"48ca6b36-1c71-42b5-8450-acb83bcb82dd", "sectionName":"Status of E-Way Bill", "status":true, "groupName":"OtherField", "defaultStatus":true},

        "fontSize": {"fieldId":"fontSize", "sectionName":"Font Size", "status":true, "groupName":"Formatting", "defaultStatus":true, "value":"normal"},
      }
    }
  },
  methods:{

    showHideHeader: function(headerId){
      if(this.isEditable){
        return true;
      }
      else if(this.invoiceFieldsObj[headerId]){
        return this.invoiceFieldsObj[headerId].status;
      }
      else{
        return true;
      }
    },

    fn_getTransactionTitle: function(transactionListId){
      for(let i=0; i<transactionForms.length; i++){
          if(transactionForms[i].id == transactionListId){
              return transactionForms[i].title;
          }
      }
    },

    fn_generateQrCode: function(){
      let self = this;
      let url = "upi://pay?pa="+this.$root.CurrentBusiness.UpiID+"&pn="+this.$root.CurrentBusiness.UPIName;

      if(this.data.transactionDetails.onCredit){
        url = "upi://pay?pa="+this.$root.CurrentBusiness.UpiID+"&pn="+this.$root.CurrentBusiness.UPIName+"&am="+this.data.transactionDetails.onCredit;
      }
      QRCode.toDataURL(url, {margin:0})
        .then(url => {
          console.log(url);
          self.paymentQrCodeUrl = url;
        })
        .catch(err => {
          console.error(err)
      })
    },

    fn_showInvoiceForText: function(){
        if(this.$route.query && this.$route.query.invoiceFor && this.$route.query.invoiceFor!='Standard'){
            return this.$route.query.invoiceFor;
        }
        // else if(this.$parent.$parent.invoiceForText && this.$parent.$parent.invoiceForText!='Standard'){
        //     return this.$parent.$parent.invoiceForText;
        // }
        else{
            return false;
        }
    },

    // fn_getInvoiceType: function(){
    //  let invoiceTypeText = null;
    //  switch (this.$route.query.invoiceFor) {
    //    case 1:
    //        invoiceTypeText = "Original For Recipient";
    //          break;
    //      case 2:
    //        invoiceTypeText = "Duplicate For Transporter";
    //          break;
    //      case 3:
    //        invoiceTypeText = "Duplicate For Supplier";
    //          break;
    //      case 4:
    //        invoiceTypeText = "Triplicate For Supplier";
    //          break;
    //      default:
    //        invoiceTypeText = "Original For Recipient";
    //  }
    //  return invoiceTypeText;
    // },

    fn_getDeviceWidth: function(){
      return window.innerWidth;
    },

    fn_editPrintField(fieldId, type){
        this.editPrintField ? this.editPrintField(fieldId, type) : '';
    },

    // fn_editUpiDetails(){
    //   if(this.isEditable){
    //     this.$router.push({"path":"/settings/payment/upi/new", "query":{"edit":1}});
    //   }
    // },

    fn_editUpiDetails(){
        if(this.isEditable){
            // this.$router.push({"path":"/settings/payment/upi/new", "query":{"edit":1}});	
            this.$eventHub.$emit('upidetails');
            // this.$refs['ref_dialogPayment'].openDialogWeb();
        }
    },

    fn_editParty(type){
        if(this.editParty){
            if(this.data.transactionDetails.transactionListId != 20005 || (type != 'expense' && this.data.transactionDetails.partyDetails.partyName)){
            this.editParty();
            }
        }
      // this.editParty ? this.editParty() : '';
    },

    fn_editBusiness(){
        this.editBusiness ? this.editBusiness() : '';
    },

    fn_editLogo(){
        this.editLogo ? this.editLogo() : '';
    },

    fn_openToggleOptions(descriptionObj, itemDescriptionObj, brandObj, categoryObj, companyObj){
      if(!this.isEditable){
        return;
      }
      
      if(this.$root.appVersion < 109){
        this.$eventHub.$emit('UpdateMobileApp');
        return;
      }

      this.openToggleOptions ? this.openToggleOptions(descriptionObj, itemDescriptionObj, brandObj, categoryObj, companyObj) : '';
    },

    fn_init: function(templateNo, calculateZoomPercent, callback, fieldObjTemp){
      if(this.$route.name == "invoicePreviewSettings"){
        this.dataLoaded = true;
      }

      let configItems = null;

      if(localStorage.getItem("template_settings")){
        let dataObj = JSON.parse(localStorage.getItem("template_settings"));
        configItems = dataObj.configItems && dataObj.configItems.length ? dataObj.configItems : null;
      }
      else if(this.data && this.data.templateSettings && this.data.templateSettings.visibilityConfig){
        configItems = JSON.parse(this.data.templateSettings.visibilityConfig);
      }

      if(configItems && configItems.length){
        for(let i=0; i<configItems.length; i++){
          for(let [key, value] of Object.entries(this.invoiceFieldsObj)){
            if(key == configItems[i].fieldId){
              value.status = configItems[i].status;
              configItems[i].value ? value.value = configItems[i].value : '';
              break;
            }
          }
        }
      }

      if(fieldObjTemp && fieldObjTemp.length){
        for(let i=0; i<fieldObjTemp.length; i++){
          for(let [key, value] of Object.entries(this.invoiceFieldsObj)){
            if(key == fieldObjTemp[i].fieldId){
              value.status = fieldObjTemp[i].status;
              fieldObjTemp[i].value ? value.value = fieldObjTemp[i].value : '';
              break;
            }
          }
        }
      }

      this.fn_generateQrCode();
      if(this.data.transactionDetails.partyDetails.address){
        this.fn_processAddress();
      }
      this.fn_processFields();
      if(this.data.transactionDetails.transactionItems && this.data.transactionDetails.transactionItems.length){
        this.fn_processTransactionItems();
        this.fn_processSummary();
      }
      else{
        // this.headers = [{"name":"#"}, {"name":"Description"}, {"name":"Amount"}];
        this.headers = [{"name":"#", "id":"tableColSrNo"}, {"name":"Description", "id":"tableColDescription"}, {"name":"Amount", "id":"tableColAmount"}];
      }

      this.fn_processSections();
      this.fn_processKeywords();

      this.$root.fn_getLocalItem('invoiceGridClassValue', response=>{
        let data = JSON.parse(response);
        if(data && data.LocalValue){
          this.gridClassValue = data.LocalValue;
        }
      });

      this.$root.fn_getLocalItem('footerSectionStyle', response=>{
        let data = JSON.parse(response);
        if(data && data.LocalValue){
          this.footerSectionValue = data.LocalValue;
        }
      });

      console.log(templateNo);
      console.log(calculateZoomPercent);
      this.dataLoaded = true;

      // setting up signature image width and height
      // setTimeout(() => {
      //   if(this.signatory.data){
      //     let signatureImageElements = document.querySelectorAll(".bill_template .bottom-right-column .signatory img");
      //     if(signatureImageElements){
      //       let maxWidth = 120;
      //       let maxHeight = 48;
      //       let srcWidth = signatureImageElements[0].offsetWidth;
      //       let srcHeight = signatureImageElements[0].offsetHeight;
      //       let ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    
      //       let imgWidth = srcWidth * ratio;
      //       let imgHeight = srcHeight * ratio;
    
      //       signatureImageElements[0].style.width = imgWidth + "px";
      //       signatureImageElements[0].style.height = imgHeight + "px";

      //       if(signatureImageElements.length > 1){
      //         signatureImageElements[1].style.width = imgWidth + "px";
      //         signatureImageElements[1].style.height = imgHeight + "px";
      //       }
      //     }
          
      //   }
      // });

      // let self = this;
      // setTimeout(function(){ 
      //   self.fn_setTableHeaderWidth(templateNo, calculateZoomPercent);
      // },300)
      // this.fn_processPrintDetails();
    },
    fn_processAddress: function(){
      let addresses = this.data.transactionDetails.partyDetails.address;
      for(let i=0; i<addresses.length; i++){
        if(addresses[i].groupTypeId==101 && !this.billingAddress){
          let addressLine = addresses[i].addressLine ? addresses[i].addressLine + ', ' : '';
          let city = addresses[i].city ? addresses[i].city + ', ' : '';
          let state = addresses[i].state ? addresses[i].state + ', ' : '';
          let country = addresses[i].country ? addresses[i].country : '';
          let pincode = addresses[i].pincode ? ', ' + addresses[i].pincode : '';
          this.billingAddress = addressLine + city + state + country + pincode;
          this.partyPhoneBilling = addresses[i].phone ? addresses[i].phone : null;
        }
        else if(addresses[i].groupTypeId==102 && !this.shippingAddress){
          let addressLine = addresses[i].addressLine ? addresses[i].addressLine + ', ' : '';
          let city = addresses[i].city ? addresses[i].city + ', ' : '';
          let state = addresses[i].state ? addresses[i].state + ', ' : '';
          let country = addresses[i].country ? addresses[i].country : '';
          let pincode = addresses[i].pincode ? ', ' + addresses[i].pincode : '';
          this.shippingAddress = addressLine + city + state + country + pincode;
          this.partyPhoneShipping = addresses[i].phone ? addresses[i].phone : null;
        }
        else if(addresses[i].groupTypeId==103 && !this.billingAddress && !this.shippingAddress){
          let addressLine = addresses[i].addressLine ? addresses[i].addressLine + ', ' : '';
          let city = addresses[i].city ? addresses[i].city + ', ' : '';
          let state = addresses[i].state ? addresses[i].state + ', ' : '';
          let country = addresses[i].country ? addresses[i].country : '';
          let pincode = addresses[i].pincode ? ', ' + addresses[i].pincode : '';
          this.billingAddress = addressLine + city + state + country + pincode;
          this.shippingAddress = addressLine + city + state + country + pincode;
          this.partyPhoneBilling = addresses[i].phone ? addresses[i].phone : null;
          this.partyPhoneShipping = addresses[i].phone ? addresses[i].phone : null;
        }
      }
    },
    fn_processFields: function(){
      this.otherFields = [];
      
      // inserting ewayBillFields if applicable
      if(this.data.transactionDetails.roundedAmount>=50000 || this.$root.TransactionSettings.eWayBillConfigValue === 1){
        let ewayBillFields = JSON.parse(JSON.stringify(require('../../config.js').configs.transactionFields.ewayBillDetailFields))
        for(let k=0; k<ewayBillFields.length; k++){
          // this.data.templateSettings.printTemplateFields.push(ewayBillFields[k]);
          let fieldIndex = this.data.templateSettings.printTemplateFields.findIndex(item=>{
            return item.fieldId == ewayBillFields[k].fieldId;
          });

          if(fieldIndex == -1){
            this.data.templateSettings.printTemplateFields.push(ewayBillFields[k]);
          }
        }
      }

      // matching applicable fields
      for(let i=0; i<this.data.templateSettings.printTemplateFields.length; i++){

        let printField = this.data.templateSettings.printTemplateFields[i];
        printField.fieldValue = null;

        if(printField.fieldType=='print'){
          printField.additionalData = JSON.parse(printField.additionalData);
        }

        for(let j=0; j<this.data.transactionDetails.otherDetails.transactionFields.length; j++){

          let transactionField = this.data.transactionDetails.otherDetails.transactionFields[j];
          if(printField.fieldId == transactionField.fieldId){
            printField.fieldValue = transactionField.fieldValue;
            this.otherFields.push(printField);
            break;
          } 
        }
      }

      console.log(JSON.stringify(this.otherFields));
    },

    // fn_processFields: function(){
    //  this.otherFields = [];
    //  for(let i=0; i<this.data.transactionDetails.otherDetails.transactionSections.length; i++){
    //    let sectionObj = {};
    //    sectionObj.sectionId = this.data.transactionDetails.otherDetails.transactionSections[i].sectionId;
    //    sectionObj.sectionName = this.data.transactionDetails.otherDetails.transactionSections[i].sectionName;
    //    sectionObj.fields = [];
    //    for(let k=0; k<this.data.transactionDetails.otherDetails.transactionFields.length; k++){

    //      for(let j=0; j<this.data.templateSettings.printTemplateFields.length; j++){

    //        let field = this.data.transactionDetails.otherDetails.transactionFields[k];
    //        if(sectionObj.sectionId == field.sectionId && this.data.templateSettings.printTemplateFields[j].printTemplateFieldId == field.fieldId){
    //          let fieldObj = {};
    //          fieldObj.fieldName = field.fieldName;
    //          fieldObj.fieldValue = field.fieldValue;
    //          sectionObj.fields.push(fieldObj);
    //          break;
    //        }

    //      }
          
    //    }
        

    //    this.otherFields.push(sectionObj);
    //  }

    //  console.log(JSON.stringify(this.otherFields));
    // },
    fn_processTransactionItems: function(){
      // this.headers = [{"name":"#"}, {"name":"Description"}, {"name":"HSN/SAC"}, {"name":"Qty"}, {"name":"Unit"}, {"name":"MRP"}, {"name":"Rate/Unit"}]
      this.headers = [{"name":"#", "id":"tableColSrNo"}, {"name":"Description", "id":"tableColDescription"}, {"name":"HSN/SAC", "id":"tableColHsn"}, {"name":"Qty", "id":"tableColQty"}, {"name":"Unit", "id":"tableColUnit"}, {"name":"MRP", "id":"tableColMrp"}, {"name":"Rate/Unit", "id":"tableColRate"}]
      this.items = [];
      this.isHsn = false;
      this.isMrp = false;
      let qtyTotal = 0;
      let unitId = null;
      let isSimilarUnit = true;

      // let hasSeries = false;
      let hasBatch = false;
      // let isColsPushed = false;
      let hasBatchNumber = false;
      let hasBatchSize = false;
      let hasMfgDate = false;
      let hasExpDate = false;
      // let hasPurchaseRate = false;

      // let batchNumberPushed = false;
      // let batchSizePushed = false;
      // let mfgDatePushed = false;
      // let expDatePushed = false;
      // let purchaseRatePushed = false;

      // running loop on items to know batch fields
      for(let i=0; i<this.data.transactionDetails.transactionItems.length; i++){
        let item = this.data.transactionDetails.transactionItems[i].cols;
        item.hasBatch = false;
        item.hasSeries = false;
        if(item.productBatches && item.productBatches.length){
          // item.hasBatch = true;
          // let itemObj = {};
          // itemObj.batches = [];
          // itemObj.field1 = [];
          // itemObj.field2 = [];
          // itemObj.field3 = [];
          for(let i=0; i<item.productBatches.length; i++){
            let field = item.productBatches[i];
            // if(field.field1 || field.field2 || field.field3){
            // 	item.hasSeries = true;
            // 	field.field1 ? itemObj.field1.push(field.field1) : '';
            // 	field.field2 ? itemObj.field2.push(field.field2) : '';
            // 	field.field3 ? itemObj.field3.push(field.field3) : '';
            // }
    
            if(field.batchNumber || field.batchMRP || field.purchaseRate || field.batchSize || field.mfgDate || field.expDate){
              hasBatch = true;
              item.hasBatch = true;
              field.batchNumber ? hasBatchNumber = true : '';
              field.batchSize ? hasBatchSize = true : '';
              field.mfgDate ? hasMfgDate = true : '';
              field.expDate ? hasExpDate = true : '';
            }
          }

          // if(hasBatch){
          // 	let insertIndex = 4;
            
          // 	if(hasBatchNumber && !batchNumberPushed){
          // 		this.headers.splice(insertIndex, 0, {"name":"#Batch", "id":"batchNumber"});
          // 		insertIndex++;
          // 		batchNumberPushed = true;
          // 	}

          // 	if(hasBatchSize && !batchSizePushed){
          // 		this.headers.splice(insertIndex, 0, {"name":"Size", "id":"batchSize"});
          // 		insertIndex++;
          // 		batchSizePushed = true;
          // 	}

          // 	if(hasMfgDate && !mfgDatePushed){
          // 		this.headers.splice(insertIndex, 0, {"name":"Mfg Date", "id":"mfgDate"});
          // 		insertIndex++;
          // 		mfgDatePushed = true;
          // 	}

          // 	if(hasExpDate && !expDatePushed){
          // 		this.headers.splice(insertIndex, 0, {"name":"Exp Date", "id":"expDate"});
          // 		insertIndex++;
          // 		expDatePushed = true;
          // 	}
          // }
        }
      }

      if(hasBatch){
        let insertIndex = 4;
        
        if(hasBatchNumber){
          this.headers.splice(insertIndex, 0, {"name":"#Batch", "id":"batchNumber"});
          insertIndex++;
        }

        if(hasBatchSize){
          this.headers.splice(insertIndex, 0, {"name":"Size", "id":"batchSize"});
          insertIndex++;
        }

        if(hasMfgDate){
          this.headers.splice(insertIndex, 0, {"name":"Mfg Date", "id":"mfgDate"});
          insertIndex++;
        }

        if(hasExpDate){
          this.headers.splice(insertIndex, 0, {"name":"Exp Date", "id":"expDate"});
          insertIndex++;
        }
      }

      for(let i=0; i<this.data.transactionDetails.transactionItems.length; i++){
        let item = this.data.transactionDetails.transactionItems[i].cols;
        let itemObj = {};
        itemObj.itemName = item.itemName;
        itemObj.hsn = item.hsn;
        itemObj.qty = item.qty;
        itemObj.unit = item.unitName;
        
        if(i>0 && unitId != item.unitId){
          isSimilarUnit = false;
        }

        unitId = item.unitId;
        qtyTotal += item.qty;
        itemObj.mrp = item.mrp;
        itemObj.rate = item.rate;
        itemObj.addOns = [];
        itemObj.gstAddOns = [];
        itemObj.description = this.data.transactionDetails.transactionItems[i].description;
        itemObj.itemDescription = this.data.transactionDetails.transactionItems[i].itemDescription;
        itemObj.baseTransactionNumber = this.data.transactionDetails.transactionItems[i].baseTransactionNumber;

        itemObj.brand = this.data.transactionDetails.transactionItems[i].brandName;
        itemObj.company = this.data.transactionDetails.transactionItems[i].companyName;
        itemObj.category = this.data.transactionDetails.transactionItems[i].categoryName;

        for(let j=0; j<item.addOns.length; j++){

          !item.rate ? item.addOns[j].value = 0 : '';
          !item.rate ? item.addOns[j].percentage = 0 : '';

          if(!item.addOns[j].isGst){
            itemObj.addOns.push(item.addOns[j]);

            let headerIndex = this.headers.findIndex(header=>{
              return header.addOnsId == item.addOns[j].addOnsId;
            })

            if(headerIndex==-1){
              let obj = {};
              obj.addOnsId = item.addOns[j].addOnsId;
              obj.name = item.addOns[j].name;
              this.headers.push(obj);
            }
          }
          else{
            itemObj.gstAddOns.push(item.addOns[j]);
          }
          
        }

        if(!item.rate){
          item.taxableValue = 0;
          item.total = 0;
        }

        itemObj.taxableValue = item.taxableValue;
        itemObj.total = item.netAmount;

        if(item.taxableValue){
          this.hasTaxableColumn = true;
        }

        itemObj.orderNumber = item.orderNumber;

        if(item.productBatches && item.productBatches.length){
          itemObj.field1 = [];
          itemObj.field2 = [];
          itemObj.field3 = [];
          // item.hasBatch = true;
          // itemObj.batches = [];
          // itemObj.field1 = [];
          // itemObj.field2 = [];
          // itemObj.field3 = [];
          // for(let i=0; i<item.productBatches.length; i++){
          // 	let field = item.productBatches[i];
          // 	if(field.field1 || field.field2 || field.field3){
          // 		item.hasSeries = true;
          // 		field.field1 ? itemObj.field1.push(field.field1) : '';
          // 		field.field2 ? itemObj.field2.push(field.field2) : '';
          // 		field.field3 ? itemObj.field3.push(field.field3) : '';
          // 	}
    
          // 	if(field.batchNumber || field.batchMRP || field.purchaseRate || field.batchSize || field.mfgDate || field.expDate || field.field4){
          // 		hasBatch = true;
          // 		field.batchNumber ? hasBatchNumber = true : '';
          // 		field.batchSize ? hasBatchSize = true : '';
          // 		field.mfgDate ? hasMfgDate = true : '';
          // 		field.expDate ? hasExpDate = true : '';
          // 	}
          // }

          // if(hasBatch){
          // 	let insertIndex = 4;
            
          // 	if(hasBatchNumber && !batchNumberPushed){
          // 		this.headers.splice(insertIndex, 0, {"name":"#Batch", "id":"batchNumber"});
          // 		insertIndex++;
          // 		batchNumberPushed = true;
          // 	}

          // 	if(hasBatchSize && !batchSizePushed){
          // 		this.headers.splice(insertIndex, 0, {"name":"Size", "id":"batchSize"});
          // 		insertIndex++;
          // 		batchSizePushed = true;
          // 	}

          // 	if(hasMfgDate && !mfgDatePushed){
          // 		this.headers.splice(insertIndex, 0, {"name":"Mfg Date", "id":"mfgDate"});
          // 		insertIndex++;
          // 		mfgDatePushed = true;
          // 	}

          // 	if(hasExpDate && !expDatePushed){
          // 		this.headers.splice(insertIndex, 0, {"name":"Exp Date", "id":"expDate"});
          // 		insertIndex++;
          // 		expDatePushed = true;
          // 	}
          // }

          itemObj.batchLength = item.productBatches && item.productBatches.length ? item.productBatches.length : 0;
          let itemWiseTaxableValue = itemObj.taxableValue;
          let itemWiseAddOnsValue = JSON.parse(JSON.stringify(itemObj.addOns));
          let itemWiseGstAddOnsValue = JSON.parse(JSON.stringify(itemObj.gstAddOns));
          let itemWiseTotal = itemObj.total;

          for(let i=0; i<item.productBatches.length; i++){

            let field = item.productBatches[i];
            if(field.field1 || field.field2 || field.field3){
              item.hasSeries = true;
              field.field1 ? itemObj.field1.push(field.field1) : '';
              field.field2 ? itemObj.field2.push(field.field2) : '';
              field.field3 ? itemObj.field3.push(field.field3) : '';
            }

            itemObj.field4 = [];
            if(field.field4){
              itemObj.field4.push(field.field4);
            }

            if(hasBatch){
              
              if(item.productBatches[i].qty && !item.hasSeries){
                itemObj.qty = item.productBatches[i].qty;
              }

              if(item.productBatches[i].batchNumber || hasBatchNumber){
                itemObj.batchNumber = item.productBatches[i].batchNumber ? item.productBatches[i].batchNumber : '';
              }

              if(item.productBatches[i].batchSize || hasBatchSize){
                itemObj.batchSize = item.productBatches[i].batchSize ? item.productBatches[i].batchSize : '';
                hasBatchSize = true;
              }

              if(item.productBatches[i].batchMRP){
                itemObj.mrp = item.productBatches[i].batchMRP;
              }

              if(item.productBatches[i].mfgDate || hasMfgDate){
                itemObj.mfgDate = item.productBatches[i].mfgDate ? this.$moment(new Date(item.productBatches[i].mfgDate)).format('DD-MMM-YYYY') : '';
              }

              if(item.productBatches[i].expDate || hasExpDate){
                itemObj.expDate = item.productBatches[i].expDate ? this.$moment(new Date(item.productBatches[i].expDate)).format('DD-MMM-YYYY') : '';
              }

              // if(item.productBatches[i].purchaseRate || hasPurchaseRate){
              // 	itemObj.purchaseRate = item.productBatches[i].purchaseRate ? item.productBatches[i].purchaseRate : '';
              // }
            }

            if(itemObj.batchLength > 1){
              itemObj.taxableValue = (itemWiseTaxableValue / item.qty) * itemObj.qty;
              
              for(let i=0; i<itemWiseAddOnsValue.length; i++){
                itemObj.addOns[i].value =  (itemWiseAddOnsValue[i].value / item.qty) * itemObj.qty
              }

              for(let i=0; i<itemWiseGstAddOnsValue.length; i++){
                itemObj.gstAddOns[i].value =  (itemWiseGstAddOnsValue[i].value / item.qty) * itemObj.qty
              }

              itemObj.total = (itemWiseTotal / item.qty) * itemObj.qty;
            }

            item.hasBatch ? this.items.push(JSON.parse(JSON.stringify(itemObj))) : '';
          }
        }


        if(!item.hasBatch || item.hasSeries){
          if(hasBatch){
              
            // if(item.productBatches[i].qty){
            // 	itemObj.qty = item.productBatches[i].qty;
            // }

            if(hasBatchNumber){
              itemObj.batchNumber = '';
            }

            if(hasBatchSize){
              itemObj.batchSize = '';
              hasBatchSize = true;
            }

            // if(item.productBatches[i].batchMRP){
            // 	itemObj.mrp = item.productBatches[i].batchMRP;
            // }

            if(hasMfgDate){
              itemObj.mfgDate = '';
            }

            if(hasExpDate){
              itemObj.expDate = '';
            }
          }

          this.items.push(itemObj)
        }
        
        // this.items.push(itemObj);

        item.hsn && this.$root.CurrentBusiness.GSTRegistrationStatus == 1 ? this.isHsn = true : '';
        // item.mrp>=0 && this.$root.TransactionSettings.mrpWiseStock ? this.isMrp = true : '';
        item.mrp && this.$root.TransactionSettings.mrpWiseStock ? this.isMrp = true : '';

        if(itemObj.gstAddOns && itemObj.gstAddOns.length && this.$root.CurrentBusiness.GSTRegistrationStatus == 1){
          let obj = {};
          obj.hsn = item.hsn ? item.hsn : '';
          obj.taxableValue = itemObj.taxableValue;
          obj.gstAddOns =  JSON.parse(JSON.stringify(itemObj.gstAddOns));
          obj.totalTax = 0;
          for(let j=0; j<obj.gstAddOns.length; j++){
            obj.totalTax = obj.totalTax + obj.gstAddOns[j].value;

            let headerIndex = this.hsnSummary.headers.findIndex(header=>{
              return header.id === obj.gstAddOns[j].addOnsId;
            })

            if(headerIndex==-1){
              let hobj = {};
              hobj.id = obj.gstAddOns[j].addOnsId;
              hobj.name = obj.gstAddOns[j].name;
              this.hsnSummary.headers.push(hobj);
            }
          }

          this.hsnSummary.items.push(obj);
        }
      }

      let summary = [];
      if(this.hsnSummary.items.length){
        this.hsnSummary.headers.push({"id":"totalTax", "name":"Total Tax"});

        let cessColIndex = this.hsnSummary.headers.findIndex(head=>head.name == 'CESS');
        
        this.hsnSummary.items.forEach((item)=>{
          if(cessColIndex != -1){
            let cessIndex = item.gstAddOns.findIndex(gstitem=>gstitem.name == "CESS");
            if(cessIndex==-1){
              let cessObj = {};
              cessObj.name = "CESS";
              cessObj.value = 0;
              item.gstAddOns.push(cessObj);
            }
          }

          
          let itemGst = [];
          item.gstAddOns.forEach((addOn)=>{
            let obj = {};
            obj.name = addOn.name;
            obj.percentage = addOn.percentage;
            itemGst.push(obj);
          })

          let indx = -1;
          for(let a=0; a<summary.length; a++){
            let summaryGst = [];
            summary[a].gstAddOns.forEach((addOn)=>{
              let obj = {};
              obj.name = addOn.name;
              obj.percentage = addOn.percentage;
              summaryGst.push(obj);
            });
            if(summary[a].hsn == item.hsn && JSON.stringify(summaryGst) == JSON.stringify(itemGst)){
              indx = a;
              break;
            }
          }

          if(indx != -1){
            summary[indx].taxableValue = summary[indx].taxableValue + item.taxableValue;

            summary[indx].gstAddOns[0].value = summary[indx].gstAddOns[0].value + item.gstAddOns[0].value;
            if(item.gstAddOns.length > 1){
              summary[indx].gstAddOns[1].value = summary[indx].gstAddOns[1].value + item.gstAddOns[1].value;
            }

            if(item.gstAddOns.length > 2){
              summary[indx].gstAddOns[2].value = summary[indx].gstAddOns[2].value + item.gstAddOns[2].value;
            }

            summary[indx].totalTax = summary[indx].totalTax + item.totalTax;
          }
          else{
            summary.push(item);
          }
        })
      }

      if(summary.length){
        // footer calculation
        let totalTaxableValue = 0;
        let taxCol1 = 0;
        let taxCol2 = 0;
        let taxCol3 = 0;
        let totalTax = 0;
        let taxColCount = null;
        for(let i=0; i<summary.length; i++){
          totalTaxableValue = totalTaxableValue + summary[i].taxableValue;
          taxCol1 = taxCol1 + summary[i].gstAddOns[0].value;

          if(summary[i].gstAddOns.length > 1){
            taxCol2 = taxCol2 + summary[i].gstAddOns[1].value;
            taxColCount = 2;
          }

          if(summary[i].gstAddOns.length > 2){
            taxCol3 = taxCol3 + summary[i].gstAddOns[2].value;
            taxColCount = 3;
          }

          totalTax = totalTax + summary[i].totalTax;
        }

        this.hsnSummary.items = JSON.parse(JSON.stringify(summary));

        if(taxColCount==2){
          this.hsnSummary.footers = [totalTaxableValue, taxCol1, taxCol2, totalTax];
        }
        else if(taxColCount==3){
          this.hsnSummary.footers = [totalTaxableValue, taxCol1, taxCol2, taxCol3, totalTax];
        }
        else{
          this.hsnSummary.footers = [totalTaxableValue, taxCol1, totalTax];
        }
      }

      console.log(JSON.stringify(summary));

      // sorting items as per order number
      this.items.sort(function(a, b){
          return a.orderNumber - b.orderNumber;
      });

      // this.qtyTotal = isSimilarUnit ? qtyTotal : null;
      if(isSimilarUnit){
        if(qtyTotal.toString().indexOf('.') == -1){
          this.qtyTotal = Number(qtyTotal);
        }
        else{
          this.qtyTotal = Number(qtyTotal.toFixed(3));
        }
      }
      else{
        this.qtyTotal = null;
      }

      // this.headers.push({"name":"Taxable Value"});
      this.headers.push({"name":"Taxable Value", "id":"tableColTaxableValue"});

      for(let l=0; l<this.items.length; l++){
        for(let k=0; k<this.items[l].gstAddOns.length; k++){
          let headerIndex = this.headers.findIndex(header=>{
            return header.gstAddOnsId == this.items[l].gstAddOns[k].addOnsId;
          })

          if(headerIndex==-1){
            let obj = {};
            obj.gstAddOnsId = this.items[l].gstAddOns[k].addOnsId;
            obj.name = this.items[l].gstAddOns[k].name;
            this.headers.push(obj);
          }
        }

        if(!this.items[l].gstAddOns.length){
          this.hasNilRatedItems = true;
        }
      }

      //removing hsn column from invoice if not mentioned in any invoice
      if(!this.isHsn){
        let hsnHeaderIndex = this.headers.findIndex(head=>{return head.id == 'tableColHsn'});
        this.headers.splice(hsnHeaderIndex, 1);
        // this.headers.splice(2, 1);
        for(let i=0; i<this.items.length; i++){
          delete this.items[i].hsn;
        }
      }

      //removing mrp column from invoice if not mentioned in any invoice
      if(!this.isMrp){
        // let mrpHeaderIndex = this.isHsn ? 4 : 3;
        let mrpHeaderIndex = this.headers.findIndex(head=>{return head.id == 'tableColMrp'});
        // let mrpHeaderIndex = this.isHsn ? 5 : 4;
        this.headers.splice(mrpHeaderIndex, 1);
        for(let i=0; i<this.items.length; i++){
          delete this.items[i].mrp;
        }
      }

      // this.headers.push({"name":"Taxable Value"});
      // this.headers.push({"name":"Total"});
      this.headers.push({"name":"Total", "id":"tableColTotal"});

      this.headersLength ? this.headersLength(this.headers.length) : '';

      console.log(JSON.stringify(this.headers));
      console.log(JSON.stringify(this.items));
    },
    fn_processSummary: function(){
      let totalTax = 0;
      let netTotal = 0;

      this.addOnsTotal = [];
      this.gstAddOnsTotal = [];
      
      for(let i=0; i<this.items.length; i++){

        netTotal += this.items[i].total;

        for(let j=0; j<this.items[i].addOns.length; j++){

          let addOnsIndex = this.addOnsTotal.findIndex(addOn=>{
            return addOn.addOnsId == this.items[i].addOns[j].addOnsId;
          });

          if(addOnsIndex==-1){
            let obj = {};
            obj.addOnsId = this.items[i].addOns[j].addOnsId;
            obj.name = this.items[i].addOns[j].name;
            obj.value = this.items[i].addOns[j].value;
            this.addOnsTotal.push(obj);
          }
          else{
            this.addOnsTotal[addOnsIndex].value += this.items[i].addOns[j].value;
          }
        }

        for(let k=0; k<this.items[i].gstAddOns.length; k++){

          let addOnsIndex = this.gstAddOnsTotal.findIndex(addOn=>{
            return addOn.gstAddOnsId == this.items[i].gstAddOns[k].addOnsId;
          });

          if(addOnsIndex==-1){
            let obj = {};
            obj.gstAddOnsId = this.items[i].gstAddOns[k].addOnsId;
            obj.name = this.items[i].gstAddOns[k].name;
            obj.value = this.items[i].gstAddOns[k].value;
            this.gstAddOnsTotal.push(obj);
          }
          else{
            this.gstAddOnsTotal[addOnsIndex].value += this.items[i].gstAddOns[k].value;
          }

          totalTax += this.items[i].gstAddOns[k].value;
        }
      }

      this.summary.totalTax = this.roundUpto2Decimals(totalTax);
      this.netTotal = this.roundUpto2Decimals(netTotal);

      if(!this.summary.totalTax){
        let itemIndex = this.headers.findIndex(item=>{return item.name == "Taxable Value"});
        let taxCol = false;
        for(let i=0; i<this.headers.length; i++){
          if(this.headers[i].name == "CGST" || this.headers[i].name == "SGST" || this.headers[i].name == "IGST" || this.headers[i].name == "UTGST" || this.headers[i].name == "CESS"){
            taxCol = true;
          }
        }
        if(!taxCol && itemIndex != -1){
          this.headers.splice(itemIndex, 1);
        }
      }

      console.log(JSON.stringify(this.addOnsTotal));
    },
    
    fn_processSections: function(){
      for(let i=0; i<this.data.templateSettings.printTemplateSections.length; i++){
        let additionalData = this.data.templateSettings.printTemplateSections[i].addData;
        if(this.data.templateSettings.printTemplateSections[i].sectionName == 'header'){
          this.header.data = JSON.parse(additionalData);
          this.header.fieldId = this.data.templateSettings.printTemplateSections[i].fieldId;
          this.header.showInPrintOut = this.data.templateSettings.printTemplateSections[i].status === null ? true : this.data.templateSettings.printTemplateSections[i].status;
        }
        else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'terms'){
          this.terms.data = JSON.parse(additionalData);
          this.terms.fieldId = this.data.templateSettings.printTemplateSections[i].fieldId;
          this.terms.showInPrintOut = this.data.templateSettings.printTemplateSections[i].status === null ? true : this.data.templateSettings.printTemplateSections[i].status;
        }
        else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'printNote'){
            this.printNote.data = JSON.parse(additionalData);
            this.printNote.fieldId = this.data.templateSettings.printTemplateSections[i].fieldId;
            this.printNote.showInPrintOut = this.data.templateSettings.printTemplateSections[i].status === null ? true : this.data.templateSettings.printTemplateSections[i].status;
        }
        else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'bankDetails'){
          this.bankDetails.data = JSON.parse(additionalData);
          this.bankDetails.fieldId = this.data.templateSettings.printTemplateSections[i].fieldId;
          this.bankDetails.showInPrintOut = this.data.templateSettings.printTemplateSections[i].status === null ? true : this.data.templateSettings.printTemplateSections[i].status;
        }
        else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'signature'){

          // if(additionalData){
          //  this.signatory.data = additionalData.indexOf("img") != -1 ?  JSON.parse(additionalData) : null; 
          // }
          // else{
          //  this.signatory.data = null;
          // }
          this.signatory.data = additionalData ?  JSON.parse(additionalData) : null;
          this.signatory.fieldId = this.data.templateSettings.printTemplateSections[i].fieldId;
          this.signatory.showInPrintOut = this.data.templateSettings.printTemplateSections[i].status === null ? true : this.data.templateSettings.printTemplateSections[i].status;
        }
        else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'subtitle'){
          this.subtitle.data = JSON.parse(additionalData);
          this.subtitle.fieldId = this.data.templateSettings.printTemplateSections[i].fieldId;
          this.subtitle.showInPrintOut = this.data.templateSettings.printTemplateSections[i].status === null ? true : this.data.templateSettings.printTemplateSections[i].status;
        }
        else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'billingLabel'){
          this.billingLabel.data = JSON.parse(additionalData);
          this.billingLabel.fieldId = this.data.templateSettings.printTemplateSections[i].fieldId;
          this.billingLabel.showInPrintOut = this.data.templateSettings.printTemplateSections[i].status === null ? true : this.data.templateSettings.printTemplateSections[i].status;
        }
        else if(this.data.templateSettings.printTemplateSections[i].sectionName == 'shippingLabel'){
          this.shippingLabel.data = JSON.parse(additionalData);
          this.shippingLabel.fieldId = this.data.templateSettings.printTemplateSections[i].fieldId;
          this.shippingLabel.showInPrintOut = this.data.templateSettings.printTemplateSections[i].status === null ? true : this.data.templateSettings.printTemplateSections[i].status;
        }
      }

      if(this.data.templateSettings.printFieldLogo){
        this.logoField.fieldId = this.data.templateSettings.printFieldLogo.fieldId;
        this.logoField.showInPrintOut = this.data.templateSettings.printFieldLogo.status;
      }

      if(this.data.templateSettings.printFieldUpiDetail){
        this.upiDetailField.fieldId = this.data.templateSettings.printFieldUpiDetail.fieldId;
        this.upiDetailField.showInPrintOut = this.data.templateSettings.printFieldUpiDetail.status;
      }

      this.$root.fn_getLocalData('bizopslogo_visibility', response=>{
        let val = JSON.parse(response);
        this.brandLogo.showInPrintOut = val === null ? true : val;
      })

      this.$root.fn_getLocalData('item_description', response=>{
        let val = JSON.parse(response);
            this.description.showInPrintOut = val === null ? true : val;
      })

      this.$root.fn_getLocalData('item_itemDescription', response=>{
        let val = JSON.parse(response);
            this.itemDescription.showInPrintOut = val === null ? true : val;
      })

      this.$root.fn_getLocalData('item_category', response=>{
        let val = JSON.parse(response);
            this.category.showInPrintOut = val === null ? false : val;
      })

      this.$root.fn_getLocalData('item_brand', response=>{
        let val = JSON.parse(response);
            this.brand.showInPrintOut = val === null ? false : val;
      })

      this.$root.fn_getLocalData('item_company', response=>{
        let val = JSON.parse(response);
            this.company.showInPrintOut = val === null ? false : val;
      })

      if(this.data.templateSettings.visibilityConfig){
        let visibilityConfig = JSON.parse(this.data.templateSettings.visibilityConfig);
        let hsnSummary = visibilityConfig.filter(config=>{return config.fieldId == 'hsnSummary'});
        if(hsnSummary.length && hsnSummary[0].status){
          this.hasHsnSummary = true;
        }

        let bottomNote = visibilityConfig.filter(config=>{return config.fieldId == 'bottomNote'});
        if(bottomNote.length && bottomNote[0].status){
          this.hasBottomNote = true;
          this.bottomNoteValue = bottomNote[0].value;
        }
      }
    },
    fn_processKeywords: function(){
      this.fn_replaceKeywords(this.header.data);
      this.fn_replaceKeywords(this.terms.data);
      this.fn_replaceKeywords(this.printNote.data);
      this.fn_replaceKeywords(this.bankDetails.data);
      this.fn_replaceKeywords(this.signatory.data);
      this.fn_replaceKeywords(this.subtitle.data);
    },
    fn_replaceKeywords: function(category){
      if(category && category.content){
        category.content = category.content.replaceAll('{{BusinessName}}', this.$root.CurrentBusiness.BusinessName);
        category.content = category.content.replaceAll('{{BusinessAddress}}', this.$root.CurrentBusiness.Address ? this.$root.CurrentBusiness.Address : '');
        category.content = category.content.replaceAll('{{BusinessState}}', this.$root.CurrentBusiness.State ? this.$root.CurrentBusiness.State : '');
        category.content = category.content.replaceAll('{{GSTIN}}', this.$root.CurrentBusiness.GSTNumber ? this.$root.CurrentBusiness.GSTNumber : '');

        if(this.$root.CurrentBusiness.GSTRegistrationStatus == 3){
            category.content = category.content.replaceAll(/GSTIN: /g, '');
        }
      }
    },

    fn_showSection: function(showInPrintOut){
      if(this.isEditable){
        return true;
      }
      else{
        if(showInPrintOut){
          return true;
        }
        else{
          return false;
        }
      }
    },

    fn_previousSiblingHeight: function(){
        setTimeout(() => {
            let bottomLeftSection = document.getElementsByClassName("bottom-left-section")[0];
            let bottomRightSection = document.getElementsByClassName("bottom-right-section")[0];
            if(bottomLeftSection.offsetHeight > bottomRightSection.offsetHeight){
                return bottomLeftSection.offsetHeight+'px';
            }
            else{
                return 'auto'
            }
        }, 500);
    },

    fn_toggleField: function(val, fieldId){
      var self = this;
      let dataObj = {};
      dataObj.status = val;
      dataObj.fieldId = fieldId;
      this.postData('TogglePrintFieldVisibility', dataObj, 
        {
          onSuccess: function(response){
                  console.log(response);
                  self.fn_updateFieldStatus(val, fieldId);
              },
              onError: function(error){
                  console.log(error);
              }
        }
          );
    },

    fn_toggleBrandLogo: function(val, fromPreview){
      let dataObj = {};
      dataObj.LocalKey = 'bizopslogo_visibility';
      dataObj.LocalValue = val;
      this.$root.fn_saveLocalData(dataObj);

      fromPreview ? this.brandLogo.showInPrintOut = val : '';
    },

    fn_updateFieldStatus: function(val, fieldId){
      if(this.logoField.fieldId == fieldId){
        this.data.templateSettings.printFieldLogo.status = val;
      }
      else if(this.upiDetailField.fieldId == fieldId){
        this.data.templateSettings.printFieldUpiDetail.status = val;
      }
      else{
        for(let i=0; i<this.data.templateSettings.printTemplateSections.length; i++){
          if(this.data.templateSettings.printTemplateSections[i].fieldId == fieldId){
            this.data.templateSettings.printTemplateSections[i].status = val;
            break;
          }
        } 
      }
    },

    fn_getPlaceOfSupply: function(){
      if(this.data){
        if(this.data.transactionDetails && this.data.transactionDetails.partyDetails && this.data.transactionDetails.partyDetails.stateCode && this.$root.CurrentBusiness.GSTRegistrationStatus != 3){
          return this.data.transactionDetails.partyDetails.placeOfSupply + ' - ' + this.data.transactionDetails.partyDetails.stateCode;
        }
        else{
          return this.data.transactionDetails.partyDetails.placeOfSupply;
        }
      }
      else{
        return '';
      }
    },

    fn_setTableHeaderWidth: function(templateNo, calculateZoomPercent){

      // getting body table width as per template by using template no.
      let bodyTableElement = document.getElementById("body_template_"+templateNo);
      let headerTableElement = document.getElementById("header_template_"+templateNo);
      let waterMarkElement = document.getElementById("page_footer_template_"+templateNo);
      
      if(headerTableElement && bodyTableElement){
        headerTableElement.style.minWidth = bodyTableElement.offsetWidth+'px';  
      }

      if(waterMarkElement && bodyTableElement){
        waterMarkElement.style.minWidth = bodyTableElement.offsetWidth+'px';
      }

      if(this.$route.name != "invoicePreviewSettings"){
        this.dataLoaded = true;
      }

      console.log(calculateZoomPercent);

      this.dataLoaded = true;

      // let self = this;
      // if(calculateZoomPercent){
      //   setTimeout(function(){
      //     let header = document.getElementById("header_template_"+templateNo);
      //     if(header){
      //       calculateZoomPercent(header.style.minWidth);  
      //     }
      //   },300);
      // }
      
    },
    // fn_processPrintDetails: function(){
    //  this.printDetails = JSON.parse(this.data.transactionDetails.partyDetails.printDetails);
    // },
    convertNumberToWords: function(amount) {
      // custom addon for paise
          let paise = null;
          let stramount = amount.toString();
          if(stramount.indexOf('.')!=-1){
            paise = stramount.substring(stramount.indexOf('.') + 1);  
          }
          // end of custom addon

        var words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';
        amount = amount.toString();
        var atemp = amount.split(".");
        var number = atemp[0].split(",").join("");
        var n_length = number.length;
        var words_string = "";
        if (n_length <= 9) {
            var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
            var received_n_array = new Array();
            for (var i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (var k = 9 - n_length, j = 0; k < 9; k++, j++) {
                n_array[k] = received_n_array[j];
            }
            for (var l = 0, m = 1; l < 9; l++, m++) {
                if (l == 0 || l == 2 || l == 4 || l == 7) {
                    if (n_array[l] == 1) {
                        n_array[m] = 10 + parseInt(n_array[m]);
                        n_array[l] = 0;
                    }
                }
            }
            var value = "";
            for (var n = 0; n < 9; n++) {
                if (n == 0 || n == 2 || n == 4 || n == 7) {
                    value = n_array[n] * 10;
                } else {
                    value = n_array[n];
                }
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if ((n == 1 && value != 0) || (n == 0 && value != 0 && n_array[n + 1] == 0)) {
                    words_string += "Crores ";
                }
                if ((n == 3 && value != 0) || (n == 2 && value != 0 && n_array[n + 1] == 0)) {
                    words_string += "Lakhs ";
                }
                if ((n == 5 && value != 0) || (n == 4 && value != 0 && n_array[n + 1] == 0)) {
                    words_string += "Thousand ";
                }
                if (n == 6 && value != 0 && (n_array[n + 1] != 0 && n_array[n + 2] != 0)) {
                    words_string += "Hundred and ";
                } else if (n == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");
        }

        if(!paise){
              return words_string;  
          }
          else{
              if(paise.length==1){
                paise = paise + "0";
              }
              
              if(words_string){
                return words_string + 'Rupees and ' + this.convertNumberToWords(paise) + 'Paise';  
              }
              else{
                return '';
              }
          }
        
    },

    fn_footerColspanSize: function(){
      let colSpanSize = 0;
      if(this.filteredItems && this.filteredItems.length){
        // let mrpColSpanSize = 0;
        // if(this.isMrp){
        // 	mrpColSpanSize = this.gstAddOnsTotal.length ? 5 : 4;
        // }
        // else{
        // 	mrpColSpanSize = this.gstAddOnsTotal.length ? 6 : 5;
        // }
        let mrpColSpanSize = this.gstAddOnsTotal.length ? 5 : 4;
        let hsnColpanSize = this.isHsn ? 1 : 0;
        colSpanSize = this.headers.length - this.addOnsTotal.length - this.gstAddOnsTotal.length - mrpColSpanSize - hsnColpanSize;

        if(this.netTotal == 0 && this.gstAddOnsTotal.length){
          colSpanSize = colSpanSize + 1;
        }

        if(!this.isEditable){
          if(!this.invoiceFieldsObj.tableColRate.status){
            colSpanSize = colSpanSize - 1;
          }
      
          if(!this.invoiceFieldsObj.tableColUnit.status){
            colSpanSize = colSpanSize - 1;
          }
      
          if(this.invoiceFieldsObj.tableColMrp && !this.invoiceFieldsObj.tableColMrp.status){
            colSpanSize = colSpanSize - 1;
          }
        }
      }
      return colSpanSize;
    },
    
    fn_formType: function(){
      let transactionListId = this.data.transactionDetails.transactionListId;
      if(transactionListId == 10001 || transactionListId == 10002 || transactionListId == 10003 || transactionListId == 10004 || transactionListId == 10005){
        return 'sale';
      }
      else if(transactionListId == 20001 || transactionListId == 20002 || transactionListId == 20003 || transactionListId == 20004){
        return 'purchase';
      }
      else if(transactionListId == 20005){
        return 'expense';
      }
      else if(transactionListId == 30001 || transactionListId == 30002 || transactionListId == 30003 || transactionListId == 30004 || transactionListId == 30005){
        return 'accountbook';
      }
    },

    showBankDetails: function(){
      let transactionListId = this.data.transactionDetails.transactionListId;
      if(transactionListId == 10002 || transactionListId == 10003 || transactionListId == 10005){
        return true;
      }
      else{
        return false;
      }
    },

    showBalanceLabel: function(){
      let transactionListId = this.data.transactionDetails.transactionListId;
      if(transactionListId == 10001 || transactionListId == 10002 || transactionListId == 10005 || transactionListId == 20001 || transactionListId == 20002){
        return false;
      }
      else{
        return true;
      }
    },

    showTaxLabels: function(){
      let transactionListId = this.data.transactionDetails.transactionListId;
      if(this.$root.CurrentBusiness && this.$root.CurrentBusiness.GSTRegistrationStatus != 3){
        return true;
      }
      else{
        if(transactionListId == 20001 || transactionListId == 20002 || transactionListId == 20003 || transactionListId == 20004 || transactionListId == 20005){
          return true;
        }
        else{
          return false;
        }
      }
    },

    fn_getToken: function(val){
      if(this.$root.appVersion < 109){
        return;
      }
      // this.showLoading("Please wait...");
      this.$root.fn_getAuthToken(token=>{
        this.fn_brandLogoToServer(val, token);
      });
    },

    fn_brandLogoToServer: function(val, token){
      if(!navigator.onLine){
        // this.showToast('Internet not available');
        return;
      }
      
      // let self = this;
      let dataObj = {};
      dataObj.businessId = this.$root.CurrentBusiness.BusinessID;
      dataObj.token = token;

      let fnName = val ? "EnableLogo" : "RemoveLogo";
      this.getServerData(fnName, dataObj, 
        {
          onSuccess: function(response){
            console.log(response);
            // self.hideLoading();
          }, 
          onError: function(error){
            console.log(error);
            // self.hideLoading();
            // if(typeof JSON.parse(error) == 'object'){
            //   let response = JSON.parse(error);
            //   self.showToast(response.Message);
            // }
          }, 
        }
      );
    },

    fn_previousBalance: function(){

			if(!this.data.transactionDetails.partyDetails.currentBalance){
				return 0;
			}

			let cashTotal = 0;
			if(this.data.transactionDetails.paymentAccounts && this.data.transactionDetails.paymentAccounts.length){
				for(let i=0; i<this.data.transactionDetails.paymentAccounts.length; i++){
					cashTotal = this.roundUpto2Decimals(cashTotal + Math.abs(this.data.transactionDetails.paymentAccounts[i].amount));
				}
			}

			if(this.data.transactionDetails.transactionListId == '10003' || this.data.transactionDetails.transactionListId == '20004'){
				return (this.data.transactionDetails.partyDetails.currentBalance + cashTotal) - this.data.transactionDetails.roundedAmount;
			}

			else if(this.data.transactionDetails.transactionListId == '20003' || this.data.transactionDetails.transactionListId == '20005' || this.data.transactionDetails.transactionListId == '10004'){
				return (this.data.transactionDetails.partyDetails.currentBalance - cashTotal) + this.data.transactionDetails.roundedAmount;
			}
		}
  },
  // mounted(){
  //  this.fn_init();
  // },
  computed:{
    filteredItems: function(){
      let transactionGroups = [];

      for(let i in this.items){

        if(this.items[i].baseTransactionNumber != this.data.transactionDetails.transactionNumber){
          this.isRef = true;
        }

            let itemIndex = transactionGroups.findIndex(transItem => transItem.baseTransactionNumber==this.items[i].baseTransactionNumber);

            if(itemIndex!=-1){
                transactionGroups[itemIndex].items.push(this.items[i]);
            }
            else{
                let obj = {};
                obj.baseTransactionNumber = this.items[i].baseTransactionNumber;
                obj.items = []
                obj.items.push(this.items[i]);
                transactionGroups.push(obj);
            }
        }

        console.log(JSON.stringify(transactionGroups));

        return transactionGroups;
    },
    roundOffAmount: function(){
      if(this.data.transactionDetails.roundOffValue){
        return this.data.transactionDetails.roundOffValue;
      }
      else{
        if(this.data.transactionDetails.isRounded){
          return this.roundUpto2Decimals(this.data.transactionDetails.roundedAmount - this.data.transactionDetails.amount);
        }
        else{
          return 0;
        }
      }
    },
    invoiceColor: function(){
      if(this.themeColor){
        return this.themeColor;
      }
      else{
        return this.data.templateSettings.themeColor;
      }
    },
    getGridClass: function(){
      if(this.gridClass != undefined){
        return this.gridClass;
      }
      else if(this.gridClassValue){
        return this.gridClassValue;
      }
      else{
        return 'nogrid';
      }
    },
    footerSectionStyle: function(){
      if(this.footerSection != undefined){
        return this.footerSection;
      }
      else if(this.footerSectionValue){
        return this.footerSectionValue;
      }
      else{
        return 1;
      }
    },
    hasFooterSection: function(){
      let hasTerms = this.terms.showInPrintOut && this.terms.data && this.terms.data.content;
      let hasPrintNote = this.printNote.showInPrintOut && this.printNote.data && this.printNote.data.content;
      let hasUPIDetails = this.$root.CurrentBusiness.UpiID && this.upiDetailField.showInPrintOut && this.showBankDetails();
      let hasBankDetails = this.bankDetails.showInPrintOut && this.bankDetails.data && this.bankDetails.data.content && this.showBankDetails();
      let hasSignature = this.signatory.showInPrintOut && this.signatory.data && this.signatory.data.content;
      let hasBrandLogo = this.brandLogo.showInPrintOut;
      return hasTerms || hasPrintNote || hasUPIDetails || hasBankDetails || hasSignature || hasBrandLogo || this.isEditable;
    }
  }
}