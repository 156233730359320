<template>
  <v-app>
<!--     <v-app-bar app color="white" :height="56" flat>

    <v-toolbar-title>Reports</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar> -->


    <v-main>
      <!-- <v-divider></v-divider> -->
      <router-view/>
    </v-main>

    <dialog-action ref="ref_dialogAction" :title="dialogActionTitle" :message="dialogActionMessage" :btnText="dialogActionBtnText" :actionMethod="dialogActionMethod" :cancelBtnText="dialogCancelBtnText" :cancelMethod="dialogCancelMethod"></dialog-action>

    <v-snackbar absolute right top v-model="$root.snackbar" :vertical="$root.snackbarIsVertical" :timeout="$root.snackbarTimeout">
        <span v-html="$root.snackbarMessage"></span>
        <template v-slot:action="{ attrs }">
            <v-btn color="warning" font-medium capitalize text v-bind="attrs" @click="$root.snackbar = false">Close</v-btn>
        </template>
    </v-snackbar>

    <v-navigation-drawer absolute right temporary v-model="$root.infoDrawer" :width="320" class="donotprint info_drawer">
        <div class="pa-4">
          
          <v-subheader style="height: 32px;" class="pa-0 font16"><strong>Verify these steps and retry</strong> <v-spacer></v-spacer> <v-icon @click="$root.infoDrawer=false">close</v-icon></v-subheader>

          <div class="pa-2 mt-4" style="border-radius: 5px;background: rgba(0,0,0,0.1);">
            <ol>
              <li>
                <p class="mb-2" style="line-height: 28px;">Your mobile device must have an active&nbsp;&nbsp;<v-icon color="primary" class="mb-1">wifi</v-icon>&nbsp;&nbsp;<strong>internet connection</strong>.</p>
              </li>
            </ol>

            <!-- <p center-align class="mb-2"><v-icon color="primary" style="font-size: 32px;">wifi</v-icon></p> -->
          </div>

          <div class="pa-2 mt-4" style="border-radius: 5px;background: rgba(0,0,0,0.1);">
            <!-- <p center-align class="mb-2"><img class="ma-2 mb-0" style="max-height:300px;" src="./assets/ServerEnable.gif" alt=""></p> -->
            <ol start="2">
              <li>
                <p class="mb-2" style="line-height: 28px;">Enable <strong>mobile server</strong> on your BizOps mobile app.</p>
              </li>
            </ol>
            <p class="mb-0 font12 light-opacity-color pl-6" style="line-height: 16px;"><strong>Note:</strong> Update <strong>BizOps App</strong> on your mobile, if not updated.</p>
            <div class="info_class"></div>
          </div>

          <!-- <div class="pa-2 mt-4" style="border-radius: 5px;background: rgba(0,0,0,0.1);">
            <ol start="3">
              <li>
                <p class="mb-0" style="line-height: 28px;">Update <strong>BizOps App</strong> on your mobile if not updated.</p>
              </li>
            </ol>
          </div> -->

          <!-- <div right-align class="mt-2">
            <v-btn style="text-transform:lowercase" small text color="primary">Do not remind me again</v-btn>
          </div> -->
        </div>
      </v-navigation-drawer>
  </v-app>
</template>

<script>

import dialogAction from './components/dialogs/dialog_action'

export default {
  name: 'App',

  data: () => ({
    //
    dialogActionTitle:null,
    dialogActionMessage:null,
    dialogActionBtnText:null,
    dialogCancelBtnText:null,
    dialogActionMethod:null,
    dialogCancelMethod:null,
    fnUpdateBusinessDetails:null
  }),
  components:{
    'dialog-action':dialogAction
  },
  methods:{
    fn_openDialogAction: function(type){
        if(this.$refs && this.$refs['ref_dialogAction']){
            if(type == 'businessupdate'){
                this.dialogActionTitle = 'Update';
                this.dialogActionMessage = 'Business profile has been updated';
                this.dialogActionBtnText = 'Update Now';
                this.dialogActionMethod = this.fn_updateBusinessDetails;
                this.dialogCancelBtnText = null;
                this.dialogCancelMethod = null;
            }
            else if(type == 'playstoreupdate'){
                this.dialogActionTitle = 'Update Mobile App';
                // this.dialogActionMessage = 'Please update your mobile app and press continue';
                this.dialogActionBtnText = 'Continue';
                this.dialogActionMethod = this.fn_checkMobileUpdate;
                // this.dialogCancelBtnText = 'Logout';
                // this.dialogCancelBtnText = 'Later';
                // this.dialogCancelMethod = this.fn_relogin;
                // this.dialogCancelMethod = null;
            }
        }

        this.$refs['ref_dialogAction'].openDialogWeb();  
    },

    fn_checkMobileUpdate: function(){
        // this.$root.fn_getCheckPlayStoreUpdate(true, response=>{
        // this.$root.fn_getCheckPlayStoreUpdate(response=>{
        //     let res = JSON.parse(response)
        //     if(!res){
        //         window.location.href = process.env.VUE_APP_BASE_URL
        //         this.fn_closeDialog();
        //     }
        // })

        this.$root.fn_getLocalData('app_version', response=>{
          // this.fn_closeDialog();
          if(response >= 172){
            this.fn_closeDialog();
            window.location.href = process.env.VUE_APP_BASE_URL;
          }
          else{
            this.$eventHub.$emit('snackbarAlert', {msg:'Please update mobile app to continue'});
          }
        })
    },

    fn_relogin: function(){
        // this.fn_closeDialog();
        this.fn_closeConnection(true);
        // let type = this.$route.name == 'Login' ? 'refresh_login' : null;
    },

    fn_closeDialog: function(){
      if(this.$refs && this.$refs['ref_dialogAction']){
        this.$refs['ref_dialogAction'].closeDialogWeb();
      }
    },

    fn_updateBusinessDetails: function(){
        this.fn_closeDialog();
        // this.fnUpdateBusinessDetails();
        this.$root.fn_getBusiness(null, this.$root.CurrentBusiness.GSTRegistrationStatus);
    }
  },
  mounted: function(){
    // this.fn_openDialogAction();
    this.$eventHub.$on('UpdateBusinessDetail', dataObj => {
      // if(dataObj.fnName){
      //   this.fnUpdateBusinessDetails = dataObj.fnName;
      //   this.fn_openDialogAction('businessupdate');  
      // }
      if(dataObj.type == 'UpdateBusinessDetail'){
        this.fn_openDialogAction('businessupdate');
      }
      else{
        this.$root.fn_getBusiness();
      }
    });

    this.$eventHub.$on('UpdateMobileApp', response => {
        if(response && response.onload){
          this.dialogActionMessage = 'Update available for your mobile app.<br>Press continue after update.';
          this.dialogCancelMethod = this.fn_relogin;
          this.dialogCancelBtnText = 'Logout';
        }
        else{
          this.dialogActionMessage = 'To use this feature update your BizOps App on your mobile device from <span font-medium>Google Play</span>.<br><br>Press continue after update.';
          this.dialogCancelMethod = null;
          this.dialogCancelBtnText = 'Later';
        }
        this.fn_openDialogAction('playstoreupdate');  
    });
  }
};
</script>

<style>
@font-face {
  font-family: "Helvetica", sans-serif;
  src: local("Helvetica"),
   url(./fonts/Helvetica.ttf) format("truetype");
}

.v-application{
    font-family: Helvetica, Arial sans-serif !important;
}

  [full-height], .full-height{
    height: 100% !important;
  }
  [color-white], .color-white{
    color: #ffffff !important;
  }
  [redcolor], .redcolor{
    color: red !important;
  }
  [greencolor], .greencolor{
    color: green !important;
  }
  [greycolor], .greycolor{
    color: #999 !important;
  }
  [grey-back], .grey-back{
    background: #f0f0f0 !important;
    /* background: #f9f9f9 !important; */
  }
  [dark-grey-back], .dark-grey-back{
    background: #f0f0f0 !important;
  }
  [white-back], .white-back{
    background: #ffffff !important;
  }
  [dark-back], .dark-back{
    background:rgba(0,0,0,0.8) !important;
  }
  [font-heavy], .font-heavy{
    font-weight: 700
  }
  [font-medium], .font-medium{
    font-weight: 600
  }
  [font-light-medium], .font-light-medium{
    font-weight: 500
  }
  [font-normal], .font-normal{
    font-weight: 400
  }
  [cursor-pointer], .cursor-pointer{
    cursor: pointer;
  }
  [capitalize], .capitalize{
    text-transform: capitalize !important;
  }
  [text-underline], .text-underline{
    text-decoration: underline !important;
  }
  [flex-align-vertical-center], .flex-align-vertical-center{
    align-self: center;
  }
  [font11], .font11{
    font-size:11px !important;
  }
  [font6], .font6{
    font-size: 6px !important;
  }
  [font8], .font8{
    font-size: 8px !important;
  }
  [font10], .font10{
    font-size: 10px !important;
  }
  [font12], .font12{
    font-size: 12px !important;
  }
  [font14], .font14{
    font-size: 14px !important;
  }
  [font16], .font16{
    font-size: 16px !important;
  }
  [font20], .font20{
    font-size: 20px !important;
  }
  [font24], .font24{
    font-size: 24px !important;
  }
  [font18], .font18{
    font-size: 18px !important;
  }
  [right-align], .right-align{
    text-align:right;
  }
  [center-align], .center-align{
    text-align:center;
  }
  [left-align], .left-align{
    text-align:left;
  }
  [justify-center], .justify-center{
    justify-content:center;
  }
  [justify-end], .justify-end{
    justify-content:end;
  }
  [light-opacity-color], .light-opacity-color{
    color:rgba(0,0,0,0.54);
  }
  [dark-opacity-color], .dark-opacity-color{
    color:rgba(0,0,0,0.87) !important;
  }
  [font-heavy], .font-heavy{
    font-weight:500;
  }
  [full-width], .full-width{
    width: 100%;
  }
  [no-border-radius], .no-border-radius{
    border-radius:0 !important;
  }
  [height48], .height48{
    height:48px;
  }
  [fade-opacity], .fade-opacity{
    opacity:0.2;
  }
[font-small], .font-small{
  font-size: small !important
}
[noshadow], .noshadow{
    box-shadow: none !important;
}
[subtitle], .subtitle{
  font-size: 1rem;
}

.circle_arrow_icon.v-icon{
  background: #eee;
  border-radius: 50%;
  padding: 2px;
  margin-right: 2px;
  vertical-align: top !important;
  font-size: 16px !important;
}

.v-snack.v-snack--active{
    z-index: 9999;
}
  .v-expansion-panel-content__wrap{
    padding: 0 !important;
  }

  .v-expansion-panel-header{
    padding:0px 16px !important;
  }

  .v-text-field .v-label{
    font-size: 12px  !important;
    top: -18px !important;
    transform: none !important;
    left: 0px !important;
  }

  /*.v-text-field.v-text-field--outlined .v-label{
    font-size: inherit  !important;
    top: 18px !important;
  }*/

  .flex-vertical-center{
    display: flex;
    align-items: center;
  }

  .two-line-title{
    font-size:18px !important;
    line-height:24px;
  }
  .two-line-title .toolbar-subtitle{
    line-height:18px; 
  }
  .two-line-title .toolbar-subtitle .subtitle-text{
    display:inline;
  }
  .two-line-title .toolbar-subtitle .v-icon.subtitle-icon{
    position:absolute;
    bottom:2px;
    color:rgba(0,0,0,0.87) !important;
  }

  .v-list .v-list-item--active{
    border-radius: 0 !important;
  }

  .v-dialog.half-screen-dialog{
    margin: 0;
    height: 100%;
    max-height: 100% !important;
    position: absolute;
    right: 0;
    border-radius: 0 !important;
  }
  .v-dialog.half-screen-dialog.without-left-nav{
    width: 50%
  }

  .v-application .v-alert.info{
    background-color:#edf0f7 !important;
    color:rgba(0,0,0,0.87) !important;
    margin:0;
    border-radius: 0;
  }

  .v-application .v-alert.info .v-icon{
    color: #2196f3 !important;
  }

  [standard-font-size], .standard-font-size, .action_pane{
    font-size:0.8125rem;
  }

  .v-toolbar{
    z-index: 1;
  }

  .v-toolbar__extension .v-input__slot{
    padding: 0px;
  }
  .v-toolbar__extension, .v-toolbar__extension *{
    background: #f9f9f9 !important;
  }

  .hasToolBar{
    padding-top:56px;
  }

  .data-container{
    height: calc(100vh - 56px);
    overflow-y: auto;
  }

  [sticky-bottom], .sticky-bottom{
    position: sticky !important;
    bottom:0 !important;
  }

  [sticky-top], .sticky-top{
    position: sticky !important;
    top:0 !important;
  }

  .data-container.hasExtention{
    height: calc(100vh - 104px);
  }

  [form-item], .form-item{
    padding: 0 16px;
  }

  [nopadding], .nopadding{
    padding: 0px !important;
  }

  [primary-color], .primary-color, .v-icon.primary-color, .primary--text{
    color: #4b77be !important;
  }
  [primary-background-color], .primary-background-color{
    background: #4b77be !important;
  }

  [white-space-normal], .white-space-normal{
    white-space: normal !important;
  }

  .item_auto_complete{
    min-width: 320px;
  }

  html{
    overflow:hidden !important;
  }

  .warning-label{
    padding: 4px 16px;
    color: #fff;
    font-size: 12px;
    /* border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; */
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-right: -16px;
    background-color:#fb8c00!important;
    max-width: 82px;
    /* position: absolute;
    left: 0;
    top: 6px; */
    margin-bottom: 10px;
  }

  .removable_item{
    opacity: 0;
    /* display:none; */
    background-color: rgba(255,0,0,0.5);
    transition: opacity 0.5s linear;
    -webkit-transition: opacity 0.5s linear;
    -moz-transition: opacity 0.5s linear;
    -o-transition: opacity 0.5s linear;
    -ms-transition: opacity 0.5s linear;
  }
/*----------------- Transaction settings css ----------------------*/
  .switches .v-input--selection-controls__input{
    position: absolute;
    right:0;
  }
  .switches  .v-input--switch{
    padding: 16px 16px 0px 16px !important;
  }
  input[aria-label="App Lock"]{
    color: rgba(0,0,0,0.87) !important;
  }
  .v-input--switch.hide-margins .v-input__slot,
  .v-input--switch.hide-margins .v-input__slot .v-input--selection-controls__input{
    margin:0 !important;
  }

  [toolbar-subtitle], .toolbar-subtitle{
    font-size: small;
    overflow: hidden;
    text-overflow: ellipsis; 
    transition: all 1s;
  }
  .two-line-title{
    font-size:18px !important;
    line-height:24px;
  }
  .two-line-title .toolbar-subtitle{
    line-height:18px; 
  }
  .two-line-title .toolbar-subtitle .subtitle-text{
    display:inline;
  }
  .two-line-title .toolbar-subtitle .v-icon.subtitle-icon{
    position:absolute;
    bottom:5px;
    color:rgba(0,0,0,0.87) !important;
  }
/*----------------- Transaction settings css end ----------------------*/

/*===========Navigation drawer css=============*/
.v-navigation-drawer__content{
  overflow:hidden !important;
}
.v-navigation-drawer__content .v-list--nav{
  overflow:auto !important;
  height:100%;
  padding-bottom:164px;
}

.info_drawer .v-navigation-drawer__content{
  overflow: auto !important;
}

.info_drawer{
  z-index: 9999 !important;
}

.info_class{
  background-image: url('./assets/ServerEnable.gif');
  width: 100%;
  height: 292px;
  background-position-y: bottom;
  background-size: cover;
  margin-top: 16px;
}



.v-list--nav .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.v-list--nav .theme--light.v-icon{
  color: rgba(255,255,255,0.6) !important;
}
.v-list--nav .v-list-item--active .theme--light.v-icon{
  color:#ffffff !important;
}

.v-list--nav .dialog-item{
  min-height: 40px;
  padding: 0px 24px;
  margin-bottom:4px;
  font-size: 16px;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
  color:rgba(255,255,255,0.6) !important;
  display:flex;
  align-items:center;
}
.v-list--nav .dialog-item:hover{
  background:rgba(255,255,255,0.02) !important;
}
.v-list--nav .dialog-item .v-icon.v-list-item__icon{
  font-size:18px;
  justify-content:left;
  margin-top:11px;
  margin-bottom:11px;
}
/*=================Scroll bar css=================*/
/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666; 
}
/*=================End of scroll bar css=================*/

/*tabs css*/
.v-tabs{
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px #f0f0f0 solid;
}

/*=======================list items css===================*/
.filter-display{
  height:58px;border-top:1px #ddd solid;border-bottom:1px #ddd solid;clear:both;
}

.filter-display .display-block{
  height:48px;
  margin: 4px;
  padding: 2px 8px;
  border-radius: 5px;
}

.filter-display .display-block .filter-display-value{
  line-height:22px;
}
/*=======================End of list items css===================*/

/*autocomplete*/
.v-autocomplete.autocomplete-small{
  max-width: 80px !important;
  max-height: 28px !important;
}
.v-autocomplete.autocomplete-small .v-input__slot{
  font-size: 12px !important;
  min-height: 28px !important;
  max-width: 80px !important;
}
.v-autocomplete.autocomplete-small input{
  max-height: 24px !important;
  padding: 0 !important;
  min-width: 40px !important;
}
.v-autocomplete.autocomplete-small .v-input__append-inner{
  padding-left: 1px !important;
}
.v-autocomplete.autocomplete-small button.v-icon{
  font-size: 16px !important;
}
.v-autocomplete.autocomplete-small.item-selected .v-input__icon--append{
  display: none !important;
}

/*==========End of autocomplete==========*/

/*===========Invoice css=============*/
#invoice_card{
  zoom:80%;
}
.invoice-preview-settings{
  zoom:90%;
}
.items-table-wrapper{
  max-height:300px;
  overflow-y:auto
}
.items-table thead{
  z-index: 2;
}
.items-table tr th,
.items-table tr td{
  padding:8px;
  vertical-align:top;
}

.items-table{
  border-collapse: collapse;
  border-style:dotted;
  border-color:#ddd;
  border:0;
  color:rgba(0,0,0,0.57);
}

.items-table.has-items{
  border-style:solid;
  color:rgba(0,0,0,0.87);
}

.items-table tr{
  border-bottom:1px rgba(0,0,0,0.54) solid;  
}

.items-table tr td .v-input,
.items-table tr td .v-input__control,
.items-table tr td .v-input__slot{
  min-height:28px !important;
  height:28px !important;
  font-size:11px;
}

/*.small-text-box .v-input,*/
.small-text-box .v-input__control,
.small-text-box .v-input__slot{
  min-height:32px !important;
  height:32px !important;
  font-size:12px;
}

.small-text-box .v-input__slot{
  padding: 0px 8px 0px 12px !important;
}

.items-table .v-input__slot{
  padding: 0px 8px !important;
}

.small-text-box .v-input__append-inner{
  margin-top:4px !important;
}


.small-checkboxes{
  display: flex;
  justify-content: flex-end;
}

.small-checkboxes label{
  font-size: 14px;
}

.small-checkboxes .v-icon{
  font-size: 20px !important;
}

.small-textbox-table .v-input__slot{
  padding-right:4px !important;
}

.small-textbox-table .v-input__append-inner{
  margin-top:2px !important;
}
/*============= End of Invoice css ================*/

/*========== Branding =============*/
.branding .title{
  font-weight: 600;
  font-style:italic;
  display:flex;
  justify-content:center;
}
.brand_logo{
  width:24px;
  margin-right:6px;
}
.brand_logo.logo_small{
  width:10px;
  margin-right:2px;
  margin-bottom: -2px;
}
.branding a{
  text-decoration: none;
}

/*==========================*/

/*========== Business Logo =============*/

.business-logo-view .logo-img{
  max-height:120px;
  max-width:120px;
  border: 4px #ddd solid;
}
.business-logo-view .logo-placeholder{
  width: 120px;
  height: 120px;
  padding:44px 32px;
  margin: auto;
  font-size:16px;
  color:rgba(0,0,0,0.2)
}
.business-logo-view .logo-placeholder .v-icon{
  font-size: 92px;
  padding: 48px;
}

/*==========================*/

/*============== Croppie ================*/
.croppie-container{
  width: auto !important;
  height: auto !important;
}
/*==================*/

.small-switch .v-input__slot{
  height:10px;
}

.small-switch .v-input__slot .v-input--selection-controls__input{
  width:22px;
}

.small-switch .v-input__slot .v-input--selection-controls__input .v-input--switch__track{
  height:10px;
  opacity:0.4;
  top: calc(50% - 7.4px);
  width:22px !important;
}

.small-switch .v-input__slot .v-input--selection-controls__input .v-input--switch__thumb{
  width: 12px;
  height: 12px;
  top: calc(50% - 9px);
  left:1px;
}

.small-switch .v-input--selection-controls__ripple{
  width:24px;
  height:24px;
  display:none;
}

.small-switch.v-input--switch.v-input--is-dirty .v-input--switch__thumb,
.small-switch.v-input--switch.v-input--is-dirty .v-input--selection-controls__ripple{
  transform: translate(11px) !important;
}

.item-note-field textarea{
  line-height: 1.2rem !important;
}

/* ================= invoice template css ======================= */
.hasborder{
  border: 1px #ddd solid;
}
.invoice-footer{
    display: flex;
    justify-content: right;
}
.invoice-footer .branding{
    padding:0 !important;
    border-radius:5px;
}
.invoice-footer .branding .branding-box{
    padding:4px 8px;
    border-radius:5px;
}
.invoice-footer .branding .branding-box .powered{
    font-size:9px !important;
    line-height:12px;
}
.invoice-footer .branding .branding-box .title{
    line-height:18px;
    align-items: center;
}
.invoice-footer .branding .branding-box .title .brand_logo{
    width:14px;
    height:14px;
    margin-right:4px;
    margin-bottom:0px
}

.row-app-logo td{
  position: relative;
}
.row-app-logo td .invoice-footer{
  border: 1px #ddd dashed;
  padding: 12px;
  border-radius: 5px;
  width: fit-content;
  display: inline-block !important;
}
.row-app-logo td .v-input{
  position: absolute;
  right: 8px;
  background: #fff;
  padding: 0px 8px;
  bottom: 10px;
}

.invoice_table{
    /* font-family: "Roboto", sans-serif !important; */
    font-family: Helvetica, Arial sans-serif !important;
    font-size:12px;
}

.invoice_table .pt-1{
    padding-top:4px;
}
.invoice_table .pt-2{
    padding-top:8px;
}
.invoice_table .pt-3{
    padding-top:12px;
}
.invoice_table .pb-1{
    padding-bottom:4px;
}
.invoice_table .pb-2{
    padding-bottom:8px;
}
.invoice_table .pb-3{
    padding-bottom:12px;
}
    
.invoice-footer-actions{
  height:auto !important;
  position:fixed;
  bottom:0;
  width:100%;
  z-index:9;
  border-top:1px #ddd solid;
}
.bill_template.preview *{
  font-size: 12px !important;
}
.bill_template{
  font-size: 12px;
  background:#fff;
  line-height: 20px;
  color:#000 !important;
  font-weight: 500 !important;
}

.bill_template.font_small *{
  font-size: 8px !important;
  line-height: 12px;
}
.bill_template.font_large *{
  font-size: 12px !important;
  line-height: 18px;
}
.bill_template.font_xl *{
  font-size: 14px !important;
  line-height: 22px;
}
.bill_template.font_xxl *{
  font-size: 16px !important;
  line-height: 28px;
}

.bill_template .ql-size-small{
    font-size: 0.75em !important;
}
.bill_template .ql-size-large{
    font-size: 1.5em !important;
}
.bill_template .ql-size-huge{
    font-size: 2.5em !important;
}

.bill_template p{
  margin-bottom:0 !important;
  line-height: 20px;
}

.bill_template table{
  height:1px;
  border-collapse: collapse;
}

.bill_template table tr td{
  padding: 4px;
  border:1px #000 solid;
  vertical-align: top;
  height: inherit !important
}

.bill_template .business_details{
  word-break: break-word;
}

.bill_template .business_details img{
  max-width: 80px;
}

.bill_template .col33_3{
  width:33.3%;
  float:left;
  padding:8px;
}

.bill_template .col50_2{
  width:50% !important;
  float:left;
  padding:8px;
}

.no-right-padding{
    padding-right:0px !important
}

.no-left-padding{
    padding-left:0px !important
}

.bill_template .col25{
  width:25% !important;
  float:left;
  padding:8px;
}

.bill_template .border-right{
  border-right:1px #000 solid;
}

[noborder], .noborder, .noborder tr td, .noborder td{
  border:none !important; 
}

.bill_template .single-column-double-section{
  padding: 4px;
  padding-right: 0 !important;
}
.bill_template .single-column-double-section .left-column,
.bill_template .single-column-double-section .right-column{
  width: 50%;
  float: left;
  padding:4px 0px !important;
}

.bill_template .col67{
  width:67%;
  float:left;
  padding:4px 0px;
}

.bill_template .col33{
  width:33%;
  float:left;
  padding:4px 0px;
}

.bill_template .col33_33{
  width:33.33%;
  float:left;
  padding:4px 0px;
}

.bill_template .col75{
    width:75%;
    float:left;
    padding:4px 0px;
}

.bill_template .col25{
    width:25%;
    float:left;
    padding:4px 0px;
}

.bill_template .bottom-details{
    height: 100%;
    display: table;
    width: 100%;
}

.table-cell{
    display: table-cell;
    padding: 4px 0px;
    vertical-align: bottom;
}

.bill_template .upi-details-section{
    float: left;
    padding-top: 16px !important;
    padding-right: 16px !important;
    width: 55%;
}

.bill_template .upi-details-section .qr-code-container{
    float:left;
    margin-right:8px;
    padding-top:4px;
}

.bill_template .upi-details-section .qr-code-container img{
    width: 64px;
}

.qr-code-details{
    padding-left:56px
}

.bill_template .bank-details-section{
    float:left;
    padding-top:16px!important;
    width:45%;
}

.bill_template .signature-section{
    display:flex;
    align-items:flex-end;
    justify-content:right;
}

.bill_template .table_div{
  display: table;
  width:100%
}
.bill_template .table_div_row{
  display: table-row;
}
.bill_template .table_div_col{
  display: table-cell;
}

.bill_template .border-bottom{
  border-bottom:1px #ddd solid !important;
}
.bill_template .border-left{
  border-left:1px #ddd solid !important;  
}
.bill_template .border-right{
  border-right:1px #ddd solid !important; 
}
.bill_template .border-top{
  border-top:1px #ddd solid !important;
}

.bill_template .bottom-row-container{
    width:100%;
    display:table;
}

.bill_template .bottom-left-column{
    display: table-cell;
    width: 67%;
    padding: 4px 0px;
}
.bill_template .bottom-right-column{
  position:relative;
  height: 100%;
  display: table-cell;
  vertical-align: bottom;
  width: 33%;
}

/*.bill_template .bottom-right-column .signatory{
  padding: 12px;
}*/

.bill_template .bottom-right-column .signatory img{
  /* width: 120px;
  height:24px; */
  max-width: 120px;
  max-height: 80px;
}

.bill_template .description_row{
  white-space: pre-line;
  overflow-wrap: break-word;
}

.editable{
  border:1px dashed lightgray;
  position:relative;
}
.editabled-field-label{
  position: absolute;top: -21px;right: 0;padding: 0px 4px;background: #f0f0f0;font-size: 8px !important;
}
.bill_template.preview .editabled-field-label{
  font-size: 8px !important 
}
.preview .invoice_title{
  font-size:20px !important;
}
.bill_template.font_small .invoice_title{
  font-size:16px !important;
}
.bill_template.font_large .invoice_title{
  font-size:24px !important;
}
.bill_template.font_xl .invoice_title{
  font-size:26px !important;
}
.bill_template.font_xxl .invoice_title{
  font-size:28px !important;
}
.no-left-border{
  border-left:0 !important;
}
.no-right-border{
  border-right:0 !important;
}

/* .preview.bill_template .business_logo{
  width: 48px;
  height: 48px;
} */

/* .bill_template .business_logo{
  display: inline-block;
  width: 48px;
  position: relative;
  height: 48px;
} */

.break_word{
  word-break:break-word
}


.bill_template .business_logo img{
  /* width: 100%;
  height: 100%; */
  max-width: 60px;
  max-height: 60px;
}

.bill_template table tr { height: 1px }
.bill_template table tr td > div { height: 100%; }
.bill_template.template_101 table tr td > div { height: auto; }

.bill_template.template_101 table tr { height: auto !important }

.bill_template.gridall table tr td *{
  border-color: rgba(0,0,0) !important;
}

.bill_template.gridall table tr td{
  border: 0.5px solid rgba(0,0,0) !important;
  padding: 0px 4px;
}

.bill_template.gridall table tr.items_row td,
.bill_template.gridall table thead.items_header td,
.bill_template.gridall table tr.items_footer td{
  padding: 4px;
}

.bill_template.gridall table tr td .col50_2,
.bill_template.gridall table tr td .col33_3,
.bill_template.gridall .single-column-double-section .left-column,
.bill_template.gridall table tr td .bottom-left-column{
  border-right: 0.5px solid rgba(0,0,0);
}
.bill_template.gridall table tr td .col50_2:last-child,
.bill_template.gridall table tr td .col33_3:last-child{
  border-right: 0 !important
}

/* .bill_template.gridall table tr td .terms-section,
.bill_template.gridall table tr td .printnote-section{
  border-bottom: 0.5px solid rgba(0,0,0,.4);
  margin-left: -4px;
  padding-left: 4px;
} */

.bill_template.gridall tr td .bottom-row-container .bottom-left-column > div:not(:last-child){
  border-bottom: 1px solid rgba(0,0,0) !important;
  margin-left: -4px;
  padding-left: 4px;
}

.bill_template.gridall table tr td .topborder{
  border-top: 0.5px solid rgba(0,0,0);
  margin-left: -4px;
  padding-left: 4px;
}

.bill_template.gridall table tr td .other-field-item{
  border-bottom: 0.5px solid rgba(0,0,0);
  margin: 0px -8px 0px -8px;
  padding-left: 8px;
}
.bill_template.gridall table tr td .other-field-item:last-child{
  border-bottom: 0 !important;
}

.bill_template.gridall table tr td .header-right-section{
  padding: 0px;
}
.bill_template.gridall table tr td .header-right-section div{
  border-bottom: 0.5px solid rgba(0,0,0);
  margin-right: -4px;
  padding:2px 4px;
}
.bill_template.gridall table tr td .table_div .table_div_row{
  border-bottom: 0.5px solid rgba(0,0,0);
}
.bill_template.gridall table tr td .table_div .table_div_row .table_div_col{
  padding:2px 4px;
}
.bill_template.gridall table tr td .table_div .table_div_row:last-child{
  border-bottom: 0 !important;
}

.bill_template.gridall table tr td .header-right-section div:last-child{
  border-bottom: 0;
}

/* .bill_template.gridall table tr td .upi-details-section{
  border-right: 1px solid rgba(0,0,0);
} */

.bill_template.griditems table thead.items_header td,
.bill_template.griditems table tr.items_row td,
.bill_template.griditems table tr.items_footer td{
  border: 0.5px solid rgba(0,0,0) !important;
}

.bill_template.griditems table tr.items_row td,
.bill_template.gridall table tr.items_row td,
.bill_template.griditems table tr.items_row,
.bill_template.gridall table tr.items_row{
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.bill_template.griditems table tr td.border-top-none,
.bill_template.gridall table tr td.border-top-none{
  border-top:0 !important
}

.bill_template.griditems table tr td.border-bottom-none,
.bill_template.gridall table tr td.border-bottom-none,
.bill_template.gridall table:first-child tbody tr:last-child td{
  border-bottom:0 !important
}

/* grid css template 2 */
.bill_template.gridall table tr td .bottomborder{
  border-bottom: 0.5px solid rgba(0,0,0);
}
.bill_template.template_2.gridall table tr td.business_row{
  padding-right: 0px;
}
.bill_template.template_2.gridall table tr td.business_row .col50_2:last-child .layout div.flex{
  border-right: 1px solid rgba(0,0,0);
}
.bill_template.template_2.gridall table tr td.business_row .col50_2.placeofsupply:last-child .layout.invoice_details div.flex:nth-child(3){
  border-right:0
}
.bill_template.template_2.gridall table tr td.business_row .col50_2.noplaceofsupply:last-child .layout.invoice_details div.flex:nth-child(2){
  border-right:0
}
.bill_template.template_2.gridall table tr td.business_row .col50_2:last-child .layout.other_details div.flex:nth-child(3){
  border-right:0
}
/* .bill_template.template_2.gridall table tr td.business_row .col50_2:last-child .layout div.flex:nth-child(3){
  border-right:0
} */
.bill_template.gridall table tr td .borderwhite,
.bill_template.gridall table tr td.borderwhite{
  border-color: #ffffff !important;
}
/* ================== */

/* grid css template 3 */
.bill_template.template_3.gridall table tr td.business_row{
  padding-right: 0 !important;
}
/* ================== */

/* grid css template 4 */
.bill_template.template_4.gridall table tr td.business_row{
  padding-right: 0 !important;
}
.bill_template.gridall table tr td .rightborder{
  border-right: 0.5px solid rgba(0,0,0);
}
.bill_template.template_4.gridall table tr td .col33_3 .bottomborder:last-child{
  border-bottom:0;
}
/* ================== */

/* grid css template 5 */
.bill_template.template_5.gridall table tr td .col33_3 .bottomborder:last-child{
  border-bottom:0;
}
/* ================== */

/* grid css template 6 */
.bill_template.template_6.gridall table tr td.business_row{
  padding-right: 0 !important;
}
/* ================== */

/* css as per desktop */
.bill_template.gridall .single-column-double-section{
  border-top: 0 !important;
}
.bill_template.gridall table tr td.no_topborder{
  border-top: 0 !important;
}
.bill_template.gridall table tr td.no_bottomborder{
  border-bottom: 0 !important;
}
/* =================== */

.batch_selected{
  background-color: #E5E4E2 !important;
}

/* quill editor */
.ql-size-large{
    font-size: 1.5em !important;
}
.quill-editor .ql-container{
  min-height: 100px;
}
.child-paragraph-no-margin p{
  margin-bottom: 0 !important;
}
/* ======== */

.remove-logo-btn .v-btn__content{
  font-size: 10px !important;
}

.right-text-field input{
  text-align: right;
}
/* .bill_template.template_2 .hasLogo{
  float: right;
} */
/* .preview.template_2 .business-details{
  float:left;
  min-width:100px;
  max-width:200px;
} */

/* .bill_template.template_2 .business_logo{
  margin-left:8px;
} */

body *{
  -webkit-print-color-adjust: exact !important;
} 

#printable{
  display: none;
}

#printable-area{
  display: none;
}

#printable .remove-logo-btn{
  display: none !important;
}

#printable-area .remove-logo-btn{
  display: none !important;
}

@media print{
  #non-printable, #non-printable-area, .donotprint{display:none !important}
  .v-main{padding: 0 !important}
  .action_pane{max-width: 100% !important;flex-basis:100% !important;width:100% !important;}
  #printable{display: block;}
    /* @page {
        margin-top: 72px;
        margin-bottom: 72px;
    } */
  @page {
    /* size:A4; */
    margin: 0.5cm 1cm 0.5cm 1cm;
  }
}

/* @page {
    margin: 0.5cm 0.5cm 0.5cm 0.5cm;
    @bottom-right {
        content: counter(page) " of " counter(pages);
    }
} */
</style>