<template>
    <div>
        <div id="non-printable">
            <!-- <v-toolbar text fixed>
                <v-toolbar-side-icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-toolbar-side-icon>

                <v-toolbar-title class="white--text">{{toolbarTitle}}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon @click="fn_getReportData" v-if="listItemObject.totalCount"><v-icon>vertical_align_bottom</v-icon></v-btn>
                <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

                <div full-width slot="extension" :class="{'search-filter':isFilterApplicable, 'bottom-shadow':scrollPositionTop!=0}">
                    <v-text-field clearable elevation-10  text solo placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch"></v-text-field>
                    <div v-if="isFilterApplicable" v-ripple class="filter-btn" @click="fn_openFilter">
                        <v-icon>filter_list</v-icon>
                        <span style="font-size: 48px;position: absolute;bottom: -14px;right: 6px;color: #4b77be;" v-if="selectionItems.typeFormId || selectionItems.orderChallanStatus!=1">&bull;</span>
                    </div>
                </div>
            </v-toolbar> -->

            <v-toolbar :height="56" flat extended>
                <!-- <v-btn icon @click="fn_goBack"><v-icon>arrow_back</v-icon></v-btn> -->

                <v-toolbar-title>{{toolbarTitle}}</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon color="primary" @click="fn_searchItem"><v-icon>autorenew</v-icon></v-btn>

                <v-btn icon @click="fn_getReportData" v-if="listItemObject.totalCount && !loading"><v-icon>vertical_align_bottom</v-icon></v-btn>

                <v-progress-circular indeterminate color="primary" v-if="loading" :width="2" :size="28" class="mr-2"></v-progress-circular>

                <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn>

                <v-btn icon @click="fn_openFilterNav">
                    <v-icon>filter_list</v-icon>
                    <span style="font-size: 48px;position: absolute;bottom: -10px;right: 6px;color: #4b77be;" v-if="selectionItems.typeFormId || selectionItems.orderChallanStatus!=1">&bull;</span>
                </v-btn>

                <!-- <v-btn icon @click="fn_getReportData" v-if="listItemObject.totalCount"><v-icon>vertical_align_bottom</v-icon></v-btn>
                <v-btn icon @click="fn_new"><v-icon>add</v-icon></v-btn> -->

                <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" @input="fn_debounceSearch" slot="extension"></v-text-field>
            </v-toolbar>

            <div class="data-container grey-back hasExtention" v-scroll.self="onScroll">

                <div class="filter-display grey-back" v-if="listItemObject.totalCount">
                    <v-layout ma-0 row wrap>
                        <v-flex xs6 sm6 md6>
                            <div class="display-block white-back">
                                <span font11 light-opacity-color>Count</span><br>
                                <span font16 class="filter-display-value">{{$root.numberToLocale(listItemObject.totalCount, 1)}}</span>
                            </div>
                        </v-flex>
                        <v-flex xs6 sm6 md6>
                            <div class="display-block white-back">
                                <span font11 light-opacity-color>Total Amount</span><br>
                                <span font16 class="filter-display-value">&#8377; {{$root.numberToLocale(listItemObject.totalAmount)}}</span>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>


                <v-list grey-back two-line nopadding>

                    <v-subheader white-back subheader-dense dark-opacity-color font-normal>
                        <!-- Period:&nbsp;<span @click="fn_openDatePicker('start', starDate)">{{selectionItems.startDate | moment("DD-MMM-YYYY")}}</span>&nbsp;To&nbsp;<span @click="fn_openDatePicker('endDate', endDate)">{{selectionItems.endDate | moment("DD-MMM-YYYY")}}</span> -->
                        <span @click="fn_openDialogCustomDates" v-if="selectionItems.selectedPeriod != 'All Time'">
                            Period: {{selectionItems.startDate | moment("DD-MMM-YYYY")}} To {{selectionItems.endDate | moment("DD-MMM-YYYY")}}
                        </span>
                        <v-spacer></v-spacer>
                        <!-- <v-menu v-model="dateMenu">
                            <v-btn justify-content-to-end style="height: 16px;" nomargin nopadding text capitalize font-normal slot="activator">
                                {{selectionItems.selectedPeriod}}&nbsp;<v-icon font20>date_range</v-icon></v-btn>
                            <v-list nopadding>
                              <v-list-item v-for="(item, index) in periods" :key="index" @click="fn_selectPeriod(item.value)">
                                  <v-list-item-title>{{item.title}}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                        </v-menu> -->
                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="pa-0" capitalize text v-bind="attrs" v-on="on">
                                {{selectionItems.selectedPeriod}}&nbsp;<v-icon>date_range</v-icon>
                              </v-btn>
                            </template>
                            <v-list nopadding>
                              <v-list-item v-for="(item, index) in periods" :key="index" @click="fn_selectPeriod(item.value)">
                                  <v-list-item-title>{{item.title}}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                        </v-menu>
                        <!-- <v-icon font20 @click="fn_getReportData" v-if="listItemObject.totalCount">vertical_align_bottom</v-icon> -->
                    </v-subheader>

                    <v-divider></v-divider>

                    <!-- <div v-if="listItemObject.totalCount">
                        <v-divider></v-divider>
                            <v-subheader subheader-dense color-light-grey>{{listItemObject.totalCount}} records</v-subheader>
                        <v-divider></v-divider>
                    </div> -->

                    <div center-align class="pa-6" v-if="(listItemObject.totalCount && (!listItemObject.items || !listItemObject.items.length)) || loadTimeOut">
                        <p font12 light-opacity-color>Unable to load the items!</p>
                        <v-btn capitalize text color="primary" @click="fn_searchItem">
                            <v-icon left>refresh</v-icon>
                            Retry
                        </v-btn>
                    </div>

                    <div white-back v-for="(item, index) in listItemObject.items" :key="index" :class="{'mt-2':index!=0}" :id="'item_'+item.transactionId">
                        <v-list-item class="right-subtitle-tile" @click="fn_itemClick(item.transactionId, index)">
                            <v-list-item-content>
                              <v-list-item-title class="mb-1">{{item.accountLedgerName}}</v-list-item-title>
                              <v-list-item-subtitle class="mb-1">
                                <!-- {{item.mergeTransactionNumber}} -->
                                <span class="primary-color">{{item.mergeTransactionNumber}}</span>
                                &nbsp;| {{item.transactionDate | moment("DD-MMM-YYYY")}}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle class="mb-1" v-if="item.refNumber">
                                Bill No. {{item.refNumber}}
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action align-flex-end>

                                <!-- <v-btn icon text @click="fn_getBottomMenu(902, item.transactionId)" v-if="item.status">
                                    <v-icon>more_horiz</v-icon>
                                </v-btn> -->
                                
                                <div class="warning-label" v-if="!item.status">Cancelled</div>

                                <!-- <div v-else></div> -->

                                <div>&#8377; {{$root.numberToLocale(item.roundedAmount)}}</div>

                            </v-list-item-action>
                        </v-list-item>

                        <!-- <v-divider v-if="listItemObject.items.length-1 != index"></v-divider> -->
                        <v-divider v-if="item.invoiceTemplate"></v-divider>
                        <div right-align style="padding:2px 6px;position:relative" v-if="item.invoiceTemplate">
                            <!-- <div class="warning-label" v-if="!item.status">Cancelled</div> -->

                            <v-btn nomargin icon text @click="fn_preview(item, true)"><v-icon font20>print</v-icon></v-btn>
                            <v-btn nomargin icon text @click="fn_preview(item)">
                                <v-icon>preview</v-icon>
                            </v-btn>
                        </div>
                    </div>

                    <!-- <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                    </infinite-loading> -->
                    <!-- <div white-back padding-all-16 center-align v-if="loadMore">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div> -->


                </v-list>

                <v-row class="ma-0" align-content="center" justify="center" v-if="loadMore">
                  <v-col cols="6">
                    <v-progress-linear rounded indeterminate :height="6" color="primary"></v-progress-linear>
                  </v-col>
                </v-row>


                <!-- <div class="display_msg" v-if="!listItemObject.items.length">There are no items</div> -->
            </div>
        </div>

<!--        <div id="printable" full-width>
          <component :is="printTemplate" ref="ref_printTemplate" :data="printData" v-if="printTemplate && printData!=null"></component>
        </div>

        <dialog-filter-items ref="ref_dialogFilterItems" title="Filter By" :filterItems="filerItems" :setFilter="fn_setFilter" :selectedFilterItemId="selectionItems.orderChallanStatus"></dialog-filter-items>

        <dialog-transaction-type-list ref="ref_dialogTransactionTypeList" :transactionTypeId="transactionTypeId" :setTransactionType="fn_setTransactionType" :selectedTypeFormId="selectionItems.typeFormId" :transactionTypeList="transactionTypeList" :hideNew="true" :clearAllBtn="true" :clearAll="fn_clearTransactionTypes"></dialog-transaction-type-list>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <dialog-month-filter ref="ref_dialogMonthFilter" :selectPeriod="fn_selectPeriod"></dialog-month-filter>
        <dialog-quarter-filter ref="ref_dialogQuarterFilter" :selectPeriod="fn_selectPeriod"></dialog-quarter-filter>

        <dialog-drive-alert ref="ref_dialogDriveAlert"></dialog-drive-alert>
 -->
        <v-navigation-drawer v-model="filter_nav" absolute right temporary>
          <transaction-type-list ref="ref_transactionTypeList" :transactionTypeId="transactionTypeId" :setTransactionType="fn_setTransactionType" :selectedTypeFormId="selectionItems.typeFormId" :transactionTypeList="transactionTypeList" :hideNew="true" :clearAllBtn="selectionItems.typeFormId ? true : false" :clearAll="fn_clearTransactionTypes" :closeFilterNav="fn_closeFilterNav" v-if="transactionTypeId == 20003"></transaction-type-list>

          <filter-items ref="ref_filterItems" title="Filter By" :filterItems="filerItems" :setFilter="fn_setFilter" :selectedFilterItemId="selectionItems.orderChallanStatus" :closeFilterNav="fn_closeFilterNav" v-if="transactionTypeId == 20001 || transactionTypeId == 20002"></filter-items>
        </v-navigation-drawer>

        <dialog-month-filter ref="ref_dialogMonthFilter" :selectPeriod="fn_selectPeriod"></dialog-month-filter>
        <dialog-quarter-filter ref="ref_dialogQuarterFilter" :selectPeriod="fn_selectPeriod"></dialog-quarter-filter>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <v-snackbar v-model="snackbar">
          File downloaded!
          <v-btn text color="primary" @click.native="$root.fn_openDownloadedFile">Open</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
// var tagId = null;
// const menuItem = require('../../../../config.js').configs.menuItem;
// const bottomSheetMenu = require('../../../../config.js').configs.bottomSheetMenu;
const transactionForms = require('../../../../config.js').configs.transactionForms;
const transactionTypes = require('../../../../config.js').configs.transactionTypes;

// const dialogjs =  require('../../../masters/forms/dialog').dialogjs;

// // import InfiniteLoading from 'vue-infinite-loading';
// import dialogFilterItems from '../../../dialogs/dialog_filter_items';
// import dialogTransactionTypeList from '../dialogs/dialog_transaction_type_list';

// import dialogDriveAlert from '../dialogs/dialog_drive_alert'
import dialogMonthFilter from '../../../dialogs/dialog_filter_months'
import dialogQuarterFilter from '../../../dialogs/dialog_filter_quarter'
import dialogPeriodSelection from '../../../dialogs/dialog_period_selection'
import dialogDatePicker from '../../../dialogs/dialog_datepicker';

import {exportExcelMixin} from '../../export_excel_mixin'

import filterItems from '../dialogs/filter_items';
import transactionTypeList from '../dialogs/transaction_type_list';

import {transactionMixin} from '../../transaction_mixin'

    export default{
        // props:['settings'],
        mixins: [exportExcelMixin, transactionMixin],
        // mixins: [transactionMixin],
        components: {
            // InfiniteLoading,
            // 'dialog-filter-items': dialogFilterItems,
            // 'dialog-transaction-type-list': dialogTransactionTypeList,
            // 'dialog-drive-alert':dialogDriveAlert,
            'dialog-period-selection':dialogPeriodSelection,
            'dialog-datepicker': dialogDatePicker,
            'dialog-month-filter': dialogMonthFilter,
            'dialog-quarter-filter': dialogQuarterFilter,
            'transaction-type-list':transactionTypeList,
            'filter-items':filterItems
        },
        data(){
            return{
                toolbarTitle:null,
                listItemObject:{"items":[], "totalCount":0, "totalAmount":null},
                searchValue:'',
                infiniteId: +new Date(),
                startIndex:0,
                scrollPositionTop:0,
                selectedItemId:null,
                filter_nav:false,
                filerItems:[
                    {"filterItemId":1, "name":"All", "value":null},
                    {"filterItemId":2, "name":"Pending", "value":'pending'},
                    {"filterItemId":3, "name":"Close", "value":'close'}
                ],
                // selectionItems.orderChallanStatus:1,
                isFilterApplicable:false,
                transactionTypeList:null,
                // selectionItems.typeFormId:null,
                selectionItems:{
                    typeFormId:null,
                    orderChallanStatus:1,
                    startDate:this.$root.minDateSelection,
                    endDate:this.$root.maxDateSelection,
                    selectedPeriod:"All Time"
                },
                loadMore:false,
                backButtonPress:false,
                dateType:null,
                selectedDate:null,
                snackbar:false,
                printData:null,
                printTemplate:null,
                showPlaceHolder:false,
                formId:null,
                dialogStorageId:null,
                dateMenu:false,
                transactionTypeId:null
            }
        },
        methods:{

            fn_openFilterNav: function(){
                this.filter_nav = true;
                this.$refs['ref_transactionTypeList'] ? this.$refs['ref_transactionTypeList'].fn_setCurrentFilterValues() : '';
            },

            fn_closeFilterNav: function(){
                this.filter_nav = false;
            },

            fn_preview: function(item, isPrint){
                item.toolbarTitle = this.toolbarTitle;
                let printType = null;
                this.fn_previewInvoice(true, 'purchase', printType, item, isPrint);
            },

            fn_bindInvoiceTemplate: function(type, item){

                this.printTemplate = null;
                this.printData = null;

                this.printTemplate = require('../../../invoice_templates/invoices/template'+ item.invoiceTemplate).default;
                console.log(this.printTemplate);

                let dataFnName = 'InvoiceData';

                if(type=='sharewhatsapp'){
                  this.fn_shareInvoice('sharewhatsapp', item, dataFnName);
                }
                else if(type=='print'){
                  this.fn_getPrintData(dataFnName, item.transactionId, 'print', null, item.mergeTransactionNumber, item.transactionDate, item.transactionListId);
                }
                else if(type=='pdf'){
                  this.fn_getPrintData(dataFnName, item.transactionId, 'pdf', null, item.mergeTransactionNumber, item.transactionDate, item.transactionListId);
                }
                else if(type=='share'){
                  this.fn_shareInvoice('share', item, dataFnName);
                }
            },

            fn_shareInvoice: function(type, item, dataFnName){
                let shareObj = {};
                shareObj.shareType = type=='sharewhatsapp' ? 'share' : 'ShareOther';
                shareObj.type = 'pdf';
                shareObj.primaryMobileNumber = item.primaryMobileNumber && type == 'sharewhatsapp' ? item.primaryMobileNumber : null;
                shareObj.subject = this.toolbarTitle + " Details";
                shareObj.email = item.email ? [item.email] : null ;

                let party = item.accountLedgerName ? item.accountLedgerName + ',' : '';

                shareObj.textMessage = "Dear "+party+"\n\nBelow are your " + this.toolbarTitle + " details. Please find the attached pdf.\n\nTransaction Date: "+this.$moment(item.transactionDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(item.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;
                
                // shareObj.textMessage = "Dear "+item.accountLedgerName+",\n\nBelow are your invoice details. Please find the attached pdf of your invoice.\n\nInvoice Date: "+this.$moment(item.createdDate).format('DD-MMM-YYYY')+"\nTotal Amount: ₹ "+this.$root.fixed2Decimal(item.roundedAmount)+"\n\nThank You,\n"+this.$root.CurrentBusiness.BusinessName;
                this.fn_getPrintData(dataFnName, item.transactionId, 'pdf', shareObj, item.mergeTransactionNumber, null, item.transactionListId);
            },

            onScroll (event) {
                var element = event.target;
                if (element.scrollHeight - element.scrollTop - 150 <= element.clientHeight)
                {
                    console.log('scrolled');
                    if(this.listItemObject.items.length < this.listItemObject.totalCount){
                      this.fn_loadItems();
                    }
                }
            },

            fn_setFilter: function(filterItemId){
                this.selectionItems.orderChallanStatus = filterItemId;
                // localStorage.setItem('transaction_filter', JSON.stringify(this.selectionItems));
                // this.fn_closeDialog();
                this.fn_closeFilterNav();
                this.fn_searchItem();
            },
            fn_openFilter: function(){
                this.dateMenu = false;
                if(this.transactionTypeId == transactionTypes.purchaseOrder.id || this.transactionTypeId == transactionTypes.purchaseChallan.id){
                    this.fn_openDialogFilterItems();
                }
                else{
                    this.fn_openTransactionTypeList();
                }
            },
            fn_openDialogFilterItems: function(){
                this.fn_openDialogWeb('web', 'FilterItems', 'ref_dialogFilterItems');
                // this.$refs['ref_dialogFilterItems'].openDialogWeb();
            },
            fn_openTransactionTypeList: function () {
                this.fn_openDialogWeb('web', 'TransactionTypeList', 'ref_dialogTransactionTypeList');
                // this.$refs['ref_dialogTransactionTypeList'].dialog_transactionTypeList = true;
            },

            fn_closeDialog: function(){
                this.$refs['ref_dialogFilterItems'].dialog_filter_items = false;
                this.$refs['ref_dialogTransactionTypeList'].dialog_transactionTypeList = false;
                this.$refs['ref_dialogDatePicker'].datepicker = false;
                this.$refs['ref_dialogQuarterFilter'].dialog_filter_quarter = false;
                this.$refs['ref_dialogMonthFilter'].dialog_filter_month = false;
            },
            fn_getTransactionTypeList: function () {
                let filterObject = {};
                filterObject.type = this.transactionTypeId;
                filterObject.accountGroupsHidden = [2];
                // filterObject.inventory = [0, 1];

                let self = this;
                this.getItems("TransactionTypeList", filterObject, 
                  {
                    onSuccess: function (response) {
                        console.log(response);
                        self.transactionTypeList = JSON.parse(response);
                    },
                    onError: function (error) {
                      console.log(error);
                    }
                  }
                )
            },
            fn_setTransactionType: function(item){
                this.selectionItems.typeFormId = item.transactionTypeFormId;
                // localStorage.setItem('transaction_filter', JSON.stringify(this.selectionItems));
                // this.fn_closeDialog();
                this.fn_closeFilterNav();
                this.fn_searchItem();
            },
            fn_clearTransactionTypes: function(){
                this.selectionItems.typeFormId = null;
                // localStorage.setItem('transaction_filter', JSON.stringify(this.selectionItems));
                // this.fn_closeDialog();
                this.fn_closeFilterNav();
                this.fn_searchItem();
            },
            fn_drafts: function(){
                this.$router.push({path:'/drafts', query:{tabIndex:1}});
            },
            fn_initializeSettings: function () {
                this.transactionTypeId = this.$route.query.transactionTypeId;
                transactionForms.filter(item => {
                    if(item.id == this.transactionTypeId){
                        this.toolbarTitle = item.title;
                    }
                })
            },
            fn_goBack: function(){
                this.backButtonPress = true;
                this.$router.go(-1);
                // this.$router.replace("/"+this.$root.CurrentBusiness.BusinessID+"/transaction_items");
            },
            // fn_menuItemClick: function(tagId, menuId, menuAction){
            //  // tagId id form future use
            //  // menuId id form future use
            //  if(menuAction=='new'){
            //      // this.fn_new();
            //  }
            //  else if(menuAction=='edit'){
            //      this.$router.push('/transactions/purchase/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+this.selectedItemId+'/edit');
            //  }
            //  else if(menuAction=='copy'){
            //      this.$router.push({path:"/transactions/purchase/"+this.toolbarTitle.toLowerCase().replace(/ /g,'')+"/items/new", query:{tagId:this.guidWeb(), itemId:this.selectedItemId, copy:true}});
            //  }
            //  else if(menuAction=='draft'){
            //      // this.$router.push({path:'/drafts', query:{category:this.toolbarTitle}});
            //  }
            // },

            // fn_checkDrive: function(){
         //        let self = this;
         //        if(this.$root.CurrentBusiness.DataDrive){
         //          this.fn_new();
         //        }
         //        else{
         //          this.$root.fn_getTransactionCount(function(count){
         //            if(count>=10){
         //              self.$refs['ref_dialogDriveAlert'].dialog_setup_drive = true;
         //            }
         //            else{
         //              self.fn_new();
         //            }
         //          })
         //        }
         //    },

            fn_new: function () {
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/new', query:{tagId:this.guidWeb()}});
            },

            fn_itemClick: function (itemId, index) {
                let obj = {};
                obj.itemId = null;
                obj.lastScrollPos = window.pageYOffset;
                obj.itemIndex = index + 1;
                localStorage.setItem("transaction_items_state", JSON.stringify(obj));
                // tagId id form future use
                // itemName id form future use
                // this.$router.push('/transactions/purchase/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+itemId);
                this.$router.push({path:'/'+this.$root.CurrentBusiness.BusinessID+'/'+this.$route.params.listId+'/'+this.toolbarTitle.toLowerCase().replace(/ /g,'')+'/items/'+itemId, query:{transactionTypeId:this.transactionTypeId}});
            },

            // fn_getBottomMenu: function (elementId, itemId) {
            //  itemId ? this.selectedItemId = itemId : '';

            //  let bottomMenu;

            //  // if(elementId == bottomSheetMenu.toolBar.Id){
            //  //  bottomMenu = {"BottomMenu": [menuItem.help]};
            //  // }
            //  if(elementId == bottomSheetMenu.masterListItems.Id){
            //      bottomMenu = {"BottomMenu": [menuItem.edit]};
            //  }
                
            //  this.setBottomMenu(tagId, bottomMenu, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
            // },

            // infiniteHandler($state) {
         //     let filterObject = {};
            //  filterObject.type = this.transactionTypeId;
            //  filterObject.limit = 10;
            //  filterObject.startIndex = this.startIndex;
            //  filterObject.search = this.searchValue.toLowerCase();

            //  // filterObject.itemFilter = {"open":false, "close":false, "all":false};
            //  filterObject.itemFilter = {};
            //  if(this.transactionTypeId == transactionTypes.purchaseOrder.Id || this.transactionTypeId == transactionTypes.purchaseChallan.Id){
            //      this.selectionItems.orderChallanStatus == 1 ? filterObject.itemFilter.all = true : this.selectionItems.orderChallanStatus == 2 ? filterObject.itemFilter.open = true : filterObject.itemFilter.close = true;
            //  }
            //  this.selectionItems.typeFormId ? filterObject.itemFilter.transactionTypeForms = [this.selectionItems.typeFormId] : '';
            //  alert('out')
            //  let self = this;
            //  this.getItems("AccountBookList", filterObject, 
            //      {
            //          onSuccess: function (response) {
            //              console.log(response);
            //              let responseJson = JSON.parse(response);
            //              self.listItemObject.totalCount = responseJson.totalCount;

            //              for(let i in responseJson.items){
            //                  self.listItemObject.items.push(responseJson.items[i]);  
            //              }

            //              self.startIndex = self.listItemObject.items.length;

            //              if(self.listItemObject.items.length < self.listItemObject.totalCount){
            //                  $state.loaded();
            //                  alert('loaded')
            //              }
            //              else {
   //                               $state.complete();
   //                               alert('complete')
   //                       }
            //          },
            //          onError: function (error) {
            //              console.log(error);
            //          }
            //      }
            //  )
         //    },

            // fn_searchItem: function () {
            //  this.startIndex = 0;
            //  this.infiniteId += 1;
            //  this.listItemObject.items = [];
            // },

            fn_loadItems: function(search) {
                this.loadMore = true;
                let filterObject = {};
                filterObject.type = this.transactionTypeId;
                filterObject.limit = 50;
                filterObject.startIndex = this.startIndex;
                filterObject.search = this.searchValue ? this.searchValue.toLowerCase() : '';
                // filterObject.startDate = new Date(this.selectionItems.startDate);
                filterObject.startDate = this.$moment(this.selectionItems.startDate).startOf('day');
                filterObject.endDate = this.$moment(this.selectionItems.endDate).endOf('day');

                filterObject.startDate = this.$root.fn_getLocalDateFormat(filterObject.startDate);
                filterObject.endDate = this.$root.fn_getLocalDateFormat(filterObject.endDate);

                //formTransactionListId will use in case of delivery challan or order to check pending or complete items
                if(this.transactionTypeId == transactionTypes.purchaseOrder.id){
                    filterObject.formTransactionListId = [transactionTypes.purchaseChallan.id, transactionTypes.purchaseInvoice.id]
                }
                else if(this.transactionTypeId == transactionTypes.purchaseChallan.id){
                    filterObject.formTransactionListId = [transactionTypes.purchaseInvoice.id]
                }

                filterObject.itemFilter = {};
                if(this.transactionTypeId == transactionTypes.purchaseOrder.id || this.transactionTypeId == transactionTypes.purchaseChallan.id){
                    this.selectionItems.orderChallanStatus == 1 ? filterObject.itemFilter.all = true : this.selectionItems.orderChallanStatus == 2 ? filterObject.itemFilter.open = true : filterObject.itemFilter.close = true;
                }
                this.selectionItems.typeFormId ? filterObject.itemFilter.transactionTypeForms = [this.selectionItems.typeFormId] : '';

                let pageState = null;
                if(localStorage.getItem("transaction_items_state")){
                    pageState = JSON.parse(localStorage.getItem("transaction_items_state"));
                    filterObject.limit = 50 - (pageState.itemIndex % 50) + pageState.itemIndex;
                }

                this.fn_loadTimeOut();

                let self = this;
                this.getItems("AccountBookList", filterObject, 
                    {
                        onSuccess: function (response) {
                            self.loadTimeOut = false;
                            console.log(response);
                            let responseJson = JSON.parse(response);
                            self.listItemObject.totalCount = responseJson.totalCount;
                            self.listItemObject.totalAmount = responseJson.totalAmount;

                            search ? self.listItemObject.items = [] : '';

                            let itemFound = false;
                            for(let i in responseJson.items){
                                if(pageState && pageState.itemId){
                                    if(responseJson.items[i].transactionId == pageState.itemId){
                                        itemFound = true;
                                    }
                                    // else{
                                    //  itemFound = false;
                                    // }
                                }
                                // else{
                                //  itemFound = true;
                                // }

                                let itemIndex = self.listItemObject.items.findIndex(item => item.transactionId==responseJson.items[i].transactionId);

                                if(itemIndex==-1){
                                    self.listItemObject.items.push(responseJson.items[i]);  
                                }

                                // self.listItemObject.items.push(responseJson.items[i]);  
                            }

                            console.log(pageState);
                            if(pageState && pageState.itemId){
                                setTimeout(function(){
                                    if(itemFound){
                                        let scrollOptions = {"duration":0, "offset":-112, "easing":"linear"};
                                        self.$vuetify.goTo('#item_'+pageState.itemId, scrollOptions);
                                        localStorage.removeItem("transaction_items_state");
                                    }
                                    else{
                                        self.$vuetify.goTo(99999, self.scrollOptions);
                                    }
                                },100);
                            }

                            if(pageState && !pageState.itemId){
                                setTimeout(function(){
                                    let scrollOptions = {"duration":300, "offset":-112, "easing":"linear"};
                                    self.$vuetify.goTo(pageState.lastScrollPos, scrollOptions);
                                    localStorage.removeItem("transaction_items_state");
                                },100);
                            }

                            self.startIndex = self.listItemObject.items.length;
                            self.loadMore = false;

                            setTimeout(function(){
                                self.showPlaceHolder = true;
                            },300);
                        },
                        onError: function (error) {
                            console.log(error);
                            self.loadMore = false;
                            self.loadTimeOut = false;
                        }
                    }
                )
            },


            fn_searchItem: function () {
                this.startIndex = 0;
                this.listItemObject.items = [];
                this.fn_loadItems(true);
            },

      //        fn_openDialogWeb: function (platform, name, ref) {

            //  let scroll_y = window.scrollY;
            //  this.fn_fixBackgroundScroll();

            //  var tagId = this.guidWeb();

            //  var dialogObj = {};
            //  dialogObj.tagId = tagId;
            //  dialogObj.formId = this.formId;
            //  dialogObj.name = name;
            //  dialogObj.ref = ref;
            //  dialogObj.data = null;
            //  dialogObj.platform = platform;

            //  dialogObj.scrollPosition = {};
            //  dialogObj.scrollPosition.x = 0;
            //  dialogObj.scrollPosition.y = scroll_y;

            //  dialogjs.fn_pushDialogInStack(this.dialogStorageId, dialogObj, this);

            //  let self = this;

            //  this.$refs[ref].openDialogWeb();
            // },

            // fn_closeDialog: function (tagId) {
            //  dialogjs.fn_checkAndCloseOpenDialogs(this);
            // },

            // fn_showWebView: function () {
            //  let viewObject = {};
            //  let self = this;

            //  this.showWebView(tagId, viewObject, 
            //      {
            //          onSuccess: function(response){
            //            console.log(response);
            //          }, 
            //          onError: function(error){
            //            console.log(error);
            //          }, 
            //          // onChange: null, 
            //          onMenuItemClick: function (tagId, menuId, menuAction) {
            //              self.fn_menuItemClick(tagId, menuId, menuAction)
            //          }
            //      }
            //  );
            // },
        },
        mounted: function(){

            // tagId = this.$route.query.tagId;

            // this.formId = this.$route.query.tagId;
            // this.dialogStorageId = "dialog_"+this.formId;

            this.fn_initializeSettings();

            // this.fn_showWebView();
            // window.js_globals.fn_onBackPress = this.fn_goBack;

            let self = this;
            document.addEventListener('scroll', function () {
                self.scrollPositionTop = window.pageYOffset
            })

            if(this.transactionTypeId == transactionTypes.purchaseOrder.id || this.transactionTypeId == transactionTypes.purchaseChallan.id || this.transactionTypeId == transactionTypes.purchaseInvoice.id){
                this.isFilterApplicable = true;
                this.transactionTypeId == transactionTypes.purchaseInvoice.id ? this.fn_getTransactionTypeList() : '';
            }

            // if(localStorage.getItem('transaction_filter')){
            //     let data = JSON.parse(localStorage.getItem('transaction_filter'));
            //     this.selectionItems.typeFormId = data.typeFormId;
            //     this.selectionItems.orderChallanStatus = data.orderChallanStatus;
            //     this.selectionItems.startDate = new Date(data.startDate);
            //     this.selectionItems.endDate = new Date(data.endDate);
            //     this.selectionItems.selectedPeriod = data.selectedPeriod;
            // }

            this.fn_loadItems();

            this.$eventHub.$on('UpdatePurchaseList', dataObj => {
                console.log(dataObj);
                this.transactionTypeId = dataObj.transactionTypeId;
                this.fn_searchItem();
            });

            // this.myworker.addEventListener('message', event => {
      //           self.fn_breakDataIntoChunks(event);
      //       });
        },
        beforeRouteEnter(to, from, next){
            next(vm => {
                console.log(vm);
                if(from.name == 'actions'){
                    localStorage.removeItem("transaction_items_state");
                }   
            });
        },
        // beforeRouteLeave(to, from, next){
        //  var dialogData = JSON.parse(localStorage.getItem(this.dialogStorageId));
        //  if(dialogData && dialogData.length){

        //      if(this.backButtonPress){
        //          dialogjs.fn_checkAndCloseOpenDialogs(this);
        //          next(false);
        //      }
        //      else{
        //          next(true);
        //      }
        //  }
        //  else{
        //      this.backButtonPress ? localStorage.removeItem('transaction_filter') : '';
        //      next(true);
        //  }
        // }
    }
</script>