<template>
    <div>
        <v-dialog v-model="dialog_returnForm" no-click-animation persistent scrollable content-class="half-screen-dialog" lazy transition="dialog-bottom-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card flat>

                <v-toolbar flat fixed>
                  <v-btn icon @click="closeDialogWeb"><v-icon>close</v-icon></v-btn>
                  <v-toolbar-title>Items</v-toolbar-title>
                  <!-- <v-spacer></v-spacer>
                  <v-btn outlined color="primary" @click="fn_appendItems">Done</v-btn> -->
                  <v-text-field grey-back clearable flat solo hide-details placeholder="Search..." v-model="searchValue" slot="extension"></v-text-field>
                </v-toolbar>

                <v-card-text full-height nopadding id="scrollable_div">

                    <v-form data-vv-scope="fields" full-width @submit.prevent="submit">

                        <ValidationObserver ref="observer">
                        
                            <v-list nopadding auto-height-tile>

                                <div v-for="(item, index) in filteredItems" :key="index" :id="'item_'+item.groupId">

                                    <div font16 class="pt-2 pl-4 pr-4">{{item.productName}}</div>

                                    <template v-for="(variant, vIndex) in item.productVariants">

                                        <v-layout row wrap ma-0 :key="vIndex">
                                            <v-flex xs9 sm9 md9 class="pl-4 pr-4 pt-2 pb-2">
                                                <div font14 light-opacity-color v-if="variant.qty">
                                                    
                                                    <template v-if="variant.secondaryUnitId">
                                                        <span v-if="item.batches && item.batches.length && fn_batchHasSeries(item.batches)">
                                                            {{roundUpto2Decimals(variant.qty)}} {{ variant.primaryUnitName }} <span>X {{item.rate}}</span>
                                                        </span>
                                                        <v-menu offset-y v-else>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <span v-bind="attrs" v-on="on">{{roundUpto2Decimals(variant.qty)}} {{variant.selectedUnit && variant.selectedUnit.itemName ? variant.selectedUnit.itemName : variant.unitName}} <v-icon font18>arrow_drop_down</v-icon></span>
                                                                <span>X {{item.rate}}</span>
                                                            </template>
                                                            <v-list densed nopadding>
                                                                <v-list-item @click="fn_selectItemUnit(variant, item, 'primary')">
                                                                    <v-list-item-title>{{ variant.primaryUnitName }}</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item @click="fn_selectItemUnit(variant, item, 'secondary')">
                                                                    <v-list-item-title>{{ variant.secondaryUnitName }}</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </template>
                                                    

                                                    <template v-else>
                                                        {{variant.qty}} {{variant.unitName}} X {{item.rate}}
                                                    </template>
                                                    <!-- <span v-if="variant.packQuantity">({{variant.packQuantity}})</span> X {{item.rate}} -->
                                                </div>    
                                            </v-flex>

                                            <v-flex xs3 sm3 md3 pr-4>

                                                <ValidationProvider :name="'returnQty'+(index+vIndex)" :rules="'max_value:'+variant.qty" v-slot="{ errors }">
                                                    <!-- <v-text-field 
                                                        class="pa-0 ma-0"
                                                        align-input-text-right
                                                        hide-details
                                                        v-model.number="variant.returnQty"
                                                        label=""
                                                        placeholder="Rqty" 
                                                        type="number" 
                                                        @input="fn_validateInput(variant)"
                                                        :error-messages="errors">
                                                    </v-text-field> -->
                                                    <v-text-field 
                                                        class="pa-0 ma-0 right-align"
                                                        align-input-text-right
                                                        :label="item.batches && item.batches.length ? 'Return Qty' : ''"
                                                        :disabled="item.batches && item.batches.length ? true : false"
                                                        :hide-details="true"
                                                        @input="$root.fn_boundDecimalLength(variant, 'returnQty', variant, fn_validateInput)"
                                                        v-model="variant.returnQty"
                                                        placeholder="Rqty" 
                                                        type="number" 
                                                        :error-messages="errors">
                                                    </v-text-field>

                                                    <!-- <div style="font-size:12px;padding:2px;color:#ff5252">{{errors[0]}}</div> -->
                                                    <div style="font-size:12px;padding:2px;color:#ff5252" v-if="errors && errors.length">This field must not be greater than bill quantity</div>
                                                </ValidationProvider>

                                            </v-flex>
                                        </v-layout>
                                    </template>



                                    <!-- <v-list-item list-checkbox class="right-subtitle-tile" @click="fn_changeSelection(item)">
                                        <v-list-item-content>
                                          <v-list-item-title class="mb-1">{{item.productName}}</v-list-item-title>

                                          <template v-for="(variant, vIndex) in item.productVariants">
                                                <v-list-item-subtitle class="mb-2" :key="vIndex" v-if="variant.qty">

                                                    <v-layout row wrap layout-wrap align-flex-end>
                                                      <v-flex xs9 sm9 md9>
                                                        {{variant.qty}} {{variant.unitName}}
                                                        <span v-if="variant.packQuantity">({{variant.packQuantity}})</span> X {{item.rate}}
                                                      </v-flex>

                                                      <v-flex xs3 sm3 md3>

                                                        <ValidationProvider :name="'retrunQty'+index+vIndex" rules="numeric|min_value:1|max_value:'+variant.qty" v-slot="{ errors }">
                                                            <v-text-field 
                                                                align-input-text-right
                                                                hide-details
                                                                 
                                                                v-model.number="variant.returnQty"
                                                                label=""
                                                                placeholder="Rqty" 
                                                                type="number" 
                                                                @input="fn_validateInput(variant)"
                                                                required
                                                                :error-messages="errors">

                                                            </v-text-field>

                                                            <div style="font-size:12px;padding:2px;color:#ff5252">{{errors[0]}}</div>
                                                        </ValidationProvider>

                                                      </v-flex>

                                                    </v-layout>
                                                    
                                                </v-list-item-subtitle>
                                          </template>
                                          

                                        </v-list-item-content>
                                            

                                    </v-list-item> -->

                                    <!-- <div style="padding:5px 16px;background-color:#ddd" v-if="item.isValid == false">Return quantity should be less than or equal to bill quantity.</div>   -->
                                    
                                    <template v-if="item.batches && item.batches.length">
                                        <v-card flat v-for="(batch, batchIndex) in item.batches" :key="batchIndex">
                                            <v-divider></v-divider>
                                            <v-card-text class="pb-3">
                                                <div style="float: left;margin-right: 12px;" v-if="item.selectedBatchType == 2">
                                                    <v-checkbox hide-details class="ma-0" v-model="batch.selected" @change="fn_autoFillQty(item)"></v-checkbox>
                                                </div>

                                                <v-layout ma-0 row wrap>
                                                    <v-flex xs9 sm9 md9>
                                                        <v-layout ma-0 class="batch_layout" row wrap>
                                                            <!-- <v-flex xs2 sm2 md2 v-if="item.selectedBatchType == 2">
                                                                <v-checkbox hide-details class="ma-0" v-model="batch.selected" @change="fn_autoFillQty(item)"></v-checkbox>
                                                            </v-flex> -->
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchNumber">
                                                                <div light-opacity-color>Batch No.</div>
                                                                <div>{{ batch.batchNumber }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchSize">
                                                                <div light-opacity-color>Size</div>
                                                                <div>{{ batch.batchSize }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchMRP">
                                                                <div light-opacity-color>MRP</div>
                                                                <div>{{ batch.batchMRP }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.mfgDate">
                                                                <div light-opacity-color>Mfg. Date</div>
                                                                <div v-if="batch.mfgDate">{{ batch.mfgDate | moment('DD-MMM-YYYY') }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.expDate">
                                                                <div light-opacity-color>Exp. Date</div>
                                                                <div v-if="batch.expDate">{{ batch.expDate | moment('DD-MMM-YYYY') }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.purchaseRate">
                                                                <div light-opacity-color>Purchase Rate</div>
                                                                <div>{{ batch.purchaseRate }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.field1">
                                                                <div light-opacity-color>Serial Number</div>
                                                                <div>{{ batch.field1 }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.field2">
                                                                <div light-opacity-color>IMEI Number</div>
                                                                <div>{{ batch.field2 }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.field3">
                                                                <div light-opacity-color>Part Number</div>
                                                                <div>{{ batch.field3 }}</div>
                                                            </v-flex>
                                                            <v-flex xs3 sm3 md3 mb-2 v-if="batch.field4">
                                                                <div light-opacity-color>Model Number</div>
                                                                <div>{{ batch.field4 }}</div>
                                                            </v-flex>
                                                        </v-layout>        
                                                    </v-flex>

                                                    <v-flex xs3 sm3 md3>
                                                        <v-layout ma-0 row wrap mt-3 v-if="item.selectedBatchType == 1">
                                                            <ValidationProvider name="qty" :rules="'min_value:0'" v-slot="{ errors }">
                                                                <v-flex xs12 sm12 md12>
                                                                    <v-text-field
                                                                        outlined
                                                                        dense
                                                                        v-model.number="batch.productQty"
                                                                        :error="batch.productQty > batch.billQty"
                                                                        @input="fn_changeBatchQty(item, batch)"
                                                                        type="number"
                                                                        placeholder="Quantity"
                                                                        :error-messages="errors"
                                                                        :hide-details="!errors || !errors.length"
                                                                    ></v-text-field>
                                                                    <!-- <div font12 light-opacity-color right-align v-if="batch.billQty != undefined">{{ 'Bill Qty: ' + roundUpto2Decimals(batch.billQty) }} {{ item.productVariants[0].selectedUnit ? item.productVariants[0].selectedUnit.itemName : item.productVariants[0].unitName }}</div> -->
                                                                </v-flex>
                                                            </ValidationProvider>

                                                            <v-flex xs12 sm12 md12 pt-1 pb-1 pr-2 right-align :class="{'error_background redcolor':batch.currentStock<0}">
                                                                Current Stock: {{ batch.currentStock ? roundUpto2Decimals(batch.currentStock) : 0 }} {{ item.productVariants[0].primaryUnitName }}
                                                            </v-flex>

                                                            <v-flex xs12 sm12 md12 redcolor pb-2 v-if="batch.expDate && $parent.fn_checkDate(batch)">
                                                                <v-icon class="mr-1 font16 redcolor">info_outline</v-icon> Expired {{ $parent.fn_getDate(batch) }}
                                                            </v-flex>
                                                        </v-layout>        
                                                    </v-flex>
                                                </v-layout>
                                                
                                            </v-card-text>
                                        </v-card>
                                        
                                    </template>
                                    <v-divider v-if="filteredItems.length-1 != index"></v-divider>
                                </div>

                            </v-list>

                        </ValidationObserver>

                    </v-form>

                    
                        

                    <div class="center-align pa-4" v-if="!filteredItems.length">No Items</div>
                </v-card-text>

                <v-card-actions>
                    <v-layout row wrap ma-0>
                        <v-flex height48 xs12 sm12 md12>
                            <v-btn color="primary" full-height nomargin block no-round-borders noshadow @click="fn_appendItems">Done</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-actions>
            </v-card>


        </v-dialog>
    </div>
</template>

<script>
import { numeric, min_value, max_value } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

extend('min_value', min_value)
extend('max_value', max_value)
extend('numeric', numeric)

    export default{
        props:['items', 'type', 'addPendingItems'],
        data(){
            return{
                ordersList:[],
                dialog_returnForm:false,
                searchValue:'',
            }
        },
        components:{
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.searchValue = '';
                this.dialog_returnForm = true;
            },
            closeDialogWeb: function () {
                this.dialog_returnForm = false;
            },
            fn_changeSelection: function (item) {
                let transactionProducts = this.items[0].transactionProducts;
                for (var i = 0; i<transactionProducts.length; i++) {
                    if(transactionProducts[i].groupId == item.groupId){
                        transactionProducts[i].selected = !transactionProducts[i].selected;
                        this.$forceUpdate();
                        break;
                    }
                }
            },
            fn_appendItems: function () {

                this.$refs.observer.validate().then((result) => {
                    if (result) {
                        
                        let items = [];
                        // let error = 0;

                        let transactionProducts = JSON.parse(JSON.stringify(this.items[0].transactionProducts));

                        for(let i=0; i<transactionProducts.length; i++){

                            // this.items[0].transactionProducts[i].isValid = true;

                            for(let j=0; j<transactionProducts[i].productVariants.length; j++){

                                //assigning bill qty
                                transactionProducts[i].productVariants[j].billQty = transactionProducts[i].productVariants[j].qty;

                                //checking if return qty is greater then bill qty
                                // if(transactionProducts[i].productVariants[j].returnQty > transactionProducts[i].productVariants[j].qty || transactionProducts[i].productVariants[j].returnQty < 0){

                                //  this.onScroll(transactionProducts[i].productVariants[j].groupId);
                                //  error = 1;
                                //  break;
                                // }
                                // else{
                                transactionProducts[i].productVariants[j].qty = transactionProducts[i].productVariants[j].returnQty;
                                // }


                                let itemIndex = items.findIndex(item=>{
                                    return item.groupId == transactionProducts[i].groupId;
                                })

                                if(Number(transactionProducts[i].productVariants[j].returnQty) && itemIndex==-1){
                                    items.push(transactionProducts[i]);
                                }
                            }

                            // if(error){
                            //  this.items[0].transactionProducts[i].isValid = false;
                            //  this.$forceUpdate();
                            //  return;
                            // }

                        }

                        if(items.length){
                            this.addPendingItems(items, this.items[0].transactionAddOns, this.type);
                        }
                        else{
                            // this.$parent.snackbar_msg = 'Return quantity not filled.';
                            // this.$parent.snackbar = true;
                            // alert("Return quantity not filled");
                            this.$eventHub.$emit('snackbarAlert', {msg:'Return quantity not filled'});
                        }

                    }
                    else{
                        this.onScroll()
                    }
                });

                // let items = [];
                // let error = 0;

                // let transactionProducts = JSON.parse(JSON.stringify(this.items[0].transactionProducts));

                // for(let i=0; i<transactionProducts.length; i++){

                //  this.items[0].transactionProducts[i].isValid = true;

                //  for(let j=0; j<transactionProducts[i].productVariants.length; j++){

                //      //assigning bill qty
                //      transactionProducts[i].productVariants[j].billQty = transactionProducts[i].productVariants[j].qty;

                //      //checking if return qty is greater then bill qty
                //      if(transactionProducts[i].productVariants[j].returnQty > transactionProducts[i].productVariants[j].qty || transactionProducts[i].productVariants[j].returnQty < 0){

                //          this.onScroll(transactionProducts[i].productVariants[j].groupId);
                //          error = 1;
                //          break;
                //      }
                //      else{
                //          transactionProducts[i].productVariants[j].qty = transactionProducts[i].productVariants[j].returnQty;
                //      }


                //      let itemIndex = items.findIndex(item=>{
                //          return item.groupId == transactionProducts[i].groupId;
                //      })

                //      if(transactionProducts[i].productVariants[j].returnQty && itemIndex==-1){
                //          items.push(transactionProducts[i]);
                //      }
                //  }

                //  if(error){
                //      this.items[0].transactionProducts[i].isValid = false;
                //      this.$forceUpdate();
                //      return;
                //  }

                // }

                // if(items.length && !error){
                //  this.addPendingItems(items, this.items[0].transactionAddOns, this.type);
                // }
                // else{
                //  this.$parent.snackbar_msg = 'Return quantity not filled.';
                //  this.$parent.snackbar = true;
                // }
            },

            onScroll: function () {
                let scrollable_div = document.getElementById('scrollable_div');
                let target_Elements = document.getElementsByClassName('error--text');
                // let target_Element = document.getElementById('item_'+groupId);
                scrollable_div.scrollTop = target_Elements[0].offsetTop - 100;

                // this.$parent.snackbar_msg = 'Return quantity should be less then or equal to bill quantity';
                // this.$parent.snackbar = true;
                // alert("Return quantity should be less then or equal to bill quantity");
                this.$eventHub.$emit('snackbarAlert', {msg:'Return quantity should be less then or equal to bill quantity'});
            },

            fn_validateInput: function (variant) {
                if(variant.returnQty > variant.qty || variant.returnQty < 0){
                    // this.$parent.snackbar_msg = 'Return quantity should be less then or equal to bill quantity';
                    // this.$parent.snackbar = true;
                    // alert("Return quantity should be less then or equal to bill quantity");
                    this.$eventHub.$emit('snackbarAlert', {msg:'Return quantity should be less then or equal to bill quantity'});
                }
            },

            fn_selectItemUnit: function(vItem, item, type){
              console.log('ssd');
              !vItem.selectedUnit ? vItem.selectedUnit = {} : '';
              
              if(type=='primary'){
                if(vItem.selectedUnit.itemId != vItem.primaryUnitId){
                  vItem.selectedUnit.itemId = vItem.primaryUnitId;
                  vItem.selectedUnit.itemName = vItem.primaryUnitName;
                  this.fn_unitChange(vItem.selectedUnit, vItem, item);
                }
              }
              else{
                if(vItem.selectedUnit.itemId != vItem.secondaryUnitId){
                  vItem.selectedUnit.itemId = vItem.secondaryUnitId;
                  vItem.selectedUnit.itemName = vItem.secondaryUnitName;
                  this.fn_unitChange(vItem.selectedUnit, vItem, item);
                }
              }
            },

            fn_unitChange: function(value, item, product){
              // let currentItemId = this.$refs.ref_unitSelection[0]._data.selectedItems[0].itemId;
              // if(currentItemId != value.itemId){
                
              // }
              console.log('ssd');
              if(value.itemId == item.secondaryUnitId){
                product.rate = (product.rate * item.packQuantity) / item.secondaryUnitPackQuantity;
              }
              else{
                product.rate = (product.rate * item.secondaryUnitPackQuantity) / item.packQuantity;
                // product.rate = this.roundUpto2Decimals(product.rate * item.secondaryUnitPackQuantity);
              }
              product.rate = this.roundUpto2Decimals(product.rate); 

              this.fn_checkAndUpdateBillQty(value, item, product);
              // this.fn_checkInput(item);
            },

            fn_checkAndUpdateBillQty: function(value, item, product){
              if(item.secondaryUnitId){
                if(value.itemId == item.secondaryUnitId){
                    item.qty = Math.round(this.roundUpto2Decimals((item.qty * item.secondaryUnitPackQuantity) / item.packQuantity));
                    if(product.batches && product.batches.length){
                        for(let i=0; i<product.batches.length; i++){
                            if(product.batches[i].billQty!=undefined){
                                product.batches[i].billQty = Math.round(this.roundUpto2Decimals((product.batches[i].billQty * item.secondaryUnitPackQuantity) / item.packQuantity));
                            }
                        }
                    }
                }
                else{
                    item.qty = (item.qty * item.packQuantity) / item.secondaryUnitPackQuantity;
                    if(product.batches && product.batches.length){
						for(let i=0; i<product.batches.length; i++){
							if(product.batches[i].billQty!=undefined){
								product.batches[i].billQty = this.roundUpto2Decimals((product.batches[i].billQty * item.packQuantity) / item.secondaryUnitPackQuantity);
							}
						}
					}
                }
              }
            },

            fn_changeBatchQty: function(item, batch){
				if(item.batches && item.batches.length){
					let totalVal = 0;
					for(let i=0; i<item.batches.length; i++){
						item.batches[i].qty = item.batches[i].productQty;
						totalVal = totalVal + Number(item.batches[i].productQty);
					}
					this.$set(item.productVariants[0], 'returnQty', Number(totalVal));
				}

				if(batch.productQty > batch.billQty){
					this.showToast("Batch quantity should be less then or equal to bill quantity");
				}
			},

			fn_autoFillQty: function(item){
				let totalQty = 0;
				for(let i=0; i<item.batches.length; i++){
					if(item.batches[i].selected){
						item.batches[i].productQty = 1;
						totalQty = totalQty + Number(item.batches[i].productQty);
					}
					else{
						item.batches[i].productQty = null;
					}
				}
				this.$set(item.productVariants[0], 'returnQty', Number(totalQty));
			},
            fn_batchHasSeries: function(batches){
                for(let i=0; i<batches.length; i++){
                    if(batches[i]['field1'] != null || batches[i]['field2'] != null || batches[i]['field3'] != null){
                        return true;
                    }
                }
                return false;
            }
        },
        computed:{
            filteredItems: function () {
                let searchText = this.searchValue ? this.searchValue.toLowerCase() : '';

                if(this.items && this.items.length){
                    return this.items[0].transactionProducts.filter(item => {
                        let name = item.productName.toLowerCase();
                        return name.match(searchText);
                    });
                }
                else{
                    return [];
                }


            }
                
        }
    }
</script>