<template>
    <div>
        <v-dialog v-model="dialog_form" content-class="half-screen-dialog" no-click-animation scrollable persistent transition="slide-y-transition" :max-width="$root.fn_getActionPaneWidth()">
            <v-card no-border-radius grey-back>

              <v-toolbar :height="56" flat>
                  <v-btn icon @click="closeDialog"><v-icon>arrow_back</v-icon></v-btn>
                  <v-toolbar-title>
                    Opening Stock
                    <div toolbar-subtitle v-show="$parent.offsetTop>48">{{$parent.Form.itemName}}</div>
                  </v-toolbar-title>
              </v-toolbar>

              <v-divider class="noprint"></v-divider>

              <div class="data-container grey-back">

                <v-progress-linear indeterminate :height="6" color="primary" v-if="$root.dialogLoader || $parent.loading"></v-progress-linear>
                <div center-align class="pa-4 light-opacity-color" v-if="$root.dialogLoader">Syncing master items...</div>

                <template v-if="!$root.dialogLoader && !$parent.loading">
                  <v-subheader class="mb-2" white-back>Opening Date
                    <v-spacer></v-spacer>
                    <span><u>{{$root.CurrentBusiness.OpeningDate | moment("DD-MMM-YYYY")}}</u></span>
                  </v-subheader>

                  <ValidationObserver ref="observer">

                      <v-form>

                    

                        <v-layout row wrap ma-0 white-back pt-4 pb-4>
                              <v-flex xs12 sm12 md12 form-item>
                                <ValidationProvider name="Item" rules="required" v-slot="{ errors }">
                                    <!-- <v-autocomplete
                                        :items="$parent.products ? $parent.products : []"
                                        :filter="$root.customItemFilter"
                                        v-model="$parent.Form.selectedProduct"
                                        id="fieldproducts"
                                        label="Item"
                                        item-value="itemId"
                                        item-text="itemName"
                                        return-object
                                        append
                                        required
                                        :hide-details="$parent.Form.selectedProduct ? true : false"
                                        :error-messages="errors"
                                        @input="$parent.fn_getData('TransactionProductsOfProduct', $parent.Form.selectedProduct.itemId)">

                                        <v-list-item slot="prepend-item" class="primary--text" @click="fn_new">
                                            <v-list-item-title>Create New</v-list-item-title>
                                        </v-list-item>

                                    </v-autocomplete> -->
                                    <v-autocomplete
                                        :items="$parent.filteredProducts ? $parent.filteredProducts : []"
                                        :filter="$root.customItemFilter"
                                        v-model="$parent.Form.selectedProduct"
                                        id="fieldproducts"
                                        label="Item"
                                        item-value="itemId"
                                        item-text="itemName"
                                        return-object
                                        append
                                        required
                                        :hide-details="$parent.Form.selectedProduct ? true : false"
                                        :error-messages="errors"
                                        @input="$parent.fn_getData('TransactionProductsOfProduct', $parent.Form.selectedProduct.itemId)">

                                        <v-list-item slot="prepend-item" class="primary--text" @click="fn_new">
                                            <v-list-item-title>Create New</v-list-item-title>
                                        </v-list-item>

                                    </v-autocomplete>

                                    <div class="pt-2 font12 right-align">
                                      <template v-for="(item, index) in $parent.Form.summaryArray">
                                          <span :key="index" v-if="$parent.Form.summaryArray.length">
                                            {{item.unitName}}: <span :class="[item.qty < 0 ? 'redcolor' : 'greencolor']">{{item.qty}}</span>
                                          </span>
                                      </template>
                                    </div>
                                </ValidationProvider>
                              </v-flex>
                        </v-layout>

                        <div class="mt-2" v-for="(transactionItem, tIndex) in $parent.Form.trProducts" :key="tIndex" :id="'section'+tIndex" :class="transactionItem.groupId">

                          <!-- <v-subheader class="white-back">#{{tIndex+1}}
                            <v-spacer></v-spacer>
                            <v-btn icon @click="$parent.fn_removeItem(tIndex, transactionItem.variants)" v-if="$parent.Form.trProducts.length>1"><v-icon color="red">remove_circle_outline</v-icon></v-btn>
                          </v-subheader>

                          <v-divider></v-divider> -->

                          <v-layout row wrap ma-0 white-back pt-4 pb-4>

                            <!-- <template v-if="$root.TransactionSettings.mrpWiseStock">
                              <v-flex xs6 sm4 md4 form-item>
                                <ValidationProvider :name="'MRP'+transactionItem.groupId" rules="min_value:0|decimal2" v-slot="{ errors }">
                                    <v-text-field 
                                        v-model.number="transactionItem.mrp"
                                        prefix="₹"
                                        label="MRP" 
                                        type="number"
                                        :error-messages="errors">
                                    </v-text-field>
                                </ValidationProvider>
                              </v-flex>
                              
                            </template> -->

                            <!-- <v-flex xs6 sm6 md6 :class="[$root.TransactionSettings.mrpWiseStock ? 'xs6 sm4 md4' : 'xs6 sm6 md6']" form-item> -->

                              <v-flex xs12 sm6 md6 form-item v-for="(item, vIndex) in transactionItem.variants" :key="vIndex">

                                <!-- <ValidationProvider :name="'Qty'+transactionItem.groupId" rules="numeric|max8" v-slot="{ errors }">
                                  <v-text-field 
                                    type="number" 
                                    prefix="₹"
                                    v-model.number="item.qty"
                                    :label="item.label" 
                                    :hint="'₹ '+ $root.numberToLocale(item.amount)"
                                    persistent-hint
                                    required
                                    :error-messages="errors">
                                      
                                  </v-text-field>
                                </ValidationProvider> -->
                                <v-text-field 
                                    type="number" 
                                    prefix="₹"
                                    @input="$root.fn_boundDecimalLength(item, 'qty')"
                                    v-model="item.qty"
                                    label="Quantity" 
                                    :hint="'₹ '+ $root.numberToLocale(item.amount)"
                                    persistent-hint
                                    required>
                                  </v-text-field>
                                
                                
                              </v-flex>

                              <template v-if="$parent.Form.selectedItemData && $parent.Form.selectedItemData.productVariants && $parent.Form.selectedItemData.productVariants.length">
                                <v-flex xs6 sm6 md6 pr-3 pl-2 v-for="(item, vIndex) in $parent.Form.selectedItemData.productVariants" :key="vIndex">
                                  <v-text-field 
                                    v-if="!item.secondaryUnitId"
                                    :disabled="true"
                                    :value="item.unitName"
                                    label="Unit"
                                    placeholder=" ">
                                  </v-text-field>

                                  <v-select
                                    v-else
                                    :items="[{itemId:item.unitId, itemName:item.unitName}, {itemId:item.secondaryUnitId, itemName:item.secondaryUnitName}]"
                                    label="Unit"
                                    item-text="itemName"
                                    item-value="itemId"
                                    v-model="$parent.Form.selectedUnit"
                                    :hide-details="true"
                                    return-object
                                    ref="ref_unitSelection"
                                    @change="fn_unitChange($event, item, transactionItem)"
                                  ></v-select>

                                  <div class="pb-3 pt-1 right-align" v-if="item.secondaryUnitId">{{ item.packQuantity }} {{ item.unitName }} = {{ item.secondaryUnitPackQuantity }} {{ item.secondaryUnitName }}</div>
                                </v-flex>
                              </template>

                              <v-flex xs12 sm12 md12 form-item pt-4>

                                <ValidationProvider :name="'Rate'+transactionItem.groupId" rules="min_value:0|decimal2" v-slot="{ errors }">
                                  <v-text-field 
                                    type="number" 
                                    prefix="₹"
                                    v-model.number="transactionItem.rate"
                                    label="Rate" 
                                    :error-messages="errors">
                                  </v-text-field>
                                </ValidationProvider>

                                </v-flex>
                              
                          </v-layout>

                          <div class="pt-2 grey-back full-width" v-if="$root.TransactionSettings.enableBatch">
                            <template v-if="$parent.hasSeries && !$parent.hasOnlySeries">
                              <v-radio-group class="pt-3 pl-3 pr-3 pb-1" white-background hide-details v-model="$parent.Form.selectedBatchType" row>
                                <v-radio label="Batch" :value="1"></v-radio>
                                <v-radio label="Series" :value="2"></v-radio>
                              </v-radio-group>
                              <v-divider></v-divider>
                            </template>
                          </div>

                        </div>

                        <template v-if="$root.TransactionSettings.enableBatch">
                        <v-subheader class="white-background" v-if="$root.TransactionSettings.enableBatch && $parent.Form.trProducts && $parent.Form.trProducts.length">
                          <template v-if="$parent.Form.selectedBatchType == 2">
                            <template v-for="(field, index) in $root.TransactionSettings.batchFields">
                              <span :key="field.title" v-if="field.id == 'field1' || field.id == 'field2' || field.id == 'field3'">{{ field.title }}<span v-if="$root.TransactionSettings.batchFields.length - 1 != index">/</span></span>
                            </template>
                          </template>
                          <template v-else>Batches</template>
                          <v-spacer></v-spacer> 
                          <v-btn small text outlined capitalize color="primary" style="border-radius:5px;" nomargin :ripple="false" @click="$parent.fn_openDialogSelectBatch">
                            <v-icon class="mr-1" font16 color="primary">add</v-icon> Add / Select
                          </v-btn>
                        </v-subheader>

                        <template v-for="(batch, batchIndex) in $parent.Form.batches">
                          <v-card flat :key="batchIndex" v-if="batch.opening">
                            <v-card-text class="pb-2">
                              <v-layout ma-0 class="batch_layout" row wrap>
                                <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchNumber">
                                  <div light-opacity-color>Batch No.</div>
                                  <div>{{ batch.batchNumber }}</div>
                                </v-flex>
                                <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchSize">
                                  <div light-opacity-color>Size</div>
                                  <div>{{ batch.batchSize }}</div>
                                </v-flex>
                                <v-flex xs3 sm3 md3 mb-2 v-if="batch.batchMRP">
                                  <div light-opacity-color>MRP</div>
                                  <div>{{ batch.batchMRP }}</div>
                                </v-flex>
                                <v-flex xs3 sm3 md3 mb-2 v-if="batch.mfgDate">
                                  <div light-opacity-color>Mfg. Date</div>
                                  <div v-if="batch.mfgDate">{{ batch.mfgDate | moment('DD-MMM-YYYY') }}</div>
                                </v-flex>
                                <v-flex xs3 sm3 md3 mb-2 v-if="batch.expDate">
                                  <div light-opacity-color>Exp. Date</div>
                                  <div v-if="batch.expDate">{{ batch.expDate | moment('DD-MMM-YYYY') }}</div>
                                </v-flex>
                                <v-flex xs3 sm3 md3 mb-2 v-if="batch.opening && !batch.field1 && !batch.field2 && !batch.field3">
                                  <div light-opacity-color>Opening</div>
                                  <div>{{ batch.opening ? batch.opening : 0  }}</div>
                                </v-flex>
                                <v-flex xs3 sm3 md3 mb-2 v-if="batch.purchaseRate">
                                  <div light-opacity-color>Purchase Rate</div>
                                  <div>{{ batch.purchaseRate }}</div>
                                </v-flex>
                                <v-flex xs3 sm3 md3 mb-2 v-if="batch.field1">
                                  <div light-opacity-color>Serial Number</div>
                                  <div>{{ batch.field1 }}</div>
                                </v-flex>
                                <v-flex xs3 sm3 md3 mb-2 v-if="batch.field2">
                                  <div light-opacity-color>IMEI Number</div>
                                  <div>{{ batch.field2 }}</div>
                                </v-flex>
                                <v-flex xs3 sm3 md3 mb-2 v-if="batch.field3">
                                  <div light-opacity-color>Part Number</div>
                                  <div>{{ batch.field3 }}</div>
                                </v-flex>
                                <v-flex xs3 sm3 md3 mb-2 v-if="batch.field4">
                                  <div light-opacity-color>Model Number</div>
                                  <div>{{ batch.field4 }}</div>
                                </v-flex>
                                <v-flex xs12 sm12 md12 redcolor pb-2 v-if="batch.expDate && $parent.fn_checkDate(batch)">
                                  <v-icon class="mr-1 font16 redcolor">info_outline</v-icon> Expired {{ $parent.fn_getDate(batch) }}
                                </v-flex>
                                <!-- <v-flex xs4 sm4 md4 mb-2 v-if="item.selectedBatchType == 1 && (item.batches.length > 1 || item.batches.length == 1 && batch.productQty != item.productVariants[0].qty)">
                                  <div light-opacity-color>Quantity</div>
                                  <div>{{ batch.productQty }}</div>
                                </v-flex> -->
                              </v-layout>
                              <!-- <v-layout row wrap>
                                <v-flex xs4 sm4 md4 mb-2 v-for="(field, fieldIndex) in $root.TransactionSettings.batchFields" :key="fieldIndex" v-if="batch[field.id]">
                                  <div light-opacity-color>{{ field.title }}</div>
                                  <div v-if="field.type == 'date'">{{ batch[field.id] | moment('DD-MMM-YYYY') }}</div>
                                  <div v-else>{{ batch[field.id] }}</div>
                                </v-flex>
                              </v-layout> -->
                            </v-card-text>
                            <v-card-actions class="pa-0 ma-0" v-if="batch.isNew">
                              <v-spacer></v-spacer>
                              <v-btn color="red" capitalize text small @click="$parent.fn_removeBatch(batchIndex)">Remove</v-btn>
                            </v-card-actions>
                          </v-card>
                          <v-divider v-if="$parent.Form.batches.length - 1 != batchIndex" :key="'divider'+batchIndex"></v-divider>
                        </template>
                      </template>

                      <!-- <v-layout ma-0 grey-back row wrap white-back v-if="$parent.Form.trProducts.length">
                        <v-flex xs12 sm12 md12 right-align>
                            <v-btn light-opacity-color class="ma-3" capitalize outlined @click="$parent.fn_addMore">Add More</v-btn>
                          </v-flex>
                      </v-layout> -->
                        

                      <v-layout row wrap white-back ma-0 mt-2>

                            <v-flex sm12 md12 xs12 pa-4>
                              <ValidationProvider v-slot="{ errors }" name="Notes" rules="max:255">
                                <v-textarea
                                  rows="2"
                                  label="Notes" 
                                  auto-grow
                                  :counter="255"
                                  v-model="$parent.Form.transactionData.notes"
                                  :error-messages="errors">
                                </v-textarea>
                              </ValidationProvider>
                            </v-flex>
                        </v-layout>

                  </v-form>

                  </ValidationObserver>

                </template>

          </div>

          <!-- <div white-background full-width nopadding style="position:fixed;bottom:0;">
            <v-layout row wrap>
              <template v-if="$parent.totalAmount">
                <template v-if="$parent.batchTotalQty">
                  <v-flex xs6 sm6 md6 pl-3 pt-2 pb-1 style="text-align:left">Batch Total Opening</v-flex>
                      <v-flex xs6 sm6 md6 pr-3 pt-2 pb-1 style="text-align:right">{{$parent.batchTotalQty}} {{$parent.Form.selectedUnit ? $parent.Form.selectedUnit.itemName : $parent.Form.trProducts[0].variants[0].unitName }}</v-flex>
                </template>
                <v-flex xs4 sm4 md4 pl-3 pb-2 style="text-align:left">Total Amount</v-flex>
                    <v-flex xs8 sm8 md8 pr-3 pb-2 style="text-align:right">&#8377; {{$parent.totalAmount ? $root.numberToLocale($parent.totalAmount) : $parent.totalAmount}}</v-flex>
              </template>
              <v-flex xs12 sm12 md12 height48>
                <v-btn color="primary" full-height nomargin block no-round-borders :disabled="$parent.disableBtn" noshadow @click="$parent.fn_checkValidations">Save</v-btn>
              </v-flex>
            </v-layout>
          </div> -->

          <v-card-actions white-back>
              <v-layout row wrap ma-0>
                  <template v-if="$parent.totalAmount">
                    <v-flex xs4 sm4 md4 pt-2 pb-2 style="text-align:left">Total Amount</v-flex>
                    <v-flex xs8 sm8 md8 pt-2 pb-2 style="text-align:right">&#8377; {{$parent.totalAmount ? $root.numberToLocale($parent.totalAmount) : $parent.totalAmount}}</v-flex>
                  </template>

                  <v-flex xs12 sm12 md12 height48>
                      <v-btn class="save_btn" no-border-radius color="primary" full-height nomargin block no-round-borders noshadow :disabled="$parent.disableOnSave" :key="$parent.saveBtnKey" @click.once="fn_validateForm">Save</v-btn>
                  </v-flex>

              </v-layout>
          </v-card-actions>
                  

          </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import gstrSubReport from '../print_report_templates/gstr_sub_report'
import { numeric, min_value, max } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

// setInteractionMode('eager')

// extend('required', {
//   ...required,
//   message: '{_field_} can not be empty',
// })

extend('min_value', {
    ...min_value,
    message: 'This field must be 0 or more.'
});

extend('max', max)

extend('decimal2', value => {

  let decimalCount = 0;
  if(Math.floor(value) != value){
     decimalCount = value.toString().split(".")[1].length || 0;
  }

  if(decimalCount <= 2){
    return true;
  }

  return 'This field must be numeric and may contain 2 decimal points.';

});

extend('numeric', {
    ...numeric,
   message: 'This field may only contain numeric characters.' 
});

    export default{
        props:['closeDialog'],
        data(){
            return{
                dialog_form: false,
            }
        },
        components:{
            // 'gstr-sub-report':gstrSubReport
            ValidationProvider,
            ValidationObserver
        },
        methods:{
            openDialogWeb: function () {
                this.dialog_form = true;
            },
            closeDialogWeb: function () {
                this.dialog_form = false;
            },
            fn_validateForm: function(){
              this.$parent.disableOnSave = true;
              this.$refs.observer.validate().then((result) => {
                if(result) {

                  if(this.$root.TransactionSettings.enableBatch && this.$parent.Form.batches && this.$parent.Form.batches.length){
                    let batchTotal = 0;
                    for(let i=0; i<this.$parent.Form.batches.length; i++){
                      batchTotal += this.$parent.Form.batches[i].opening;
                    }
                    let openingQty = this.$parent.Form.trProducts[0].variants[0].qty ? this.$parent.Form.trProducts[0].variants[0].qty : 0; 
                    if(openingQty != batchTotal){
                      this.$parent.saveBtnKey++;
                      this.$parent.disableOnSave = false;
                      this.$eventHub.$emit('snackbarAlert', {msg:"Opening quantity not matched with batch total opening"});
                      return;
                    }
                  }

                  this.$parent.fn_checkValidations();
                }
                else{
                  this.$parent.saveBtnKey++;
                  this.$parent.disableOnSave = false;
                  this.fn_scrollToElement('error--text');
                }
              });
            },
            fn_new: function(){
              this.closeDialogWeb();
              setTimeout(()=>{
                this.$router.push({path:'/'+this.$route.params.businessId + '/' + this.$route.params.listId +'/product/items/new', query:{tagId:this.guidWeb(), ls:1}});
              },100);
            },

            fn_unitChange: function(value, item, product){
              let currentItemId = this.$refs.ref_unitSelection[0]._data.selectedItems[0].itemId;
              if(currentItemId != value.itemId){
                if(value.itemId == item.secondaryUnitId){
                  product.rate = (product.rate * item.packQuantity) / item.secondaryUnitPackQuantity;
                }
                else{
                  product.rate = (product.rate * item.secondaryUnitPackQuantity) / item.packQuantity;
                  // product.rate = this.roundUpto2Decimals(product.rate * item.secondaryUnitPackQuantity);
                }
                // this.tempRate = product.rate;
                product.rate = this.roundUpto2Decimals(product.rate); 
              }
            },
        }
    }
</script>